import PropTypes from "prop-types";
import React, { Component } from 'react';
import {Translate} from "react-localize-redux";
import {Label, Input, FormGroup, Form, Button, FormFeedback, Alert, Row, Col} from "reactstrap";
import {isEmptyString} from "../../../../utils/string-utils";
import {audioDevicePropTypes} from "../../../../utils/models-prop-types";
import {Formik} from "formik";
import VolumeSlider from "./volume-slider";

const propTypes = {
  config: PropTypes.shape({
    autoStart: PropTypes.bool,
    sipRtpPortStart: PropTypes.number,
    sipRtpPortRange: PropTypes.number,
    inputDevice: PropTypes.string,
    inputMode: PropTypes.string,
    inputVolume: PropTypes.string,
    outputDevice: PropTypes.string,
    outputVolume: PropTypes.string,
    playbackFramesPerPacket: PropTypes.number
  }),
  forbiddenUDPPorts: PropTypes.arrayOf(PropTypes.number).isRequired,
  inputAudioDevices: PropTypes.arrayOf(audioDevicePropTypes),
  outputAudioDevices: PropTypes.arrayOf(audioDevicePropTypes),
  onSubmit: PropTypes.func.isRequired
};

const PORT_MIN = 1024;
const PORT_MAX = 65535;

class IntercomSettingsForm extends Component {

  constructor(props){
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.toggleInputLink = this.toggleInputLink.bind(this);
    this.toggleOutputLink = this.toggleOutputLink.bind(this);

    this.state = {
      inputLink: false,
      outputLink: false
    };
  }

  handleSubmit(values, { resetForm }){
    const data = { ...values };
    data.inputVolume = JSON.stringify(data.inputVolume);
    data.outputVolume = JSON.stringify(data.outputVolume);
    data.sipRtpPortRange = data.sipRtpPortEnd - data.sipRtpPortStart;
    this.props.onSubmit(data);
    resetForm(values);
  }

  handleValidation(values){
    const errors = {};

    if(isEmptyString(values.sipRtpPortStart)){
      errors.sipRtpPortStart = 'genericLabel.REQUIRED_FIELD.text';
    } else if (values.sipRtpPortStart < PORT_MIN || values.sipRtpPortStart > PORT_MAX) {
      errors.sipRtpPortStart = 'genericLabel.INVALID_VALUE.text';
    }

    if(isEmptyString(values.sipRtpPortEnd)){
      errors.sipRtpPortEnd = 'genericLabel.REQUIRED_FIELD.text';
    } else if (values.sipRtpPortStart > values.sipRtpPortEnd) {
      errors.sipRtpPortEnd = 'genericLabel.INVALID_VALUE.text';
    } else if (values.sipRtpPortEnd < PORT_MIN || values.sipRtpPortEnd > PORT_MAX) {
      errors.sipRtpPortEnd = 'genericLabel.INVALID_VALUE.text';
    }

    // Forbidden Ports
    if (!errors.sipRtpPortStart && !errors.sipRtpPortEnd) {
      for (const index in this.props.forbiddenUDPPorts) {
        if (this.props.forbiddenUDPPorts[index] >= values.sipRtpPortStart && this.props.forbiddenUDPPorts[index] <= values.sipRtpPortEnd) {
          errors.sipRtpPortStart = 'genericLabel.PORT_ALREADY_USED.text';
          break;
        }
      }
    }

    return errors;
  }

  toggleInputLink() {
    this.setState({
      inputLink: !this.state.inputLink
    });
  }

  toggleOutputLink() {
    this.setState({
      outputLink: !this.state.outputLink
    });
  }

  render() {
    const { config, inputAudioDevices, outputAudioDevices } = this.props;
    const sipRtpPortEndDefault = config.sipRtpPortStart + config.sipRtpPortRange
    return (
      <Formik initialValues={{
                autoStart: config.autoStart,
                sipRtpPortStart: config.sipRtpPortStart,
                sipRtpPortRange: config.sipRtpPortRange,
                sipRtpPortEnd: config.sipRtpPortEnd ? config.sipRtpPortEnd : sipRtpPortEndDefault ,
                inputDevice: inputAudioDevices.find(device => device.name === config.inputDevice) ? config.inputDevice : '',
                inputMode: config.inputMode,
                inputVolume: config.inputVolume ? JSON.parse(config.inputVolume) : [],
                outputDevice: outputAudioDevices.find(device => device.name === config.outputDevice) ? config.outputDevice : '',
                outputVolume: config.outputVolume ? JSON.parse(config.outputVolume) : [],
                playbackFramesPerPacket: config.playbackFramesPerPacket
              }}
              validateOnBlur={false}
              validateOnChange={true}
              validate={ this.handleValidation }
              onSubmit={ this.handleSubmit }>
        {({
            values,
            errors,
            dirty,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            handleValidation,
            isSubmitting,
            setFieldValue
            /* and other goodies */
          }) => {
          const selectedInputAudioDevice = values.inputDevice ? inputAudioDevices.find(device => device.name === values.inputDevice) : null;
          const modes = selectedInputAudioDevice && selectedInputAudioDevice.modes ? selectedInputAudioDevice.modes.split(',') : null;
          const selectedOutputAudioDevice = values.outputDevice ? outputAudioDevices.find(device => device.name === values.outputDevice) : null;

          const handleInputDeviceChange = (e) => {
            // When device changes, we update the number of its volume sliders depending on the nb_volume property
            handleChange(e);
            const selectedDeviceName = e.target.value;
            const newVolumeCount = inputAudioDevices.find(device => device.name === selectedDeviceName).nb_volumes;
            setFieldValue('inputVolume',  Array(newVolumeCount).fill(50).map((value, index) => values.inputVolume[index] ? values.inputVolume[index] : value ));
          };
          const handleOutputDeviceChange = (e) => {
            // When device changes, we update the number of its volume sliders depending on the nb_volume property
            handleChange(e);
            const selectedDeviceName = e.target.value;
            const newVolumeCount = outputAudioDevices.find(device => device.name === selectedDeviceName).nb_volumes;
            setFieldValue('outputVolume',  Array(newVolumeCount).fill(50).map((value, index) => values.outputVolume[index] ? values.outputVolume[index] : value ));
          };

          const handleInputVolumeChange = (inputLink, index, value) => {
            if (inputLink) {
              for(var i in values.inputVolume){
                setFieldValue(`inputVolume[${i}]`, value)
              }
            }
            else {
              setFieldValue(`inputVolume[${index}]`, value)
            }
          };

          const handleOutputVolumeChange = (outputLink, index, value) => {
            if (outputLink) {
              for(var i in values.outputVolume){
                setFieldValue(`outputVolume[${i}]`, value)
              }
            }
            else {
              setFieldValue(`outputVolume[${index}]`, value)
            }
          };

          return (
          <Form onSubmit={handleSubmit}>
            <FormGroup check>
              <Label check>
                <Input id="intercomSettings_autoStart"
                       type="checkbox"
                       name="autoStart"
                       onChange={handleChange}
                       checked={values.autoStart}/>{' '}
                <Translate id="genericLabel.ENABLE_INTERCOM_AUTO_START.text"/>
              </Label>
            </FormGroup>
            <FormGroup>
              <Label for="sipRtpPortStart">
                <Translate id="genericLabel.UDP_PORT_RANGE.text"/>
              </Label>
              { (config.sipRtpPortStart !== values.sipRtpPortStart || config.sipRtpPortEnd !== values.sipRtpPortEnd) && dirty &&
              <Alert color="warning">
                <Translate id="genericLabel.RESTART_STREAMHUB_TO_TAKE_CHANGES_INTO_ACCOUNT.text"/>
              </Alert>
              }
              <div className="row mr-1">
                <div className="col-md-3">
                <Input type="number"
                       name="sipRtpPortStart"
                       id="intercomSettings_sipRtpPortStart"
                       min={PORT_MIN}
                       max={PORT_MAX}
                       invalid={errors.sipRtpPortStart !== undefined}
                       value={values.sipRtpPortStart}
                       onChange={handleChange}/>
                <FormFeedback>
                  <Translate id={errors.sipRtpPortStart}/>
                </FormFeedback>
                </div>
                <div className="col-md-3">
                <Input type="number"
                       name="sipRtpPortEnd"
                       id="intercomSettings_sipRtpPortEnd"
                       min={PORT_MIN}
                       max={PORT_MAX}
                       invalid={errors.sipRtpPortEnd !== undefined}
                       value={values.sipRtpPortEnd}
                       onChange={handleChange}/>
                <FormFeedback>
                  <Translate id={errors.sipRtpPortEnd}/>
                </FormFeedback>
                </div>
              </div>
            </FormGroup>
            <Row form>
              <Col>
                <FormGroup>
                  <Label for="inputDevice">
                    <Translate id="genericLabel.INPUT_DEVICE.text"/>
                  </Label>
                  <Input type="select"
                        name="inputDevice"
                        id="intercomSettings_inputDevice"
                        value={values.inputDevice}
                        onChange={handleInputDeviceChange}>
                    { !selectedInputAudioDevice  &&
                        <option value=''></option>
                    }
                    { inputAudioDevices.map(device => (
                      <option key={device.name} value={device.name}>{ device.name }</option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              { modes &&
              <Col>
                <FormGroup>
                  <Label for="inputMode">
                    <Translate id="genericLabel.MODE.text"/>
                  </Label>
                  <Input type="select"
                        name="inputMode"
                        id="intercomSettings_inputMode"
                        value={values.inputMode}
                        onChange={handleChange}>
                    { modes.map(mode => (
                      <option key={mode} value={mode}>{mode}</option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              }
            </Row>
            { selectedInputAudioDevice && values.inputVolume.length > 0 &&
            <FormGroup>
              <Label for="inputVolume">
                <Translate id="genericLabel.VOLUME.text"/>
              </Label>
              <div id="intercomSettings_inputVolume">
                {values.inputVolume.map((volume, index) => (
                  <VolumeSlider key={index}
                                name={`i${index + 1}`}
                                onChange={value => handleInputVolumeChange(this.state.inputLink, index, value)}
                                value={volume}/>
                ))}
              </div>
            </FormGroup>
            }
            { selectedInputAudioDevice && values.inputVolume.length > 0 &&
            <FormGroup check>
              <Label check>
                <Input id="intercomSettings_inputLink"
                       type="checkbox"
                       name="inputLink"
                       onChange={e => { this.toggleInputLink(); handleInputVolumeChange(!this.state.inputLink, 0, values.inputVolume[0])}}
                       checked={this.state.inputLink}/>{' '}
                <Translate id="genericLabel.SAME_LEVELS.text"/>
              </Label>
            </FormGroup>
            }
            <FormGroup>
              <Label for="outputDevice">
                <Translate id="genericLabel.OUTPUT_DEVICE.text"/>
              </Label>
              <Input type="select"
                     name="outputDevice"
                     id="intercomSettings_outputDevice"
                     value={values.outputDevice}
                     onChange={handleOutputDeviceChange}>
                { !selectedOutputAudioDevice &&
                     <option value=''></option>
                }
                { outputAudioDevices && outputAudioDevices.map(device => (
                  <option key={device.name} value={device.name}>{ device.name }</option>
                ))}
              </Input>
            </FormGroup>
            { selectedOutputAudioDevice && values.outputVolume.length > 0 &&
            <FormGroup>
              <Label for="outputVolume">
                <Translate id="genericLabel.VOLUME.text"/>
              </Label>
              <div id="intercomSettings_outputVolume">
                {values.outputVolume.map((volume, index) => (
                  <VolumeSlider key={index}
                                name={`o${index + 1}`}
                                onChange={value => handleOutputVolumeChange(this.state.outputLink, index, value)}
                                value={volume}/>
                ))}
              </div>
            </FormGroup>
            }
            { selectedOutputAudioDevice && values.outputVolume.length > 0 &&
            <FormGroup check>
              <Label check>
                <Input id="intercomSettings_outputLink"
                       type="checkbox"
                       name="outputLink"
                       onChange={e => { this.toggleOutputLink(); handleOutputVolumeChange(!this.state.outputLink, 0, values.outputVolume[0])}}
                       checked={this.state.outputLink}/>{' '}
                <Translate id="genericLabel.SAME_LEVELS.text"/>
              </Label>
            </FormGroup>
            }
            <FormGroup className="buttons">
              <Button id="intercomSettings_submit"
                      color="primary"
                      disabled={isSubmitting || !dirty}
                      type="submit">
                <Translate id="genericLabel.SAVE.text"/>
              </Button>
            </FormGroup>
          </Form>
        )}}
      </Formik>
    );
  }
}

IntercomSettingsForm.propTypes = propTypes;

export default IntercomSettingsForm;
