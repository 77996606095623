import PropTypes from 'prop-types';
import React, {Fragment, useRef} from 'react';
import { connect } from 'react-redux';
import { Translate, withLocalize } from "react-localize-redux";
import {DragSource, DropTarget} from 'react-dnd';
import { Button } from 'reactstrap';
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";
import AWLoader from "@aviwest/ui-kit/dist/js/components/loader";

import AudioLevels from '../../../../common/audio-levels/index';
import InputSelector from "../../../../common/input-selector/index";
import StatusButton from '../../../../common/status-button/index';
import Thumbnail from "../../../../common/thumbnail";
import Graph from "./graph";
import CopyToClipboardButton from "../../../../common/copy-to-clipboard-button/index";
import Destination from "./destination";
import {
  outputIpProfilePropTypes,
  outputPropTypes,
  videoCardCapabilitiesPropTypes
} from '../../../../../utils/models-prop-types';

import {
  USER_ROLE_ADMIN,
  USER_ROLE_VIEWER,
  DASHBOARD_LAYOUT_FULLSIZE,
  DASHBOARD_LAYOUT_GRID,
  DASHBOARD_LAYOUT_INLINE,
  DND_ITEM_TYPE_ENCODER,
  DND_ITEM_TYPE_INPUT,
  DND_ITEM_TYPE_IP_OUTPUT,
  DND_ITEM_TYPE_MULTIVIEW,
  INPUT_ID_MULTIVIEW,
  MULTIVIEW,
  OUTPUT_TYPE_HARDWARE,
  OUTPUT_TYPE_NDI,
  OUTPUT_TYPE_IP,
  NDI,
  STATUS_LIVE,
  STATUS_ON,
  STATUS_OFF,
  STATUS_ERROR,
  RTMP
} from '../../../../../constants';
import {canPlayStream, outputIcon, outputMatchingVideoCard} from "../../../../../utils/global-utils";
import { AWControlGridItem, AWControlGridItemStatus, AWControlGridItemContent, AWControlGridItemContentMain, AWControlGridItemContentMainCompact, AWControlGridItemContentMainDetails, AWControlGridItemHeader, AWControlGridItemHeaderControls, AWControlGridItemHeaderMetadata, AWControlGridItemThumbnail, AWControlGridItemContentTitle, AWControlGridItemActions } from '@aviwest/ui-kit/dist/js/components/control-grid';
import Badge from 'reactstrap/lib/Badge';
import AWPopup, { AWPopupRow, AWPopupCol } from '@aviwest/ui-kit/dist/js/components/popup';
import AWBadgeLabel from "@aviwest/ui-kit/dist/js/components/badge-label";
import { formatOutputId } from "../../../../../utils/global-utils";
import OutputBadge from './output-badge';
import { getAvailableVideoCards } from '../../../../../misc/capabilities.selectors';

const propTypes = {
  hasAdminLevel: PropTypes.bool,
  hasViewerLevel: PropTypes.bool,
  connectDropTarget: PropTypes.func.isRequired,
  ipProfile: outputIpProfilePropTypes,
  ipProfiles: PropTypes.arrayOf(outputIpProfilePropTypes),
  isOver: PropTypes.bool.isRequired,
  onEditSettings: PropTypes.func.isRequired,
  onChangePosition: PropTypes.func,
  onPersistPosition: PropTypes.func,
  onPreview: PropTypes.func,
  onLock: PropTypes.func.isRequired,
  onStart: PropTypes.func.isRequired,
  onStop: PropTypes.func.isRequired,
  onSwitchInput: PropTypes.func.isRequired,
  onStartPlayback: PropTypes.func.isRequired,
  onDetachIPProfile: PropTypes.func,
  output: outputPropTypes.isRequired,
  selectedInputId: PropTypes.string,
  videoCard: videoCardCapabilitiesPropTypes,
  videoCards: PropTypes.arrayOf(videoCardCapabilitiesPropTypes),
  onHoverInput: PropTypes.func,
  onHoverEncoder: PropTypes.func,
  layout: PropTypes.oneOf([DASHBOARD_LAYOUT_GRID, DASHBOARD_LAYOUT_INLINE, DASHBOARD_LAYOUT_FULLSIZE]),
};
// DND Target

const dndDropTarget = {
  canDrop(props, monitor) {
    const itemType = monitor.getItemType();
    const item = monitor.getItem();
    const outputType = props.output.type;
    if(outputType === OUTPUT_TYPE_IP && itemType === DND_ITEM_TYPE_IP_OUTPUT){
      return true;
    }
    if(props.output.lockstate){
      return false;
    }
    if(props.output.booking){
      return false; // if the output is booked, we can't drop
    }
    if(outputType === OUTPUT_TYPE_HARDWARE || outputType === OUTPUT_TYPE_NDI){
      return itemType === DND_ITEM_TYPE_MULTIVIEW || itemType === DND_ITEM_TYPE_INPUT; // We don't accept encoders for Hardware or NDI outputs
    }
    else if(outputType === OUTPUT_TYPE_IP){
      return itemType === DND_ITEM_TYPE_ENCODER || (itemType === DND_ITEM_TYPE_INPUT && item.channelType !== NDI); // We don't accept multiview or NDI inputs for IP outputs
    }
    return false;
  },

  drop(props, monitor) {
    const itemType = monitor.getItemType();
    if(itemType){
      const item = monitor.getItem();
      if(itemType === DND_ITEM_TYPE_INPUT){
        props.onSwitchInput(props.output, item.id, null, true);
      }
      else if(itemType === DND_ITEM_TYPE_MULTIVIEW){
        props.onSwitchInput(props.output, INPUT_ID_MULTIVIEW, null, true);
      }
      else if(itemType === DND_ITEM_TYPE_ENCODER){
        props.onSwitchInput(props.output, null, item.id, true);
      }
      else if(itemType === DND_ITEM_TYPE_IP_OUTPUT){
        props.onPersistPosition();
      }
    }
  },

  hover(props, monitor, component) {
    if(monitor.getItemType() !== DND_ITEM_TYPE_IP_OUTPUT){
      return null;
    }

    const output = monitor.getItem().output;
    if(props.output.type !== OUTPUT_TYPE_IP){
      return null;
    }
    const hoverIndex = props.output.index;
    if (output.index !== hoverIndex) {
      // Time to actually perform the action
      props.onChangePosition(output, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      monitor.getItem().output.index = hoverIndex;
    }
  }
};

const dndCollectTarget = (connect, monitor) => {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver() && monitor.getItemType() !== DND_ITEM_TYPE_IP_OUTPUT,
    canDrop: monitor.canDrop()
  };
};

// DND Source

const dndInputSource = {
  beginDrag(props) {
    return {
      output: props.output
    };
  },
  canDrag(props) {
    return props.output.type === OUTPUT_TYPE_IP;
  }
};

const dndCollectSource = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging()
  }
};

const settingsMenu = (finalName, props, collapsed) => {
  const {output, configOutputEnable, hasAdminLevel, hasViewerLevel, ipProfile} = props;
  return (
    (props.layout === DASHBOARD_LAYOUT_INLINE && collapsed) ?
      <Fragment>
        { props.output.status === STATUS_OFF && props.onDetachIPProfile &&
          <Button id={"dashboard_output_"+props.output.id+"_ejectButton"}
                  className="basic icon"
                  disabled={!hasAdminLevel || output.lockstate}
                  onClick={() => props.onDetachIPProfile(output)}
                  title={props.translate('genericLabel.EJECT_IP_PROFILE.text')}>
            <AWIcon name="eject" />
          </Button>
        }
        <Button id={"dashboard_output_"+props.output.id+"_lockButton"}
                  className="basic icon"
                  disabled={!hasAdminLevel}
                  onClick={() => props.onLock(output)}
                  title={props.translate('genericLabel.OUTPUT_LOCK.text')}>
          <AWIcon name="lock" color={ output.lockstate ? 'primary' : undefined } />
        </Button>
        <StatusButton id={"dashboard_output_"+output.id}
                      onChange={ (status) => status === STATUS_ON ? props.onStart(output) : props.onStop(output) }
                      status={output.status}
                      disabled={hasViewerLevel || output.lockstate || (configOutputEnable && output.status === STATUS_OFF) || (!configOutputEnable && output.status !== STATUS_OFF) || (!output.inputId && !output.encoderId) || (output.inputId<0 && output.encoderId<0)}
                      active={output.status !== STATUS_OFF}/>
        <Button id={"dashboard_output_"+props.output.id+"_settingButton"}
          className="basic icon" onClick={() => props.onEditSettings(output.id)} disabled={!props.hasAdminLevel || output.lockstate}
          title={props.translate('genericLabel.SETTINGS.text')}>
          <AWIcon name={ipProfile ? "options" : "setting"}/>
        </Button>
      </Fragment>
    :
      <AWPopup renderTrigger={({ ref, onClick, className, opened }) => (
        <button id={"dashboard_output_"+props.output.id+"_menuSettingButton"} ref={ref} className={className + " circular btn btn-secondary"} onClick={onClick} >
          <AWIcon name={opened ? "cancel" : "icon_menu_alt"} />
        </button>
      )}>
        {({close}) => (
          <div>
            <Badge>{finalName}</Badge>
            <AWPopupRow>
              <AWPopupCol>
                <Button id={"dashboard_output_"+props.output.id+"_settingsButton"}
                  onClick={() => props.onEditSettings(output.id)} disabled={!props.hasAdminLevel || output.lockstate}
                  title={props.translate('genericLabel.SETTINGS.text')}>
                  <AWIcon name={ipProfile ? "options" : "setting"}/>
                  <span><Translate id="genericLabel.SETTINGS.text" /></span>
                </Button>
              </AWPopupCol>
              { props.output.status === STATUS_OFF && props.onDetachIPProfile &&
              <AWPopupCol>
                <Button id={"dashboard_output_"+props.output.id+"_ejectButton"}
                        onClick={() => props.onDetachIPProfile(output)}
                        disabled={!hasAdminLevel || output.lockstate}
                        title={props.translate('genericLabel.EJECT_IP_PROFILE.text')}>
                  <AWIcon name="eject" />
                  <span><Translate id="genericLabel.EJECT_IP_PROFILE.text" /></span>
                </Button>
              </AWPopupCol>
              }
              <AWPopupCol>
                <Button id={"dashboard_output_"+props.output.id+"_lockButton"}
                        onClick={() => {props.onLock(output); close();}}
                        disabled={!hasAdminLevel}
                        title={props.translate('genericLabel.OUTPUT_LOCK.text')}>
                  <AWIcon name="lock" color={ output.lockstate ? 'primary' : undefined } />
                  <span><Translate id="genericLabel.OUTPUT_LOCK.text" /></span>
                </Button>
              </AWPopupCol>
            </AWPopupRow>
          </div>
        )}
      </AWPopup>
  )
}

const OUTPUT_PRESENT_PREVIEW = 'PREVIEW';
const OUTPUT_PRESENT_PLACEHOLDER = 'PLACEHOLDER';

const Output = React.forwardRef((props, ref) => {
  const {
    canDrop,
    connectDragSource,
    connectDragPreview,
    connectDropTarget,
    ipProfile,
    isDragging,
    isOver,
    output,
    onPreview,
    onStart,
    onStop,
    onSwitchInput,
    selectedInputId,
    videoCard,
    videoCards,
    onHoverInput,
    onHoverEncoder,
    layout,
    configOutputEnable,
    hasViewerLevel,
    audioLimit
  } = props;


  const elementRef = useRef(null);
  connectDragPreview(elementRef, {
    captureDraggingState: false
  });
  connectDropTarget(elementRef);
  connectDragSource(elementRef)

  const { id, name, status, type, has4K } = output;
  const finalName = name ? name : (ipProfile ? ipProfile.name : id);
  let present = OUTPUT_PRESENT_PLACEHOLDER;
  if(status === STATUS_LIVE || status === STATUS_ERROR || (status === STATUS_ON && (type === OUTPUT_TYPE_HARDWARE || type === OUTPUT_TYPE_NDI))){
    present = OUTPUT_PRESENT_PREVIEW;
  }
  let collapsed = (present === OUTPUT_PRESENT_PLACEHOLDER);
  let matchSelectedInput = false;
  if((output.inputId === INPUT_ID_MULTIVIEW && selectedInputId === MULTIVIEW) || output.inputId === selectedInputId){
    matchSelectedInput = true;
  }
  return (
    <AWControlGridItem collapsed={collapsed}
          available={present === OUTPUT_PRESENT_PLACEHOLDER}
          ref={elementRef}
          dragged={isDragging}
          highlighted={matchSelectedInput}
          className={ `aw-output ${isOver ? (canDrop ? ' aw-target-valid' : ' aw-target-invalid') : ''}` }
          inError={status === STATUS_ERROR}
          id={`dashboard_output_${props.output.type}_${props.output.id}`}>
      <AWControlGridItemHeader>
      <AWControlGridItemStatus>
        {status === STATUS_LIVE && <AWBadgeLabel fill color='red'>LIVE</AWBadgeLabel>}
      </AWControlGridItemStatus>
          <AWControlGridItemHeaderMetadata align="right">
            { has4K &&
              <span>4k</span>
            }
            <Destination output={output}
                         ipProfile={ipProfile}
                         videoCard={videoCard}/>
        </AWControlGridItemHeaderMetadata>
        <AWControlGridItemHeaderControls>
        {settingsMenu(finalName, props, collapsed)}
        </AWControlGridItemHeaderControls>
      </AWControlGridItemHeader>
      <AWControlGridItemContent>
        <AWControlGridItemContentMain>
          <AWControlGridItemContentMainCompact>
            { present === OUTPUT_PRESENT_PLACEHOLDER && (layout === DASHBOARD_LAYOUT_INLINE ?
              <Fragment>
                {formatOutputId(output.id, output.type)}
                {type !== OUTPUT_TYPE_HARDWARE && <AWIcon name={outputIcon(type, ipProfile ? ipProfile.protocol : null)} />}
                <AWControlGridItemContentTitle className={output.type === OUTPUT_TYPE_IP ? 'draggable' : ''}>
                  { ((configOutputEnable && status === STATUS_OFF) || (!configOutputEnable && status !== STATUS_OFF)) &&
                  <AWLoader active={true} position="centered" size="sm"/>
                  }
                  {finalName}
                </AWControlGridItemContentTitle>
              </Fragment>
              :
              <AWControlGridItemContentTitle className={output.type === OUTPUT_TYPE_IP ? 'draggable' : ''}>
                { ((configOutputEnable && status === STATUS_OFF) || (!configOutputEnable && status !== STATUS_OFF)) &&
                <AWLoader active={true} position="centered"/>
                }
                  {output.type === OUTPUT_TYPE_HARDWARE && <AWIcon name="sdi"/>}
                  
                  <AWBadgeLabel disabled 
                    left={outputMatchingVideoCard(videoCards, output) ? formatOutputId(output.id, output.type , outputMatchingVideoCard(videoCards, output).sdiCardType) : formatOutputId(output.id, output.type)}
                    right={type !== OUTPUT_TYPE_HARDWARE && <AWIcon name={outputIcon(type, ipProfile ? ipProfile.protocol : null)} />}>{finalName}
                  </AWBadgeLabel>

              </AWControlGridItemContentTitle>
              )
            }
            { present === OUTPUT_PRESENT_PREVIEW &&
            <AWControlGridItemThumbnail className={output.type === OUTPUT_TYPE_IP ? 'draggable' : ''} overlay={<div><p>&nbsp;{output.inputInfo}</p><p>{output.outputInfo}</p></div>}
              error={status === STATUS_ERROR && <Translate id={output.message ? `streamingOutputMessage.${output.message}.text` : "genericLabel.ERROR.text"} />}>
              <Thumbnail inputId={ output.inputId >= 0 ? output.inputId : undefined }
                 encoderId={ output.type === OUTPUT_TYPE_IP && output.encoderId >= 0 ? output.encoderId : undefined }
                 outputId={ output.type === OUTPUT_TYPE_HARDWARE ? output.id : undefined }
                 ndiOutputId={ output.type === OUTPUT_TYPE_NDI ? output.id : undefined }
                 timestamp={new Date().getTime()}>
                <AWControlGridItemContentTitle>
                  <OutputBadge output={output} 
                               finalName={finalName} 
                               ipProfile={ipProfile}
                               videoCardType={outputMatchingVideoCard(videoCards, output) ? outputMatchingVideoCard(videoCards, output).sdiCardType : ""} />
                </AWControlGridItemContentTitle>
              </Thumbnail>
            </AWControlGridItemThumbnail>
            }
            { !output.booking &&
              <InputSelector encoderId={ output.encoderId }
                            inputId={ output.inputId }
                            includeEncoders={ type === OUTPUT_TYPE_IP }
                            includeMultiView={ (type === OUTPUT_TYPE_HARDWARE || type === OUTPUT_TYPE_NDI) }
                            disabled={output.lockstate}
                            lockstate={output.lockstate}
                            onChange={(inputId, encoderId) => onSwitchInput(output, inputId, encoderId)}
                            onHoverInput={onHoverInput}
                            onHoverEncoder={onHoverEncoder}/>
            }
            { output.booking &&
              <div className="select">
                <Button id={"dashboard_output_"+props.output.id+"_bookingButton"}
                    disabled={true} className="aw-input-selector btn btn-secondary">
                    <AWIcon name="booking" />
                    <div className="text-clip" title={output.booking}>{output.booking}</div>
                </Button>
              </div>
            }
            <AWControlGridItemActions>
              { type === OUTPUT_TYPE_IP &&
                <CopyToClipboardButton ipProfile={ipProfile}/>
              }
              { onPreview && ipProfile && canPlayStream(ipProfile) && status === STATUS_LIVE &&
              <Button id={"dashboard_output_"+props.output.id+"_previewButton"}
                      className="basic icon"
                      onClick={() => onPreview(output)}>
                <AWIcon name="preview"/>
              </Button>
              }
                <StatusButton id={"dashboard_output_"+output.id}
                  onChange={ (status) => status === STATUS_ON ? onStart(output) : onStop(output) }
                  status={status}
                  disabled={hasViewerLevel || output.lockstate || (configOutputEnable && status === STATUS_OFF) || (!configOutputEnable && status !== STATUS_OFF) || (!output.inputId && !output.encoderId) || (output.inputId<0 && output.encoderId<0)}
                  active={status !== STATUS_OFF}/>
            </AWControlGridItemActions>
          </AWControlGridItemContentMainCompact>
          { status === STATUS_LIVE &&
          <AWControlGridItemContentMainDetails>
            {type === OUTPUT_TYPE_HARDWARE &&
            <AudioLevels outputId={id}/>
            }
            {type === OUTPUT_TYPE_NDI &&
            <AudioLevels ndiOutputId={id}/>
            }
            {type === OUTPUT_TYPE_IP &&
            <Fragment>
              <AudioLevels inputId={output.inputId} limit={audioLimit}/>
              <AudioLevels encoderId={output.encoderId} limit={audioLimit}/>
              <div className="graph-container">
                <Graph output={output}/>
              </div>
            </Fragment>
            }
          </AWControlGridItemContentMainDetails>
          }
        </AWControlGridItemContentMain>
      </AWControlGridItemContent>
    </AWControlGridItem>
  );
});

Output.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
  let configOutputEnable, audioLimit
  switch (ownProps.output.type){
    case OUTPUT_TYPE_HARDWARE:
      configOutputEnable = state.config.basebandPlayer[ownProps.output.id].enable
      break;
    case OUTPUT_TYPE_NDI:
      configOutputEnable = state.config.NDIOutput[ownProps.output.id].enable
    break;
    case OUTPUT_TYPE_IP:
      configOutputEnable = state.config.streamingOutput[ownProps.output.id].enable
      audioLimit = state.config.streamingOutput[ownProps.output.id].mode === RTMP ? 2 : undefined
    break;
    default:
      configOutputEnable = false
  }
  return {
    hasAdminLevel: state.identity.role === USER_ROLE_ADMIN,
    hasViewerLevel: state.identity.role === USER_ROLE_VIEWER,
    videoCards: getAvailableVideoCards(state),
    configOutputEnable,
    audioLimit
  }
};

export default connect(mapStateToProps)(DropTarget([DND_ITEM_TYPE_IP_OUTPUT, DND_ITEM_TYPE_INPUT, DND_ITEM_TYPE_MULTIVIEW, DND_ITEM_TYPE_ENCODER], dndDropTarget, dndCollectTarget)(DragSource(DND_ITEM_TYPE_IP_OUTPUT, dndInputSource, dndCollectSource)(withLocalize(Output))));