import PropTypes from "prop-types";
import React, { Component } from "react";
import { Tooltip } from "reactstrap";
import { withLocalize } from "react-localize-redux";
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";
import AWBadgeLabel from "@aviwest/ui-kit/dist/js/components/badge-label";
import { formatOutputId } from "../../../../../utils/global-utils";

const propTypes = {
  connections: PropTypes.number,
  outputId: PropTypes.string.isRequired,
  finalName: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired
};

class ConnectionsIndicator extends Component {

  constructor(props) {
    super(props);
    this.toggleMessage = this.toggleMessage.bind(this);
    this.iconRef = React.createRef();

    this.state = {
      tooltipMessage: false
    }
  }

  toggleMessage() {
    this.setState({
      tooltipMessage: !this.state.tooltipMessage
    });
  }

  render() {
    const { connections, outputId, finalName, icon } = this.props;
    if (connections === null) {
      return (
        <AWBadgeLabel>
          {finalName}
        </AWBadgeLabel>
      )
    }
    return (
      <AWBadgeLabel left={formatOutputId(outputId, null)} right={
        <span className="connections-indicator" id={`connections-indicator-${outputId}`}><AWIcon name={icon} /> {connections}
          <Tooltip position="top-end" isOpen={this.state.tooltipMessage} target={`connections-indicator-${outputId}`} toggle={this.toggleMessage}>
            {`${connections} ${this.props.translate("genericLabel.CONNECTIONS.text")}`}
          </Tooltip>
        </span>
      }>
        {finalName}
      </AWBadgeLabel>
    );
  }
}

ConnectionsIndicator.propTypes = propTypes;

export default withLocalize(ConnectionsIndicator);
