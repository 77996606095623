import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Button } from 'reactstrap';
import { connect } from "react-redux";
import {Translate} from "react-localize-redux";
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";
import {DragSource} from 'react-dnd';
import {
  USER_ROLE_ADMIN,
  USER_ROLE_VIEWER,
  DND_ITEM_TYPE_MULTIVIEW,
  INPUT_ID_MULTIVIEW,
  DASHBOARD_LAYOUT_GRID,
  DASHBOARD_LAYOUT_INLINE,
  MULTIVIEW,
  STATUS_LIVE,
  STATUS_OFF,
  STATUS_ON
} from '../../../../../constants';
import StatusButton from '../../../../common/status-button/index';
import {
  multiViewEditSettings,
  multiViewStart,
  multiViewStop,
  openMultiViewModal
} from "../../dashboard.actions";
import Preview from "./preview";
import {inputPropTypes} from "../../../../../utils/models-prop-types";
import AudioLevels from "../../../../common/audio-levels/index";
import { AWControlGridItem, AWControlGridItemStatus, AWControlGridItemContent,
  AWControlGridItemContentMain, AWControlGridItemContentMainCompact, AWControlGridItemContentTitle,
  AWControlGridItemActions, AWControlGridItemContentMainDetails, AWControlGridItemHeader,
  AWControlGridItemHeaderControls, AWControlGridItemHeaderMetadata } from '@aviwest/ui-kit/dist/js/components/control-grid';
import Badge from 'reactstrap/lib/Badge';
import AWPopup, { AWPopupRow, AWPopupCol } from '@aviwest/ui-kit/dist/js/components/popup';
import AWBadgeLabel from "@aviwest/ui-kit/dist/js/components/badge-label";

const propTypes = {
  hasAdminLevel: PropTypes.bool,
  hasUserLevel: PropTypes.bool,
  layout: PropTypes.oneOf([DASHBOARD_LAYOUT_GRID, DASHBOARD_LAYOUT_INLINE]),
  multiView: PropTypes.object,
  inputs: PropTypes.arrayOf(inputPropTypes),
  callStart: PropTypes.func.isRequired,
  callStop: PropTypes.func.isRequired,
  callEditSettings: PropTypes.func.isRequired,
  isHighlighted: PropTypes.bool.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired,
};

const dndInputSource = {
  beginDrag() {
    return {
      id: INPUT_ID_MULTIVIEW
    };
  }
};

const dndCollect = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  }
};

class MultiView extends Component {

  constructor(props){
    super(props);

    this.handleStatusButtonChange = this.handleStatusButtonChange.bind(this);

    this.elementRef = React.createRef();
  }

  handleStatusButtonChange(statusRequested){
    if(statusRequested === STATUS_ON){
      this.props.callStart();
    }
    else {
      this.props.callStop();
    }
  }

  settingsMenu(props) {
    return (
      (props.layout === DASHBOARD_LAYOUT_INLINE && props.multiView.status === STATUS_OFF) ?
        <Fragment>
          <StatusButton id={"dashboard_input_multiView_"}
                        onChange={ this.handleStatusButtonChange }
                        disabled={props.hasViewerLevel}
                        status={ props.multiView.status }
                        active={ props.multiView.status !== STATUS_OFF }/>
          <Button id="dashboard_input_multiView_settingButton" className="basic" onClick={props.callEditSettings} disabled={!props.hasAdminLevel}>
            <AWIcon name="setting"/>
          </Button>
        </Fragment>
      :
      <AWPopup renderTrigger={({ ref, onClick, className, opened }) => (
        <button id="dashboard_input_multiView_settingMenuButton" ref={ref} className={className + " circular btn btn-secondary"} onClick={onClick} >
          <AWIcon name={opened ? "cancel" : "icon_menu_alt"} />
        </button>
      )}>
        {() => (
          <div>
            <Badge>{props.multiView.name}</Badge>
            <AWPopupRow>
              <AWPopupCol>
                <Button id="dashboard_input_multiView_setting" onClick={props.callEditSettings} disabled={!props.hasAdminLevel}>
                  <AWIcon name="setting"/>
                </Button>
              </AWPopupCol>
              { props.multiView.status !== STATUS_OFF &&
              <AWPopupCol>
                <Button id="dashboard_input_multiView_multiviewButton" onClick={props.callOpenMultiViewModal} disabled={props.hasViewerLevel}>
                  <AWIcon name="multiview"/>
                </Button>
              </AWPopupCol>
              }
            </AWPopupRow>
          </div>
        )}
      </AWPopup>
    )
  }

  render(){
    const {
      layout,
      multiView,
      isHighlighted,
      connectDragSource,
      isDragging,
      hasViewerLevel
    } = this.props;
    const { status } = multiView;

    connectDragSource(this.elementRef)

    return (
      <AWControlGridItem available
          ref={this.elementRef}
          collapsed={status === STATUS_OFF}
          className="multiview"
          highlighted={isHighlighted}
          dragged={isDragging}
          id="dashboard_input_multiview">
        <AWControlGridItemHeader>
          <AWControlGridItemStatus>

          </AWControlGridItemStatus>
          <AWControlGridItemHeaderMetadata align="right">
              <div className="source">
                <span className="type"><Translate id="genericLabel.MULTI_VIEW.text"/></span>
                <AWIcon name="multiview"/>
              </div>
          </AWControlGridItemHeaderMetadata>
          <AWControlGridItemHeaderControls >
            {this.settingsMenu(this.props)}
          </AWControlGridItemHeaderControls>
        </AWControlGridItemHeader>
        <AWControlGridItemContent>
        <AWControlGridItemContentMain>
          <AWControlGridItemContentMainCompact>
            { status !== STATUS_OFF &&
              <Preview multiView={multiView}/>
            }
            { status === STATUS_OFF && layout === DASHBOARD_LAYOUT_GRID &&
              <AWControlGridItemContentTitle>
                <AWBadgeLabel disabled><Translate id="genericLabel.MULTI_VIEW.text"/></AWBadgeLabel>
              </AWControlGridItemContentTitle>
            }
            { status === STATUS_OFF && layout === DASHBOARD_LAYOUT_INLINE &&
              <AWControlGridItemContentTitle>
                <Translate id="genericLabel.MULTI_VIEW.text"/>
              </AWControlGridItemContentTitle>
            }
            <AWControlGridItemActions>
              <StatusButton id={"dashboard_input_multiView_"}
                          onChange={ this.handleStatusButtonChange }
                          disabled={hasViewerLevel}
                          status={ status }
                          active={ status !== STATUS_OFF }/>
            </AWControlGridItemActions>
            </AWControlGridItemContentMainCompact>
              { status === STATUS_LIVE && layout === DASHBOARD_LAYOUT_INLINE &&
            <AWControlGridItemContentMainDetails>
                <AudioLevels inputId={`${multiView.audioChannelIndex}`}/>
          </AWControlGridItemContentMainDetails>
              }
            </AWControlGridItemContentMain>
        </AWControlGridItemContent>
      </AWControlGridItem>
    )
  }
}

MultiView.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
  return {
    hasAdminLevel: state.identity.role === USER_ROLE_ADMIN,
    hasViewerLevel: state.identity.role === USER_ROLE_VIEWER,
    multiView: state.streamhub.multiView,
    isHighlighted: state.dashboard.highlightedInput === MULTIVIEW,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    callStart: () => dispatch(multiViewStart()),
    callStop: () => dispatch(multiViewStop()),
    callEditSettings: () => dispatch(multiViewEditSettings(true)),
    callOpenMultiViewModal: () => dispatch(openMultiViewModal(true)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DragSource(DND_ITEM_TYPE_MULTIVIEW, dndInputSource, dndCollect)(MultiView));
