import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import {withRouter} from "react-router";
import {Translate, withLocalize} from "react-localize-redux";
import {Button} from "reactstrap";
import AWOutput from './output';
import Filters, {filtersPropTypes, hasAtLeastOneFilter} from '../../../common/dashboard-filters'
import LayoutSelector from '../../../common/layout-selector';
import PlayerModal from "../../../common/player-modal";
import Empty from "../../../common/empty";
import NewOutputButton from "./new-output-button";
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";

import {
  licensePropTypes,
  outputIpProfilePropTypes,
  outputPropTypes,
  videoCardCapabilitiesPropTypes
} from '../../../../utils/models-prop-types';

import {
  changeOutputsFilters,
  changeOutputsLayout,

  outputHardwareEditSettings,
  outputNDIEditSettings,
  outputIPEditSettings,
  outputIPPreview,
  inputPlaybackStart,
  outputStart,
  outputStop,
  outputLock,
  outputSwitchInput,
  outputChangePosition,
  outputPersistPositions,
  ipOutputsProfilesSettings,
  highlightInput,
  highlightEncoder,
  outputDetachIPProfile
} from '../dashboard.actions';

import {
  USER_ROLE_ADMIN,
  DASHBOARD_LAYOUT_GRID,
  DASHBOARD_LAYOUT_INLINE
} from "../../../../constants";

import {
  outputMatchingIPProfile,
  outputMatchingVideoCard
} from "../../../../utils/global-utils";

import {
  getOutputsCount,
  getVisibleHardwareOutputs,
  getVisibleNDIOutputs,
  getVisibleIPOutputs
} from "../dashboard.selectors";

import OutputHardwareSettingsModal from "./output-hardware-settings-modal";
import OutputNDISettingsModal from "./output-ndi-settings-modal";
import OutputIPSettingsModal from "./output-ip-settings-modal";
import {canAddNewIPOutput} from "../../../../misc/license.selectors";
import AWLoader from "@aviwest/ui-kit/dist/js/components/loader";
import AWControlGrid from '@aviwest/ui-kit/dist/js/components/control-grid';
import { openHelpModal } from '../../../../misc/help.actions';

const propTypes = {
  hasAdminLevel: PropTypes.bool,
  callChangeOutputsFilters: PropTypes.func.isRequired,
  callChangeOutputsLayout: PropTypes.func.isRequired,

  callOutputHardwareEditSettings: PropTypes.func.isRequired,
  callOutputNDIEditSettings: PropTypes.func.isRequired,
  callOutputIPEditSettings: PropTypes.func.isRequired,
  callOutputIPPreview: PropTypes.func.isRequired,
  callOutputStart: PropTypes.func.isRequired,
  callOutputStop: PropTypes.func.isRequired,
  callOutputSwitchInput: PropTypes.func.isRequired,
  callOutputPlaybackStart: PropTypes.func.isRequired,
  callOutputChangePosition: PropTypes.func.isRequired,
  callOutputPersistPositions: PropTypes.func.isRequired,
  callOutputDetachIPProfile: PropTypes.func.isRequired,
  openHelpModal: PropTypes.func.isRequired,

  outputIPProfiles: PropTypes.arrayOf(outputIpProfilePropTypes).isRequired,
  outputsCount: PropTypes.number.isRequired,
  canAddNewIPOutput: PropTypes.bool.isRequired,
  outputsFilters: filtersPropTypes.isRequired,
  outputsLayout: PropTypes.oneOf([DASHBOARD_LAYOUT_GRID, DASHBOARD_LAYOUT_INLINE]).isRequired,
  hardwareOutputsVisible: PropTypes.arrayOf(outputPropTypes).isRequired,
  ndiOutputsVisible: PropTypes.arrayOf(outputPropTypes).isRequired,
  ipOutputsVisible: PropTypes.arrayOf(outputPropTypes).isRequired,
  ready: PropTypes.bool.isRequired,
  previewingIPOutput: outputPropTypes,
  videoCards: PropTypes.arrayOf(videoCardCapabilitiesPropTypes),
  localIps: PropTypes.arrayOf(PropTypes.string),
  license: licensePropTypes,
};

class OutputSection extends Component {

  render() {
    const {
      hasAdminLevel,
      multiView,
      outputIPProfiles,
      outputsCount,
      canAddNewIPOutput,
      outputsFilters,
      outputsLayout,
      hardwareOutputsVisible,
      ndiOutputsVisible,
      ipOutputsVisible,
      ready,
      previewingIPOutput,
      videoCards,
      license,
      openHelpModal,
      localIps,
      translate
    } = this.props;
    const atLeastOneOutputsFilter = hasAtLeastOneFilter(outputsFilters);
    return (
        <Fragment>
        <div className="layout-section outputs">
          <div className="section-header">
            <div className="title text-clip">
              <Translate id="genericLabel.OUTPUTS.text"/>
              <Button id="dashboard_output_infoCircleButton"
                      className="basic icon"
                      size="sm"
                      onClick={() => openHelpModal("c_sh_outputs_overview.html")}>
                <AWIcon name="info_circle"/>
              </Button>
            </div>
            <div className="display-options">
              { hasAdminLevel && license != null && license.IPOutput > 0 &&
                <Button id="dashboard_output_optionsButton"
                        className="basic icon" title={translate('genericLabel.CONTRIBUTION_PROFILES.text')}
                        size="sm"
                        onClick={() => this.props.callOpenIpOutputsProfilesSettings(false)}>
                    <AWIcon name="options"/>
                </Button>
              }
              <Filters filters={outputsFilters}
                       id="output"
                       onChange={(filters) => this.props.callChangeOutputsFilters(filters)}/>
              <LayoutSelector onChange={ (layout) => this.props.callChangeOutputsLayout(layout)} selected={outputsLayout} id="dashboard_output"/>
            </div>
          </div>
          <AWControlGrid layout={outputsLayout} className="section-content pretty-scroll">
            { ready && hardwareOutputsVisible.length > 0 &&
            <div className="hardware">
              { hardwareOutputsVisible.map(output => <AWOutput key={ output.id }
                                                               ipProfile={ outputMatchingIPProfile(outputIPProfiles, output) }
                                                               ipProfiles={ outputIPProfiles }
                                                               multiView={ multiView }
                                                               onEditSettings={ this.props.callOutputHardwareEditSettings }
                                                               onStart={ this.props.callOutputStart }
                                                               onStop={ this.props.callOutputStop }
                                                               onLock={ this.props.callOutputLock }
                                                               onSwitchInput={ this.props.callOutputSwitchInput }
                                                               onStartPlayback={ this.props.callOutputPlaybackStart }
                                                               output={ output }
                                                               selectedInputId={this.props.match.params.inputId}
                                                               videoCard={ outputMatchingVideoCard(videoCards, output) }
                                                               onHoverInput={ this.props.highlightInput }
                                                               onHoverEncoder={ this.props.highlightEncoder }
                                                               layout={ outputsLayout } />)
              }
            </div>
            }
            { ready && hardwareOutputsVisible.length > 0 && ndiOutputsVisible.length > 0 && <div className="splitter"/> }
            { ready && ndiOutputsVisible.length > 0 &&
            <div className="hardware">
              { ndiOutputsVisible.map(output => <AWOutput key={ output.id }
                                                               ipProfile={ outputMatchingIPProfile(outputIPProfiles, output) }
                                                               ipProfiles={ outputIPProfiles }
                                                               multiView={ multiView }
                                                               onEditSettings={ this.props.callOutputNDIEditSettings }
                                                               onStart={ this.props.callOutputStart }
                                                               onStop={ this.props.callOutputStop }
                                                               onLock={ this.props.callOutputLock }
                                                               onSwitchInput={ this.props.callOutputSwitchInput }
                                                               onStartPlayback={ this.props.callOutputPlaybackStart }
                                                               output={ output }
                                                               selectedInputId={this.props.match.params.inputId}
                                                               onHoverInput={ this.props.highlightInput }
                                                               onHoverEncoder={ this.props.highlightEncoder }
                                                               layout={ outputsLayout } />)
              }
            </div>
            }
            { ready && (hardwareOutputsVisible.length > 0 || ndiOutputsVisible.length > 0) && <div className="splitter"/> }
            { ready &&
            <div className="ip">
              { ipOutputsVisible.map(output => <AWOutput key={ output.id }
                                                         ipProfile={ outputMatchingIPProfile(outputIPProfiles, output) }
                                                         ipProfiles={ outputIPProfiles }
                                                         multiView={ multiView }
                                                         onEditSettings={ this.props.callOutputIPEditSettings }
                                                         onChangePosition={ this.props.callOutputChangePosition }
                                                         onPersistPosition={this.props.callOutputPersistPositions}
                                                         onPreview={ this.props.callOutputIPPreview }
                                                         onStart={ this.props.callOutputStart }
                                                         onStop={ this.props.callOutputStop }
                                                         onLock={ this.props.callOutputLock }
                                                         onSwitchInput={ this.props.callOutputSwitchInput }
                                                         onStartPlayback={ this.props.callOutputPlaybackStart }
                                                         onDetachIPProfile={ this.props.callOutputDetachIPProfile }
                                                         output={ output }
                                                         selectedInputId={this.props.match.params.inputId}
                                                         onHoverInput={ this.props.highlightInput }
                                                         onHoverEncoder={ this.props.highlightEncoder }
                                                         layout={ outputsLayout } />)
              }

              { hasAdminLevel && canAddNewIPOutput &&
              <NewOutputButton onClick={() => this.props.callOpenIpOutputsProfilesSettings(true)}/>
              }
            </div>
            }
            { ready && hardwareOutputsVisible.length === 0 && ipOutputsVisible.length === 0 && atLeastOneOutputsFilter &&
            <Empty icon="filter"
                   text={<Translate id="genericLabel.OUTPUTS_FILTER_MATCH_EMPTY.text"/>}
                   subText={`(0/${outputsCount})`}/>
            }
            {!ready && <AWLoader active={true} position="centered"/> }
          </AWControlGrid>
        </div>
          <PlayerModal outputIPProfile={ previewingIPOutput ? outputMatchingIPProfile(outputIPProfiles, previewingIPOutput) : null }
                       onClose={ () => this.props.callOutputIPPreview(null) }
                       streamhubLocalIps={ localIps } />
          <OutputHardwareSettingsModal /> 
          <OutputNDISettingsModal />
          <OutputIPSettingsModal />
        </Fragment>
    );
  }
}

OutputSection.propTypes = propTypes;

const mapStateToProps = (state) => {
  const {
    ready,
    outputsFilters,
    outputsLayout,
    splitterPosition,
    previewingIPOutput
  } = state.dashboard;

  const {
    inputs,
    outputIPProfiles,
    videoCards,
    localIps
  } = state.streamhub;

  const license = state.license.details;
  return {
    hasAdminLevel: state.identity.role === USER_ROLE_ADMIN,
    ready,
    inputs,
    hardwareOutputsVisible: getVisibleHardwareOutputs(state),
    ndiOutputsVisible: getVisibleNDIOutputs(state),
    ipOutputsVisible: getVisibleIPOutputs(state),
    outputsCount: getOutputsCount(state),
    canAddNewIPOutput: canAddNewIPOutput(state),
    outputIPProfiles,
    outputsFilters,
    outputsLayout,
    splitterPosition: splitterPosition ? splitterPosition : undefined,
    previewingIPOutput,
    videoCards,
    localIps,
    license,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    callChangeOutputsFilters: (filters) => dispatch(changeOutputsFilters(filters)),
    callChangeOutputsLayout: (layout) => dispatch(changeOutputsLayout(layout)),
    callOpenIpOutputsProfilesSettings: (canSelect) => dispatch(ipOutputsProfilesSettings(true, canSelect)),

    callOutputStart: (output) => dispatch(outputStart(output)),
    callOutputStop: (id) => dispatch(outputStop(id)),
    callOutputLock: (output) => dispatch(outputLock(output)),
    callOutputHardwareEditSettings: (id) => dispatch(outputHardwareEditSettings(id)),
    callOutputNDIEditSettings: (id) => dispatch(outputNDIEditSettings(id)),
    callOutputIPEditSettings: (id) => dispatch(outputIPEditSettings(id)),
    callOutputSwitchInput: (output, inputId, encoderId, forceStart) => dispatch(outputSwitchInput(output, inputId, encoderId, forceStart)),
    callOutputPlaybackStart: (output, file) => dispatch(inputPlaybackStart(output, file)),
    callOutputIPPreview: (output) => dispatch(outputIPPreview(output)),
    callOutputChangePosition: (output, newIndex) => dispatch(outputChangePosition(output, newIndex)),
    callOutputPersistPositions: () => dispatch(outputPersistPositions()),
    callOutputDetachIPProfile: (output) => dispatch(outputDetachIPProfile(output)),
    openHelpModal: (filename) => dispatch(openHelpModal(filename, true)),
    highlightInput: (id) => dispatch(highlightInput(id)),
    highlightEncoder: (id) => dispatch(highlightEncoder(id)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withLocalize(OutputSection)));