import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import { Button, Col } from 'reactstrap';
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";

import AudioLevels from "../../../../common/audio-levels/index";
import BitrateIndicator from './bitrate-indicator';
import Graph from './graph';
import InputControls from './controls';
import InputPreview from './preview';
import IpProfileSelector from '../../../../common/ip-profile-selector/index';
import { inputIpProfilePropTypes, inputPropTypes } from "../../../../../utils/models-prop-types";
import {inputDisplayName} from "../../../../../utils/global-utils";
import Source from './source';
import DeviceStatus from './device-status';
import VideoReturnInfo from './video-return-info';
import BatteryLevel from './battery-level';
import MetaData from './meta-data';
import LiveProfileInfo from "./live-info";
import Intercom from "./intercom";
import RemoteControl from "./remote-control";
import Badge from 'reactstrap/lib/Badge';
import AWPopup, { AWPopupRow, AWPopupCol } from '@aviwest/ui-kit/dist/js/components/popup';
import {inputRemoteControl} from "../../dashboard.actions";

import {
  USER_ROLE_ADMIN,
  USER_ROLE_VIEWER,
  DASHBOARD_LAYOUT_FULLSIZE,
  DASHBOARD_LAYOUT_GRID,
  DASHBOARD_LAYOUT_INLINE,
  DND_ITEM_TYPE_FILE,
  DND_ITEM_TYPE_ENCODER,
  FORWARD_STATUS_FORWARDING,
  INPUT_TYPE_DEVICE,
  PLAYBACK_STATUS_OFF,
  STATUS_LIVE,
  STATUS_OFF,
  STATUS_ON,
  DND_ITEM_TYPE_INPUT,
  NDI,
  RECORD_STATUS_ON,
  STATUS_ERROR,
  STATUS_WARNING,
  PLAYBACK_STATUS_PAUSED,
  VIDEO_IFB_STATUS_STARTED,
  DEVICE_TYPE_STUDIO,
  DEVICE_TYPE_MOJOPRO_APP,
  INTERCOM_STATUS_ON,
  INTERCOM_STATUS_STARTING,
  INPUT_TYPE_IP,
  WEBRTC
} from '../../../../../constants';
import ForwardInfo from "./forward-info";
import ForwardDetail from "./forward-detail";
import StreamInfo from "./stream-info";
import NetworkInfo from "./network-info";
import LiveTimer from "./live-timer";
import {DragSource, DropTarget} from "react-dnd";
import Playback from "./playback";
import LatencyBitrateControls from "./latency-bitrate-controls";
import { AWControlGridItem, AWControlGridItemContent, AWControlGridItemContentAdditional, AWControlGridItemContentMain,
  AWControlGridItemContentMainCompact, AWControlGridItemContentMainDetails, AWControlGridItemHeader,
  AWControlGridItemHeaderControls, AWControlGridItemHeaderMetadata, AWControlGridItemStatus,
  AWControlGridItemActions } from '@aviwest/ui-kit/dist/js/components/control-grid';
import AWBadgeLabel from "@aviwest/ui-kit/dist/js/components/badge-label";
import Row from 'reactstrap/lib/Row';
import StatusButton from '../../../../common/status-button/index';
import { Translate, withLocalize } from 'react-localize-redux';

const INPUT_PRESENT_CONTROLS = 'CONTROLS';
const INPUT_PRESENT_PLAYBACK = 'PLAYBACK';
const INPUT_PRESENT_PLACEHOLDER = 'PLACEHOLDER';

const dndDropTarget = {

  canDrop(props, monitor) {
    const { input } = props;
    const itemType = monitor.getItemType();
    // Can drop file
    if(itemType === DND_ITEM_TYPE_FILE){
      return ((input.status === STATUS_OFF || input.playbackStatus === PLAYBACK_STATUS_PAUSED ) && !input.booking);
    }
    // Can drop encoder
    else if (itemType === DND_ITEM_TYPE_ENCODER) {
      const item = monitor.getItem();
      return (input.videoIFBDecoderCapability & 0x1) !== 0  && item.videoReturnMode;
    }
    // Can drop input
    else if (itemType === DND_ITEM_TYPE_INPUT) {
      const item = monitor.getItem();
      // Do not drop on itself
      return item.id !== props.input.id && (input.videoIFBDecoderCapability & item.deviceVideoReturnProfile) !== 0;
    }
  },

  drop(props, monitor) {
    const itemType = monitor.getItemType();
    const item = monitor.getItem();
    // Drop file
    if(itemType === DND_ITEM_TYPE_FILE){
      props.onPlaybackStart(props.input, item.file);
    }
    // Bind video IFB encoder to an input
    else if (itemType === DND_ITEM_TYPE_ENCODER) {
      props.onVideoIFBEncoderBind(props.input, item.id);
    }
    // Bind video IFB source to an input
    else if (itemType === DND_ITEM_TYPE_INPUT) {
      props.onVideoIFBSourceBind(props.input, item.id);
    }
  }
};

const dndCollectTarget = (connect, monitor) => {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
  };
};

const dndInputSource = {
  canDrag(props) {
    return true
  },
  beginDrag(props) {
    return {
      id: props.input.id,
      channelType: props.input.channelType,
      deviceVideoReturnProfile: props.input.deviceVideoReturnProfile
    };
  }
};

const dndCollectSource = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging()
  }
};

const propTypes = {
  hasAdminLevel: PropTypes.bool,
  hasViewerLevel: PropTypes.bool,
  orionConnectionStatus: PropTypes.bool.isRequired,
  canDrop: PropTypes.bool,
  connectDropTarget: PropTypes.func.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  connectDragPreview: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired,
  layout: PropTypes.oneOf([DASHBOARD_LAYOUT_GRID, DASHBOARD_LAYOUT_INLINE, DASHBOARD_LAYOUT_FULLSIZE]),
  input: inputPropTypes.isRequired,
  ipProfile: inputIpProfilePropTypes,
  ipProfiles: PropTypes.arrayOf(inputIpProfilePropTypes),
  isOver: PropTypes.bool.isRequired,
  onDisconnect: PropTypes.func.isRequired,
  onEditSettings: PropTypes.func.isRequired,
  onIntercomStart: PropTypes.func.isRequired,
  onIntercomStop: PropTypes.func.isRequired,
  onIPProfileChange: PropTypes.func.isRequired,
  onIPProfileCreate: PropTypes.func.isRequired,
  onIPProfileEdit: PropTypes.func.isRequired,
  onLatencyChange: PropTypes.func.isRequired,
  onRecordStart: PropTypes.func.isRequired,
  onRecordStop: PropTypes.func.isRequired,
  onEjectVideoIFB: PropTypes.func.isRequired,
  onRemoteLiveStart: PropTypes.func.isRequired,
  onRemoteLiveStop: PropTypes.func.isRequired,
  onRemoteForwardStop: PropTypes.func.isRequired,
  onRemoteProfileSwitch: PropTypes.func.isRequired,
  onStart: PropTypes.func.isRequired,
  onStop: PropTypes.func.isRequired,
  onPlaybackStart: PropTypes.func.isRequired,
  onPlaybackStop: PropTypes.func.isRequired,
  onPlaybackLoopOn: PropTypes.func.isRequired,
  onPlaybackLoopOff: PropTypes.func.isRequired,
  onShowDetails: PropTypes.func.isRequired,
  onCloseDetails: PropTypes.func.isRequired,
  onVideoCappedBitrateChange: PropTypes.func.isRequired,
  onResetDroppedPackets: PropTypes.func.isRequired,
  onRemoteControl: PropTypes.func.isRequired,
  onVideoIFBEncoderBind: PropTypes.func.isRequired,
  onVideoIFBSourceBind: PropTypes.func.isRequired,
  isHighlighted: PropTypes.bool.isRequired,
  webProxyPort: PropTypes.number,
  webProxyPortHttps: PropTypes.number,
  liveGuestHasIntercom: PropTypes.bool,
};

class Input extends Component {

  constructor(props){
    super(props);

    this.handleStatusButtonChange = this.handleStatusButtonChange.bind(this);
    this.elementRef = React.createRef();
    this.dragContentRef = React.createRef();
  }

  handleStatusButtonChange(statusRequested){
    const input = this.props.input;
    if(statusRequested === STATUS_ON){
      this.props.onStart(input);
    }
    else {
      if(input.playbackStatus !== PLAYBACK_STATUS_OFF){
        this.props.onPlaybackStop(input);
      }
      else if(input.ipProfileId){
        this.props.onStop(input);
      }
      else {
        this.props.onDisconnect(input);
      }
    }
  }

  settingsMenu(props) {
    return (
      (props.layout === DASHBOARD_LAYOUT_INLINE && props.input.status === STATUS_OFF) ?
        <Fragment>
          {!props.input.booking && props.ipProfile != null &&
            <Button className="basic icon"
              disabled={props.hasViewerLevel}
              onClick={() => props.onIPProfileChange(props.input, null)}
              title={props.translate('genericLabel.EJECT_IP_PROFILE.text')}>
              <AWIcon name="eject" />
            </Button>
          }
          {props.ipProfile != null && <StatusButton onChange={this.handleStatusButtonChange}
              id={"dashboard_input_"+props.input.id}
              disabled={props.hasViewerLevel}
              active={props.input.status !== STATUS_OFF}
              status={props.input.status}/>}
          {!props.input.booking && props.ipProfile != null &&
            <Button id={"dashboard_input_"+props.input.id+"_editIpProfileButton"}
              className="basic icon"
              disabled={!props.hasAdminLevel}
              onClick={() => props.onIPProfileEdit(props.input.ipProfileId)}
              title={props.translate('genericLabel.EDIT_IP_PROFILE_SELECTED.text')}>
              <AWIcon name="options" />
            </Button>
          }
          <Button id={"dashboard_input_"+props.input.id+"_settingsButton"}
            className="basic icon"
            onClick={() => props.onEditSettings(props.input.id)}
            disabled={!props.hasAdminLevel}
            title={props.translate('genericLabel.SETTINGS.text')}>
            <AWIcon name="setting"/>
          </Button>
        </Fragment>
      :
        <AWPopup renderTrigger={({ ref, onClick, className, opened }) => (
          <button id={"dashboard_input_"+props.input.id+"_menuSettingButton"} ref={ref} className={className + " circular btn btn-secondary"} onClick={onClick} >
              <AWIcon name={opened ? "cancel" : "icon_menu_alt"} />
          </button>
        )}>
          {({close}) => (
            <div>
              <Badge>{props.input.name}</Badge>
              <AWPopupRow>
                <AWPopupCol>
                  <Button id={"dashboard_input_"+props.input.id+"_SettingButton"}
                    onClick={() => props.onEditSettings(props.input.id)}
                    disabled={!props.hasAdminLevel}
                    title={props.translate('genericLabel.SETTINGS.text')}>
                    <AWIcon name="setting"/>
                    <span><Translate id="genericLabel.SETTINGS.text" /></span>
                  </Button>
                </AWPopupCol>
                { props.ipProfile != null &&
                <AWPopupCol>
                  <Button id={"dashboard_input_"+props.input.id+"_editIpProfileButton"}
                    onClick={() => {props.onIPProfileEdit(props.input.ipProfileId); close()}}
                    disabled={!props.hasAdminLevel}
                    title={props.translate('genericLabel.EDIT_IP_PROFILE_SELECTED.text')}>
                    <AWIcon name="options" />
                    <span><Translate id="genericLabel.EDIT_IP_PROFILE_SELECTED.text" /></span>
                  </Button>
                </AWPopupCol>
                }
                { props.input.status === STATUS_OFF && !props.input.booking && props.ipProfile != null &&
                <Fragment>
                  <AWPopupCol>
                    <Button id={"dashboard_input_"+props.input.id+"_ejectIpProfileButton"}
                      onClick={() => {props.onIPProfileChange(props.input, null); close()}}
                      disabled={props.hasViewerLevel}
                      title={props.translate('genericLabel.EJECT_IP_PROFILE.text')}>
                      <AWIcon name="eject" />
                      <span><Translate id="genericLabel.EJECT_IP_PROFILE.text" /></span>
                    </Button>
                  </AWPopupCol>
                </Fragment>}
                { props.input.playbackStatus !== PLAYBACK_STATUS_OFF &&
                  <AWPopupCol>
                    <Button id={"dashboard_input_"+props.input.id+"_ejectPlayback"}
                      onClick={() => {props.onPlaybackStop(props.input); close()}}
                      disabled={props.hasViewerLevel}
                      title={props.translate('genericLabel.EJECT_PLAYBACK.text')}>
                      <AWIcon name="eject" />
                      <span><Translate id="genericLabel.EJECT_PLAYBACK.text" /></span>
                    </Button>
                  </AWPopupCol>}
                { (props.input.type === INPUT_TYPE_DEVICE && props.input.playbackStatus === PLAYBACK_STATUS_OFF) &&
                  <AWPopupCol>
                    <Button id={"dashboard_input_"+props.input.id+"_ejectDevice"}
                      onClick={() => {props.onDisconnect(props.input); close()}}
                      disabled={props.hasViewerLevel}
                      title={props.translate('genericLabel.EJECT_DEVICE.text')}>
                      <AWIcon name="eject" />
                      <span><Translate id="genericLabel.EJECT_DEVICE.text" /></span>
                    </Button>
                  </AWPopupCol>}
                { props.input.type === INPUT_TYPE_DEVICE && props.input.deviceType !== DEVICE_TYPE_STUDIO && props.input.deviceType !== DEVICE_TYPE_MOJOPRO_APP &&
                  <AWPopupCol>
                    <Button id={"dashboard_input_"+props.input.id+"_remoteControlButton"}
                      onClick={() => {props.onRemoteControl(props.input); close()}}
                      title={props.translate('genericLabel.REMOTE_CONTROL.text')}
                      disabled={props.hasViewerLevel}>
                      <AWIcon name="remote_control" />
                      <span><Translate id="genericLabel.REMOTE_CONTROL.text" /></span>
                    </Button>
                  </AWPopupCol>}
                { (props.input.videoReturnEncSrcIdx >= 0 || props.input.videoReturnSrcIdx >= 0) &&
                  <AWPopupCol>
                    <Button id={"dashboard_input_"+props.input.id+"_ejectVideoButton"} 
                      onClick={ () => {props.onEjectVideoIFB(props.input); close()}}
                      disabled={props.hasViewerLevel}
                      title={props.translate('genericLabel.EJECT_VIDEO_IFB.text')}>
                      <AWIcon name="video_return_off" />
                      <span><Translate id="genericLabel.EJECT_VIDEO_IFB.text" /></span>
                    </Button>
                  </AWPopupCol>}
              </AWPopupRow>
            </div>
          )}
        </AWPopup>
    )
  }

  render(){
    const {
      canDrop,
      connectDropTarget,
      connectDragSource,
      connectDragPreview,
      isDragging,
      layout,
      input,
      ipProfile,
      ipProfiles,
      isOver,
      onIntercomStart,
      onIntercomStop,
      onIPProfileChange,
      onIPProfileCreate,
      onLatencyChange,
      onRecordStart,
      onRecordStop,
      onRemoteLiveStart,
      onRemoteLiveStop,
      onRemoteForwardStop,
      onRemoteProfileSwitch,
      onResetDroppedPackets,
      onShowDetails,
      onCloseDetails,
      onVideoCappedBitrateChange,
      configInputProtocol,
      orionConnectionStatus,
      webProxyPort,
      webProxyPortHttps,
      controllingInput,
      identity,
      hasViewerLevel,
      hasAdminLevel,
      liveGuestHasIntercom,
      configTooBig
    } = this.props;
    const { id, deviceForwardStatus, playbackStatus, status, deviceVideoIFBStatus } = input;
    const finalName = inputDisplayName(input, ipProfile);

    let present = null;
    if(status !== STATUS_OFF){
      if(playbackStatus !== PLAYBACK_STATUS_OFF){
        present = INPUT_PRESENT_PLAYBACK;
      }
      else {
        present = INPUT_PRESENT_CONTROLS;
      }
    }
    else if(layout === DASHBOARD_LAYOUT_GRID){
      present = INPUT_PRESENT_PLACEHOLDER;
    }

    connectDropTarget(this.elementRef)
    connectDragSource(this.dragContentRef)
    connectDragPreview(this.dragContentRef)

    return (
      <AWControlGridItem ref={this.elementRef} collapsed={status === STATUS_OFF}
                    available={status === STATUS_OFF}
                    dragged={isDragging}
                    className={ `aw-input${isOver ? (canDrop ? ' aw-target-valid' : ' aw-target-invalid') : ''}` }
                    inError={status === STATUS_ERROR}
                    inWarning={status === STATUS_WARNING}
                    highlighted={this.props.isHighlighted}
                    id={`dashboard_input_${this.props.input.id}`}>
        <AWControlGridItemHeader>
          <AWControlGridItemStatus>
            {(status === STATUS_LIVE) && <AWBadgeLabel fill color='red'>LIVE</AWBadgeLabel>}
            {(input.recordStatus === RECORD_STATUS_ON || input.deviceRecordStatus === RECORD_STATUS_ON) &&
              <AWBadgeLabel fill color='red'><AWIcon name="record" /> REC</AWBadgeLabel>}
          </AWControlGridItemStatus>
          <AWControlGridItemHeaderMetadata>
            { status !== STATUS_OFF &&
            <Fragment>
              <BitrateIndicator inputId={input.id}/>
              <LiveTimer inputId={input.id}/>
              <MetaData input={input}/>
            </Fragment>
            }
            </AWControlGridItemHeaderMetadata>
            <AWControlGridItemHeaderMetadata align="right">
              <DeviceStatus input={input}/>
              <Source input={input}
                      ipProfile={ipProfile}/>
              <BatteryLevel input={input}/>
              {deviceVideoIFBStatus === VIDEO_IFB_STATUS_STARTED && <VideoReturnInfo input={input}/>}
            </AWControlGridItemHeaderMetadata>
          <AWControlGridItemHeaderControls>
            {this.settingsMenu(this.props)}
          </AWControlGridItemHeaderControls>
        </AWControlGridItemHeader>
         <AWControlGridItemContent>
          <AWControlGridItemContentMain>
            <AWControlGridItemContentMainCompact ref={this.dragContentRef}>
            { present !== INPUT_PRESENT_PLAYBACK && deviceForwardStatus !== FORWARD_STATUS_FORWARDING &&
              <InputPreview input={input} ipProfile={ipProfile} layout={layout} configInputProtocol={configInputProtocol} />
            }
            { present === INPUT_PRESENT_CONTROLS && deviceForwardStatus === FORWARD_STATUS_FORWARDING &&
              <ForwardInfo deviceForwardInfo={input.deviceForwardInfo} deviceRecordStatus={input.deviceRecordStatus}/>
            }

            { present !== INPUT_PRESENT_PLAYBACK &&
              <Fragment>
                <IpProfileSelector input={input}
                                  hasAdminLevel={hasAdminLevel}
                                  hasViewerLevel={hasViewerLevel}
                                  detach={ ipProfile != null }
                                  ipProfiles={ ipProfiles }
                                  maxWidth={ layout === DASHBOARD_LAYOUT_GRID ? 100 : null }
                                  name={ finalName }
                                  onProfileChange={ (profileId) => onIPProfileChange(input, profileId) }
                                  onProfileCreate={ onIPProfileCreate }
                                  configTooBig={configTooBig}/>

                <AWControlGridItemActions>
                  <InputControls input={input}
                                  hasViewerLevel={hasViewerLevel}
                                  ipProfile={ipProfile}
                                  configInputProtocol={configInputProtocol}
                                  layout={layout}
                                  onIntercomStart={ onIntercomStart }
                                  onIntercomStop={ onIntercomStop }
                                  onRecordStart={ onRecordStart }
                                  onRecordStop={ onRecordStop }
                                  onRemoteLiveStart={ onRemoteLiveStart }
                                  onRemoteLiveStop={ onRemoteLiveStop }
                                  onRemoteForwardStop={ onRemoteForwardStop }
                                  onRemoteProfileSwitch={ onRemoteProfileSwitch }
                                  onStatusButtonChange={ this.handleStatusButtonChange }
                                  onShowDetails= { onShowDetails }
                                  onCloseDetails= { onCloseDetails }
                                  orionConnectionStatus= { orionConnectionStatus }
                                  liveGuestHasIntercom={ liveGuestHasIntercom }/>
                </AWControlGridItemActions>
              </Fragment>
            }

            { present === INPUT_PRESENT_PLAYBACK &&
              <Playback input={input}
                        hasViewerLevel={hasViewerLevel}
                        inputName={finalName}
                        layout={layout}
                        onCloseDetails={onCloseDetails}
                        onShowDetails={onShowDetails}/>
            }

          </AWControlGridItemContentMainCompact>
          <AWControlGridItemContentMainDetails>
            { deviceForwardStatus === FORWARD_STATUS_FORWARDING &&
              <ForwardDetail deviceForwardInfo={input.deviceForwardInfo}/>
            }

            { (input.type === INPUT_TYPE_DEVICE || (input.type === INPUT_TYPE_IP && ipProfile && ipProfile.protocol === WEBRTC))
              && (input.intercomStatus === INTERCOM_STATUS_ON || input.intercomStatus === INTERCOM_STATUS_STARTING) &&
              <Intercom input={input}/>
            }
            { controllingInput && input.id === controllingInput &&
            <RemoteControl identity={identity}
                           webProxyPort={webProxyPort}
                           webProxyPortHttps={webProxyPortHttps}
                           closeModal={this.props.callCloseModal}
                           input={input}/>
            }

            { (status === STATUS_LIVE || input.playbackStatus === PLAYBACK_STATUS_PAUSED) &&
            <Fragment>
              <AudioLevels inputId={id}/>
              { input.channelType !== NDI &&
                <div className="graph-container">
                  <Graph inputId={id}/>
                </div>
              }
              </Fragment>
            }
          </AWControlGridItemContentMainDetails>
          </AWControlGridItemContentMain>
          { layout === DASHBOARD_LAYOUT_FULLSIZE &&
          <AWControlGridItemContentAdditional>
            <Row>
            <Col xs="4">
              {input.deviceLiveInfo && deviceForwardStatus !== FORWARD_STATUS_FORWARDING &&
                <LiveProfileInfo input={input}/>
              }
              { status === STATUS_LIVE && input.channelType !== NDI &&
                <StreamInfo inputId={id}
                  onResetDroppedPackets={() => onResetDroppedPackets(input)}/>
              }
              { status === STATUS_LIVE && input.deviceLiveInfo &&
                <LatencyBitrateControls
                  sliderLatencyCapability={input.sliderLatencyCapability}
                  liveInfo={input.deviceLiveInfo}
                  onLatencyChange={(latency) => onLatencyChange(input, latency)}
                  onCappedBitrateChange={(bitrate) => onVideoCappedBitrateChange(input, bitrate)}
                  hasViewerLevel={hasViewerLevel}/>
              }
            </Col>
            <Col xs="8">
              { input.type === INPUT_TYPE_DEVICE &&
                <NetworkInfo input={input} hasViewerLevel={hasViewerLevel}/>
              }
            </Col>
            </Row>
          </AWControlGridItemContentAdditional>
          }
        </AWControlGridItemContent>
      </AWControlGridItem>
    )
  }
}

Input.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
  const webProxyPort = state.config.tunRemoteControl ? state.config.tunRemoteControl.webProxyPort : null;
  const webProxyPortHttps = state.config.tunRemoteControl ? state.config.tunRemoteControl.webProxyPortHttps : null;
  return {
    configInputProtocol: state.config.inputProtocol ? state.config.inputProtocol[ownProps.input.ipProfileId] : null,
    hasAdminLevel: state.identity.role === USER_ROLE_ADMIN,
    hasViewerLevel: state.identity.role === USER_ROLE_VIEWER,
    orionConnectionStatus: state.license.orionConnectionStatus,
    isHighlighted: state.dashboard.highlightedInput === ownProps.input.id,
    webProxyPort,
    webProxyPortHttps,
    controllingInput: state.dashboard.controllingInput,
    identity: state.identity,
    liveGuestHasIntercom: state.capabilities.liveGuestIntercom,
    configTooBig: state.streamhub.configTooBig
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    callCloseModal: () => dispatch(inputRemoteControl(null)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(DropTarget([DND_ITEM_TYPE_INPUT, DND_ITEM_TYPE_FILE, DND_ITEM_TYPE_ENCODER], dndDropTarget, dndCollectTarget)(DragSource(DND_ITEM_TYPE_INPUT, dndInputSource, dndCollectSource)(withLocalize(Input))));