import PropTypes from 'prop-types';
import React, {Component} from 'react';
import { connect } from 'react-redux';
import {Translate, withLocalize} from "react-localize-redux";
import { Button } from "reactstrap";
import {HLS, RTMP, RTSP, SRT, TS, SST, OUTPUT_TYPE_IP} from "../../../../constants";
import HLSForm from "./hls-form";
import RTMPForm from "./rtmp-form";
import RTSPForm from "./rtsp-form";
import SRTForm from "./srt-form";
import TSForm from "./ts-form";
import SAFESTREAMForm from "./safestream-form";
import { getAvailableDestinationInputs} from "../../../../misc/capabilities.selectors";
import AWConfirm from '@aviwest/ui-kit/dist/js/components/confirm';
import AWIcon from '@aviwest/ui-kit/dist/js/components/icon';
import {Alert} from "reactstrap";
import { outputIcon } from '../../../../utils/global-utils';

const propTypes = {
  editingProfileId: PropTypes.string,
  protocolSet: PropTypes.string,
  forbiddenNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  config: PropTypes.object,
  destinationInputs: PropTypes.arrayOf(PropTypes.string),
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  publicIp: PropTypes.string,
  publicHostname: PropTypes.string,
  localIps: PropTypes.arrayOf(PropTypes.string),
  rtspServer: PropTypes.object
};

class OutputIPProfileForms extends Component {

  constructor(props){
    super(props);

    this.handleModeChange = this.handleModeChange.bind(this);

    this.state = {
      mode: props.config ? props.config.mode : props.protocolSet ?? props.protocols[0]
    };
  }

  handleModeChange(e) {
    this.setState({
      mode: e.target.value
    });
  }

  render() {
    const { mode } = this.state;
    const { config, destinationInputs, forbiddenNames, forbiddenUris, forbiddenUDPPorts, localIps, onCancel, onSubmit, onDelete, publicIp, publicHostname, rtspServer } = this.props;
    let additionalActions = [];
    if(onDelete){
      additionalActions.push(
        <AWConfirm key={2}
          onConfirm={onDelete}
          confirmationText={ this.props.translate("genericLabel.SECOND_CLICK_CONFIRM.text") }
          render={(ref, onClick) => (
            <Button id="outputsProfile_deleteButton"
              innerRef={ref}
              onClick={onClick}
              className="mr-auto"
              outline
              color="primary">
              <Translate id="genericLabel.DELETE.text"/>
            </Button>
          )} />
      );
    }
    if(onCancel){
      additionalActions.push(
        <Button id="outputsProfile_cancelButton"
                key={1}
                onClick={onCancel}
                outline
                color="primary">
          <Translate id="genericLabel.CANCEL.text"/>
        </Button>
      );
    }
    const alert = config && config.enable === true ?
      <Alert color="info">
        <Translate id="genericLabel.IP_OUTPUT_RUNNING_PROFILE.text"/>
      </Alert> : null

    const protocol =
      <div className="protocol">
        <AWIcon name={outputIcon(OUTPUT_TYPE_IP, mode)} />
      </div>
    return (
      <>
        { mode === HLS &&
        <HLSForm additionalActions={additionalActions}
                 config={config}
                 forbiddenNames={forbiddenNames}
                 forbiddenUris={forbiddenUris}
                 localIps={localIps}
                 onSubmit={onSubmit}
                 publicIp={publicIp}
                 publicHostname={publicHostname}
                 alert={alert}
                 protocol={protocol}/>
        }
        { mode === RTMP &&
        <RTMPForm additionalActions={additionalActions}
                  config={config}
                  forbiddenNames={forbiddenNames}
                  forbiddenUris={forbiddenUris}
                  localIps={localIps}
                  onSubmit={onSubmit}
                  publicIp={publicIp}
                  publicHostname={publicHostname}
                  alert={alert}
                  protocol={protocol}/>
        }
        { mode === RTSP &&
        <RTSPForm additionalActions={additionalActions}
                  config={config}
                  forbiddenNames={forbiddenNames}
                  forbiddenUris={forbiddenUris}
                  localIps={localIps}
                  onSubmit={onSubmit}
                  publicIp={publicIp}
                  publicHostname={publicHostname}
                  rtspServer={rtspServer}
                  alert={alert}
                  protocol={protocol}/>
        }
        { mode === SRT &&
        <SRTForm additionalActions={additionalActions}
                 config={config}
                 forbiddenNames={forbiddenNames}
                 forbiddenUris={forbiddenUris}
                 forbiddenUDPPorts={forbiddenUDPPorts}
                 localIps={localIps}
                 onSubmit={onSubmit}
                 publicIp={publicIp}
                 publicHostname={publicHostname}
                 alert={alert}
                 protocol={protocol}/>
        }
        { mode === TS &&
        <TSForm additionalActions={additionalActions}
                config={config}
                forbiddenNames={forbiddenNames}
                forbiddenUris={forbiddenUris}
                localIps={localIps}
                onSubmit={onSubmit}
                publicHostname={publicHostname}
                alert={alert}
                protocol={protocol}/>
        }
        { mode === SST &&
        <SAFESTREAMForm additionalActions={additionalActions}
                        config={config}
                        destinationInputs={destinationInputs}
                        forbiddenNames={forbiddenNames}
                        forbiddenUris={forbiddenUris}
                        localIps={localIps}
                        onSubmit={onSubmit}
                        publicHostname={publicHostname}
                        alert={alert}
                        protocol={protocol}/>
        }
      </>
    );
  }
}

OutputIPProfileForms.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
  const config = state.config.streamingOutput ? state.config.streamingOutput[ownProps.editingProfileId] : null;
  const publicIp = state.streamhub.publicIp;
  const publicHostname = state.config.hostname;
  const localIps = state.streamhub.localIps;
  const forbiddenNames = [];
  const forbiddenUris = [];
  const forbiddenUDPPorts = state.streamhub.udpUsedPort.atpGeneralBasePorts.concat(
    state.streamhub.udpUsedPort.LiveGuestPorts,
    state.streamhub.udpUsedPort.inputProtocolPorts,
    state.streamhub.udpUsedPort.intercomPorts,
    state.streamhub.udpUsedPort.channelPorts,
    state.streamhub.udpUsedPort.remoteControlPorts).filter(port => config ? port !== config.port : true);
  state.streamhub.outputIPProfiles.forEach(ipProfile => {
    if(!config || ipProfile.id !== config.id){
      forbiddenNames.push(ipProfile.name);
      if(ipProfile.uri && (ipProfile.protocol !== "SST" || (ipProfile.protocol === "SST" && !ipProfile.uri.endsWith("AUTO")))){
        forbiddenUris.push({uri: ipProfile.uri, name: ipProfile.name});
      }
      if(ipProfile.publicUri){
        forbiddenUris.push({uri: ipProfile.publicUri, name: ipProfile.name});
      }
      if(ipProfile.localUris){
        ipProfile.localUris.forEach(localUri => forbiddenUris.push({uri: localUri, name: ipProfile.name}));
      }
      if(ipProfile.uris){
        ipProfile.uris.forEach(uri => forbiddenUris.push({uri: uri, name: ipProfile.name}));
      }
    }
  });
  if(config){
    config.id = ownProps.editingProfileId;
  }
  return {
    forbiddenNames,
    forbiddenUris,
    forbiddenUDPPorts,
    config,
    destinationInputs: getAvailableDestinationInputs(state),
    publicIp,
    publicHostname,
    localIps,
    rtspServer: state.config.rtspserver
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(OutputIPProfileForms));