import {
  LICENSE_MODAL,
  LICENSE_UPLOAD, LICENSE_UPLOAD_REPONSE,
  LICENSE_UPLOAD_SMPTE2110, LICENSE_UPLOAD_REPONSE_SMPTE2110
} from "../constants";


export const openLicenseModal = (open) => {
  return {
    type: LICENSE_MODAL,
    open
  }
};

export const uploadLicense = (file) => {
  return {
    type: LICENSE_UPLOAD,
    file
  }
};

export const licenseUploadResponse = (result) => {
  return {
    type: LICENSE_UPLOAD_REPONSE,
    result
  }
};

export const uploadLicenseSMPTE2110 = (file) => {
  //console.log("license.action")
  return {
    type: LICENSE_UPLOAD_SMPTE2110,
    file
  }
};

export const licenseUploadResponseSMPTE2110 = (result) => {
  //console.log("license.action responce")
  return {
    type: LICENSE_UPLOAD_REPONSE_SMPTE2110,
    result
  }
};