import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from "react-redux";
import {withLocalize} from "react-localize-redux";

// import {DEVICE_PRODUCT_DMNGPRO} from "../../../../../constants";
import AWModal from "@aviwest/ui-kit/dist/js/components/modal";
import {openMultiViewModal, multiViewChangeGrid, multiViewShowOverlay, multiViewSwitchAudio,} from "../../dashboard.actions"
import {inputIpProfilePropTypes, inputPropTypes} from "../../../../../utils/models-prop-types";
import MultiViewGrid from "./grid";

const propTypes = {
  callCloseModal: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired,
  multiView: PropTypes.object,
  inputs: PropTypes.arrayOf(inputPropTypes),
  inputIPProfiles: PropTypes.arrayOf(inputIpProfilePropTypes),
};

class MultiViewModal extends Component {

  constructor(props){
    super(props);

    this.state = {
    }
  }

  render(){
    const { opened, translate, multiView, inputs, inputIPProfiles,} = this.props;
    return (
    <AWModal id="multiview_modal"
             icon="multiview"
             onClose={this.props.callCloseModal}
             open={opened}
             title={ translate("genericLabel.MULTI_VIEW.text") }>
          { opened &&
          <MultiViewGrid multiView={multiView}
                          inputs={inputs}
                          status={multiView.status}
                          inputIPProfiles={inputIPProfiles}
                          onAudioSwitch={ this.props.callSwitchAudio }
                          onGridChange={ this.props.callChangeGridConf }
                          onShowOverlay={ this.props.callShowOverlay }/>
          }
        </AWModal>
    );
  }

}

MultiViewModal.propTypes = propTypes;

const mapStateToProps = (state) => {
  return {
    opened: state.dashboard.multiViewModal,
    multiView: state.streamhub.multiView,
    inputs: state.streamhub.inputs,
    inputIPProfiles: state.streamhub.inputIPProfiles,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    callCloseModal: () => dispatch(openMultiViewModal(false)),
    callSwitchAudio: (inputId) => dispatch(multiViewSwitchAudio(inputId)),
    callChangeGridConf: (data) => dispatch(multiViewChangeGrid(data)),
    callShowOverlay: (show) => dispatch(multiViewShowOverlay(show)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(MultiViewModal));