import AWBadgeLabel from "@aviwest/ui-kit/dist/js/components/badge-label";
import React, { useMemo } from "react";
import PropTypes from 'prop-types';
import { STATUS_ERROR, STATUS_WARNING, STATUS_LIVE, STATUS_OFF, STATUS_ON } from "../../../constants";

const propTypes = {
  status: PropTypes.oneOf([STATUS_LIVE, STATUS_OFF, STATUS_ON, STATUS_ERROR, STATUS_WARNING]).isRequired
};

const StatusBadge = ({ status }) => {

  const { statusDisplayed, color, offline } = useMemo(() => {
    switch (status) {
      case STATUS_LIVE:
        return { statusDisplayed: "LIVE", color: "red", offline: false };
      case STATUS_ON:
        return { statusDisplayed: "STARTED", color: "white", offline: false };
        case STATUS_OFF:
        return { statusDisplayed: "NOT STARTED", color: "white", offline: true };
      default:
        return { statusDisplayed: "", color: "", offline: false };
    }
  }, [status]);

  if (statusDisplayed === "") {
    return null;
  } else {
    return (
      <AWBadgeLabel fill offline={offline} color={color} title={statusDisplayed}>{statusDisplayed}</AWBadgeLabel>
    )
  }
};

StatusBadge.propTypes = propTypes;

export default StatusBadge;