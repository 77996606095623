import {
  DASHBOARD_INPUT_ATTACH_PROFILE,
  DASHBOARD_INPUT_START,
  DASHBOARD_INPUT_STOP,
  DASHBOARD_INPUT_INTERCOM_START,
  DASHBOARD_INPUT_INTERCOM_STOP,
  DASHBOARD_INPUT_RECORD_START,
  DASHBOARD_INPUT_RECORD_STOP,
  DASHBOARD_INPUT_EJECT_VIDEO_IFB,
  DASHBOARD_INPUT_REMOTE_LIVE_START,
  DASHBOARD_INPUT_REMOTE_LIVE_STOP,
  DASHBOARD_INPUT_REMOTE_FORWARD_STOP,
  DASHBOARD_INPUT_REMOTE_PROFILE_SWITCH,
  DASHBOARD_INPUT_SETTINGS,
  DASHBOARD_INPUTS_LAYOUT_CHANGE,
  DASHBOARD_INPUTS_FILTER_CHANGE,
  DASHBOARD_OUTPUTS_LAYOUT_CHANGE,
  DASHBOARD_OUTPUTS_FILTER_CHANGE,
  DASHBOARD_OUTPUT_START,
  DASHBOARD_OUTPUT_STOP,
  DASHBOARD_OUTPUT_LOCK,
  DASHBOARD_OUTPUT_UNLOCK,
  DASHBOARD_OUTPUT_HARDWARE_SETTINGS,
  DASHBOARD_OUTPUT_NDI_SETTINGS,
  DASHBOARD_OUTPUT_IP_SETTINGS,
  DASHBOARD_OUTPUT_SWITCH_INPUT,
  DASHBOARD_OUTPUT_ATTACH_PROFILE,
  DASHBOARD_OUTPUT_DETACH_PROFILE,
  DASHBOARD_INPUT_DETACH_PROFILE,
  DASHBOARD_INPUT_DISCONNECT,
  DASHBOARD_SPLITTER_POSITION_CHANGE,
  LS_DASHBOARD_VERTICAL_SIZES,
  LS_DASHBOARD_HORIZONTAL_SIZES,
  LS_DASHBOARD_OUTPUTS_FILTERS,
  LS_DASHBOARD_INPUTS_FILTERS,
  LS_DASHBOARD_INPUTS_LAYOUT,
  LS_DASHBOARD_OUTPUTS_LAYOUT,
  DASHBOARD_INPUT_PLAYBACK_PLAY,
  DASHBOARD_INPUT_PLAYBACK_SEEK,
  DASHBOARD_INPUT_PLAYBACK_STOP,
  DASHBOARD_INPUT_PLAYBACK_PAUSE,
  DASHBOARD_INPUT_PLAYBACK_LOOP_ON,
  DASHBOARD_INPUT_PLAYBACK_LOOP_OFF,
  DASHBOARD_INPUT_PLAYBACK_START,
  DASHBOARD_INPUT_VIDEO_IFB_ENCODER_BIND,
  DASHBOARD_INPUT_VIDEO_IFB_SOURCE_BIND,
  DASHBOARD_HIGHLIGHT_INPUT,
  DASHBOARD_MULTIVIEW_START,
  DASHBOARD_MULTIVIEW_SWITCH_AUDIO,
  DASHBOARD_MULTIVIEW_STOP,
  DASHBOARD_MULTIVIEW_SETTINGS,
  DASHBOARD_MULTIVIEW_CHANGE_GRID,
  DASHBOARD_OUTPUT_IP_PREVIEW,
  DASHBOARD_MOBILE_SHOW_OUTPUTS,
  DASHBOARD_INPUT_SUBSCRIBE,
  DASHBOARD_INPUT_UNSUBSCRIBE,
  DASHBOARD_INPUT_CHANGE_VIDEO_CAPPED_BITRATE,
  DASHBOARD_INPUT_RESET_DROPPED_PACKETS,
  DASHBOARD_INPUT_REMOTE_CONTROL,
  DASHBOARD_INPUT_REMOTE_CONTROL_TOKEN_REPLACE,
  DASHBOARD_INTERCOM_MUTE,
  DASHBOARD_INPUT_INTERCOM_AUDIO_PATTERN,
  DASHBOARD_ENCODERS_DISPLAY,
  DASHBOARD_ENCODER_START,
  DASHBOARD_ENCODER_STOP,
  DASHBOARD_ENCODER_ATTACH_PROFILE,
  DASHBOARD_ENCODER_DETACH_PROFILE,
  DASHBOARD_ENCODER_SWITCH_INPUT,
  DASHBOARD_ENCODER_SETTINGS,
  DASHBOARD_ENCODER_RECORD_START,
  DASHBOARD_ENCODER_RECORD_STOP,
  DASHBOARD_HIGHLIGHT_ENCODER,
  DASHBOARD_FILES_DISPLAY,
  DASHBOARD_INPUT_CHANGE_LATENCY,
  DASHBOARD_ENCODERS_LAYOUT_CHANGE,
  DASHBOARD_FILES_LAYOUT_CHANGE,
  DASHBOARD_OUTPUTS_CHANGE_POSITION,
  DASHBOARD_OUTPUTS_PERSIST_POSITIONS,
  DASHBOARD_INPUT_ENTER_DETAILS, DASHBOARD_INPUT_CLOSE_DETAILS, DASHBOARD_MULTIVIEW_SHOW_OVERLAY,
  DASHBOARD_MULTIVIEW_MODAL,
  DASHBOARD_INPUT_RECONNECT_MODEM,
  DASHBOARD_INPUT_DISCONNECT_MODEM,
  DASHBOARD_IP_INPUTS_PROFILES_SETTINGS,
  DASHBOARD_IP_OUTPUTS_PROFILES_SETTINGS,
  DASHBOARD_ENCODERS_PROFILES_SETTINGS
} from '../../../constants';

export const changeEncodersLayout = (layout) => {
  return {
    type: DASHBOARD_ENCODERS_LAYOUT_CHANGE,
    layout
  }
};

export const changeInputsLayout = (layout) => {
  localStorage.setItem(LS_DASHBOARD_INPUTS_LAYOUT, layout);
  return {
    type: DASHBOARD_INPUTS_LAYOUT_CHANGE,
    layout
  }
};

export const changeInputsFilters = (filters) => {
  localStorage.setItem(LS_DASHBOARD_INPUTS_FILTERS, JSON.stringify(filters));
  return {
    type: DASHBOARD_INPUTS_FILTER_CHANGE,
    filters
  }
};

export const inputEnterDetails = (input) => {
  return {
    type: DASHBOARD_INPUT_ENTER_DETAILS,
    input
  }
};

export const inputCloseDetails = (input) => {
  return {
    type: DASHBOARD_INPUT_CLOSE_DETAILS,
    input
  }
};

export const changeOutputsLayout = (layout) => {
  localStorage.setItem(LS_DASHBOARD_OUTPUTS_LAYOUT, layout);
  return {
    type: DASHBOARD_OUTPUTS_LAYOUT_CHANGE,
    layout
  }
};

export const changeDashboardFilesLayout = (layout) => {
  return {
    type: DASHBOARD_FILES_LAYOUT_CHANGE,
    layout
  }
};

export const changeOutputsFilters = (filters) => {
  localStorage.setItem(LS_DASHBOARD_OUTPUTS_FILTERS, JSON.stringify(filters));
  return {
    type: DASHBOARD_OUTPUTS_FILTER_CHANGE,
    filters
  }
};

export const changeSizes = (vertical, sizes) => {
  if(vertical){
    localStorage.setItem(LS_DASHBOARD_VERTICAL_SIZES, JSON.stringify(sizes));
  }
  else {
    localStorage.setItem(LS_DASHBOARD_HORIZONTAL_SIZES, JSON.stringify(sizes));
  }
  return {
    type: DASHBOARD_SPLITTER_POSITION_CHANGE,
    sizes,
    vertical
  }
};

export const mobileDisplayOutputs = (display) => {
  return {
    type: DASHBOARD_MOBILE_SHOW_OUTPUTS,
    display
  }
};

export const displayEncoders = (display) => {
  return {
    type: DASHBOARD_ENCODERS_DISPLAY,
    display
  }
};

export const displayFiles = (display) => {
  return {
    type: DASHBOARD_FILES_DISPLAY,
    display
  }
};

export const ipInputsProfilesSettings = (open, profileToEdit = null, backToDashboard = false, channelProfileId = -1)  => {
  return {
    type: DASHBOARD_IP_INPUTS_PROFILES_SETTINGS,
    open,
    profileToEdit,
    backToDashboard,
    channelProfileId
  }
};

export const ipOutputsProfilesSettings = (open, canSelect) => {
  return {
    type: DASHBOARD_IP_OUTPUTS_PROFILES_SETTINGS,
    open,
    canSelect
  }
};

export const encodersProfilesSettings = (open, canSelect) => {
  return {
    type: DASHBOARD_ENCODERS_PROFILES_SETTINGS,
    open,
    canSelect
  }
};

// MultiView

export const multiViewStart = () => {
  return {
    type: DASHBOARD_MULTIVIEW_START
  }
};

export const multiViewStop = () => {
  return {
    type: DASHBOARD_MULTIVIEW_STOP
  }
};

export const multiViewEditSettings = (open) => {
  return {
    type: DASHBOARD_MULTIVIEW_SETTINGS,
    open
  }
};

export const multiViewSwitchAudio = (inputId) => {
  return {
    type: DASHBOARD_MULTIVIEW_SWITCH_AUDIO,
    inputId
  }
};

export const multiViewChangeGrid = (configuration) => {
  return {
    type: DASHBOARD_MULTIVIEW_CHANGE_GRID,
      ...configuration
  }
};

export const multiViewShowOverlay = (showOverlayInfo) => {
  return {
    type: DASHBOARD_MULTIVIEW_SHOW_OVERLAY,
    showOverlayInfo
  }
};

export const openMultiViewModal = (open) => {
  return {
    type: DASHBOARD_MULTIVIEW_MODAL,
    open
  }
};

// Encoders
export const encoderStart = (encoder) => {
  return {
    type: DASHBOARD_ENCODER_START,
    encoder
  }
};

export const encoderStop = (encoder) => {
  return {
    type: DASHBOARD_ENCODER_STOP,
    encoder
  }
};

export const encoderAttachProfile = (encoder, profileId) => {
  return {
    type: DASHBOARD_ENCODER_ATTACH_PROFILE,
    encoder,
    profileId
  }
};

export const encoderDetachProfile = (encoder) => {
  return {
    type: DASHBOARD_ENCODER_DETACH_PROFILE,
    encoder
  }
};

export const encoderSwitchInput = (encoder, inputId, forceStart) => {
  return {
    type: DASHBOARD_ENCODER_SWITCH_INPUT,
    encoder,
    inputId,
    forceStart
  }
};

export const encoderEditSettings = (encoderId) => {
  return {
    type: DASHBOARD_ENCODER_SETTINGS,
    encoderId
  }
};

export const highlightEncoder = (encoderId) => {
  return {
    type: DASHBOARD_HIGHLIGHT_ENCODER,
    encoderId
  }
}

export const encoderStartRecord = (encoder) => {
  return {
    type: DASHBOARD_ENCODER_RECORD_START,
    encoder
  }
};

export const encoderStopRecord = (encoder) => {
  return {
    type: DASHBOARD_ENCODER_RECORD_STOP,
    encoder
  }
};

// Inputs

export const inputStart = (input) => {
  return {
    type: DASHBOARD_INPUT_START,
    input
  }
};

export const inputStop = (input) => {
  return {
    type: DASHBOARD_INPUT_STOP,
    input
  }
};

export const inputDisconnect = (input) => {
  return {
    type: DASHBOARD_INPUT_DISCONNECT,
    input
  }
};

export const inputAttachIPProfile = (input, profileId) => {
  return {
    type: DASHBOARD_INPUT_ATTACH_PROFILE,
    input,
    profileId
  }
};

export const inputDetachIPProfile = (input) => {
  return {
    type: DASHBOARD_INPUT_DETACH_PROFILE,
    input
  }
};

export const inputEditSettings = (inputId) => {
  return {
    type: DASHBOARD_INPUT_SETTINGS,
    inputId
  }
};

export const inputStartIntercom = (input) => {
  return {
    type: DASHBOARD_INPUT_INTERCOM_START,
    input
  }
};

export const inputStopIntercom = (input) => {
  return {
    type: DASHBOARD_INPUT_INTERCOM_STOP,
    input
  }
};

export const inputStartRecord = (input) => {
  return {
    type: DASHBOARD_INPUT_RECORD_START,
    input
  }
};

export const inputStopRecord = (input) => {
  return {
    type: DASHBOARD_INPUT_RECORD_STOP,
    input
  }
};

export const inputEjectVideoIFB = (input) => {
  return {
    type: DASHBOARD_INPUT_EJECT_VIDEO_IFB,
    input
  }
};

export const inputRemoteStartLive = (input) => {
  return {
    type: DASHBOARD_INPUT_REMOTE_LIVE_START,
    input
  }
};

export const inputRemoteStopLive = (input) => {
  return {
    type: DASHBOARD_INPUT_REMOTE_LIVE_STOP,
    input
  }
};

export const inputRemoteStopForward = (input) => {
  return {
    type: DASHBOARD_INPUT_REMOTE_FORWARD_STOP,
    input
  }
};

export const inputRemoteSwitchProfile = (input, profileId) => {
  return {
    type: DASHBOARD_INPUT_REMOTE_PROFILE_SWITCH,
    input,
    profileId
  }
};

export const inputRemoteControl = (inputId) => {
  return {
    type: DASHBOARD_INPUT_REMOTE_CONTROL,
    inputId
  }
};

export const remoteControlTokenReplace = (url, deviceHardwareID) => {
  return {
    type: DASHBOARD_INPUT_REMOTE_CONTROL_TOKEN_REPLACE,
    url,
    deviceHardwareID
  }
};

export const intercomMute = (inputId, channelType, mute) => {
  return {
    type: DASHBOARD_INTERCOM_MUTE,
    inputId,
    channelType,
    mute
  }
};

export const inputIntercomAudioPattern = (inputId, active) => {
  return {
    type: DASHBOARD_INPUT_INTERCOM_AUDIO_PATTERN,
    inputId,
    active
  }
};

export const inputSubscribe = (input) => {
  return {
    type: DASHBOARD_INPUT_SUBSCRIBE,
    input
  }
};

export const inputUnsubscribe = (input) => {
  return {
    type: DASHBOARD_INPUT_UNSUBSCRIBE,
    input
  }
};

export const inputChangeVideoCappedBitrate = (input, videoCappedBitrate) => {
  return {
    type: DASHBOARD_INPUT_CHANGE_VIDEO_CAPPED_BITRATE,
    input,
    videoCappedBitrate
  }
};

export const inputChangeLatency = (input, latency) => {
  return {
    type: DASHBOARD_INPUT_CHANGE_LATENCY,
    input,
    latency
  }
};

export const inputResetDroppedPacketsCounter = (input) => {
  return {
    type: DASHBOARD_INPUT_RESET_DROPPED_PACKETS,
    input
  }
};

export const inputPlaybackStart = (input, file) => {
  return {
    type: DASHBOARD_INPUT_PLAYBACK_START,
    input,
    file
  }
};

export const inputPlaybackPlay = (input) => {
  return {
    type: DASHBOARD_INPUT_PLAYBACK_PLAY,
    input
  }
};

export const inputPlaybackPause = (input) => {
  return {
    type: DASHBOARD_INPUT_PLAYBACK_PAUSE,
    input
  }
};

export const inputPlaybackStop = (input) => {
  return {
    type: DASHBOARD_INPUT_PLAYBACK_STOP,
    input
  }
};

export const inputPlaybackLoopOn = (input) =>{
  return {
    type: DASHBOARD_INPUT_PLAYBACK_LOOP_ON,
    input
  }
};

export const inputPlaybackLoopOff = (input) =>{
  return {
    type: DASHBOARD_INPUT_PLAYBACK_LOOP_OFF,
    input
  }
};

export const inputVideoIFBEncoderBind = (inputId, encoderId) => {
  return {
    type: DASHBOARD_INPUT_VIDEO_IFB_ENCODER_BIND,
    inputId,
    encoderId
  }
};

export const inputVideoIFBSourceBind = (inputId, sourceId) => {
  return {
    type: DASHBOARD_INPUT_VIDEO_IFB_SOURCE_BIND,
    inputId,
    sourceId
  }
};

export const inputPlaybackSeek = (input, seeking) => {
  return {
    type: DASHBOARD_INPUT_PLAYBACK_SEEK,
    input,
    seeking
  }
};

export const highlightInput = (inputId) => {
  return {
    type: DASHBOARD_HIGHLIGHT_INPUT,
    inputId
  }
}

// Outputs

export const outputStart = (output) => {
  return {
    type: DASHBOARD_OUTPUT_START,
    output,
    itemConfig: 'enable'
  }
};

export const outputStop = (output) => {
  return {
    type: DASHBOARD_OUTPUT_STOP,
    output,
    itemConfig: 'enable'
  }
};

export const outputLock = (output) => {
  return {
    type: output.lockstate ? DASHBOARD_OUTPUT_UNLOCK : DASHBOARD_OUTPUT_LOCK,
    output,
    itemConfig: 'lockstate'
  }
};

export const outputHardwareEditSettings = (outputId) => {
  return {
    type: DASHBOARD_OUTPUT_HARDWARE_SETTINGS,
    outputId
  }
};

export const outputNDIEditSettings = (outputId) => {
  return {
    type: DASHBOARD_OUTPUT_NDI_SETTINGS,
    outputId
  }
};

export const outputIPEditSettings = (outputId) => {
  return {
    type: DASHBOARD_OUTPUT_IP_SETTINGS,
    outputId
  }
};

export const outputSwitchInput = (output, inputId, encoderId, forceStart) => {
  return {
    type: DASHBOARD_OUTPUT_SWITCH_INPUT,
    output,
    inputId,
    encoderId,
    forceStart
  }
};

export const outputAttachIPProfile = (profileId) => {
  return {
    type: DASHBOARD_OUTPUT_ATTACH_PROFILE,
    profileId
  }
};

export const outputDetachIPProfile = (output) => {
  return {
    type: DASHBOARD_OUTPUT_DETACH_PROFILE,
    output
  }
};

export const outputIPPreview = (output) => {
  return {
    type: DASHBOARD_OUTPUT_IP_PREVIEW,
    output
  }
};

export const outputChangePosition = (output, newIndex) => {
  return {
    type: DASHBOARD_OUTPUTS_CHANGE_POSITION,
    output,
    index: newIndex
  }
};

export const outputPersistPositions = () => {
  return {
    type: DASHBOARD_OUTPUTS_PERSIST_POSITIONS
  };
};

export const reconnectModem = (name, input) => {
  return {
    type: DASHBOARD_INPUT_RECONNECT_MODEM,
    name,
    input
  }
};

export const disconnectModem = (name, input) => {
  return {
    type: DASHBOARD_INPUT_DISCONNECT_MODEM,
    name,
    input
  }
};
