import {Formik} from "formik";
import PropTypes from "prop-types";
import React from "react";
import {Button, Form, FormFeedback, FormGroup, Input, Label} from "reactstrap";
import {Translate, withLocalize} from "react-localize-redux";

import {generateUniqueId, isEmptyString} from "../../../../utils/string-utils";
import {DEJITTER_BUFFER_MAX, DEJITTER_BUFFER_MIN, DEJITTER_BUFFER_DEFAULT, HLS} from "../../../../constants";
import AWConfirm from "@aviwest/ui-kit/dist/js/components/confirm";
import HelpLayout from "../../../common/help-layout";

const propTypes = {
  config: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired,
    uniqueId: PropTypes.string
  }),
  forbiddenNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  alert: PropTypes.node,
  protocol: PropTypes.node
};

const HLSForm = (props) => {
  const { config, forbiddenNames, translate, alert, protocol } = props;

  const handleFormSubmit = (values) => {
    
    props.onSubmit(values);
  };

  const handleValidation = (values) => {
    const errors = {};

    // Name
    if(isEmptyString(values.name)){
      errors.name = 'genericLabel.REQUIRED_FIELD.text';
    }
    else if(values.name.length >= 32){
      errors.name = 'genericLabel.TOO_LONG.text';
    }
    else if(forbiddenNames.indexOf(values.name) !== -1){
      errors.name = 'genericLabel.DUPLICATED_VALUES.text';
    }

    if(isEmptyString(values.uri)){
      errors.uri = 'genericLabel.REQUIRED_FIELD.text';
    }

    // DeJitterBuffer
    if(isEmptyString(values.dejitterBuffer)){
      errors.dejitterBuffer = 'genericLabel.REQUIRED_FIELD.text';
    }
    else if(parseInt(values.dejitterBuffer) < DEJITTER_BUFFER_MIN || parseInt(values.dejitterBuffer) > DEJITTER_BUFFER_MAX){
      errors.dejitterBuffer = 'genericLabel.INVALID_FORMAT.text';
    }

    return errors;
  };

  return (
    <Formik initialValues={{
              id: config ? config.id : undefined,
              type: HLS,
              name: config ? config.name : '',
              uri: config ? config.uri : '',
              dejitterBuffer: config ? config.dejitterBuffer : DEJITTER_BUFFER_DEFAULT,
              uniqueId: config && config.uniqueId ? config.uniqueId : generateUniqueId()
            }}
            validate={ handleValidation }
            validateOnBlur={false}
            validateOnChange={true}
            onSubmit={ handleFormSubmit }>
      {({
          values,
          errors,
          dirty,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          /* and other goodies */
        }) => (
        <Form onSubmit={ handleSubmit }>

          <HelpLayout
          filename={`c_sh_hls_input_profile.html`}
          form={<fieldset disabled={config && config.enable === true}>
            {alert}
            {protocol}
            <FormGroup>
              <Label for="name">
                <Translate id="genericLabel.NAME.text"/>
              </Label>
              <Input type="text"
                    name="name"
                    id="inputsProfile_hls_name"
                    invalid={errors.name !== undefined}
                    placeholder={ translate('genericLabel.NAME.text') }
                    value={values.name}
                    onChange={handleChange}/>
              <Input type="hidden"
                     name="uniqueId"
                     id="uniqueId"
                     readOnly={true}
                     value={values.uniqueId}/>
              <FormFeedback>
                <Translate id={errors.name} />
              </FormFeedback>
            </FormGroup>

            <FormGroup>
              <Label for="dir">
                <Translate id="genericLabel.URI.text"/>
              </Label>
              <Input type="text"
                    name="uri"
                    id="inputsProfile_hls_uri"
                    invalid={errors.uri !== undefined}
                    placeholder={ translate('genericLabel.URI.text') }
                    value={values.uri}
                    onChange={handleChange}/>
              <FormFeedback>
                <Translate id={errors.uri} />
              </FormFeedback>
            </FormGroup>

            <FormGroup>
              <Label for="dejitterBuffer">
                <Translate id="genericLabel.DEJITTER_BUFFER.text"/>
              </Label>
              <Input type="number"
                    name="dejitterBuffer"
                    id="inputsProfile_hls_dejitterBuffer"
                    invalid={errors.dejitterBuffer !== undefined}
                    min={DEJITTER_BUFFER_MIN}
                    max={DEJITTER_BUFFER_MAX}
                    placeholder={ translate('genericLabel.DEJITTER_BUFFER.text') }
                    value={values.dejitterBuffer}
                    onChange={handleChange}/>
              <FormFeedback>
                <Translate id={errors.dejitterBuffer} />
              </FormFeedback>
              <div className="indicator">
                <Translate id={`genericLabel.DEJITTER_BUFFER_HELP.text`}/>
              </div>
            </FormGroup>
            </fieldset>}

            buttons={<FormGroup className="buttons">
              { props.onDelete && !props.backToDashboard &&
              <AWConfirm
                onConfirm={props.onDelete}
                confirmationText={ props.translate("genericLabel.SECOND_CLICK_CONFIRM.text") }
                render={(ref, onClick) => (
                  <Button id="inputsProfile_hls_deleteButton"
                    innerRef={ref}
                    onClick={onClick}
                    disabled={config && config.enable === true}
                    className="mr-auto"
                    outline
                    color="primary">
                    <Translate id="genericLabel.DELETE.text"/>
                  </Button>
                )} />
              }
              { props.onCancel && !props.backToDashboard &&
              <Button id="inputsProfile_hls_cancelButton"
                onClick={props.onCancel}
                outline
                color="primary">
                <Translate id="genericLabel.CANCEL.text"/>
              </Button>
              }
              <Button id="inputsProfile_hls_saveButton"
                      color="primary"
                      disabled={!dirty}
                      type="submit">
                <Translate id="genericLabel.SAVE.text"/>
              </Button>
            </FormGroup>
          } />
        </Form>
      )}
    </Formik>
  );
};

HLSForm.propTypes = propTypes;

export default withLocalize(HLSForm);