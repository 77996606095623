import PropTypes from "prop-types";
import React from 'react';
import { connect } from 'react-redux';
import {Translate} from "react-localize-redux";
import {Link} from "react-router-dom";
import {Nav, NavItem, NavLink} from "reactstrap";
import {Redirect, Route, Switch, withRouter} from "react-router";

import Form from "./form";
import FormNdi from "./form-ndi"
import AdvancedForm from "./advanced-form";

import {licensePropTypes} from "../../../../utils/models-prop-types";
import {updateNetworkSettings, updateNdiSettings, updateNetworkAdvancedSettings} from "./network.settings.actions";

const propTypes = {
  config: PropTypes.object,
  license: licensePropTypes,
  callUpdateNetworkSettings: PropTypes.func.isRequired,
  configNdi: PropTypes.object,
  callUpdateNdiSettings: PropTypes.func.isRequired,
  callUpdateNetworkAdvancedSettings: PropTypes.func.isRequired
};

const NetworkSettings = (props) => {
  const { location, callUpdateNetworkSettings, config, license, configNdi, callUpdateNdiSettings, callUpdateNetworkAdvancedSettings } = props;
  const hasPath = (path) => {
    return location.pathname.includes(path);
  };

  return (
    <div className="settings-section">
      <div className="header">
        <div className="title">
          <Translate id="genericLabel.NETWORK.text"/>
        </div>
      </div>
      <Nav tabs>
        <NavItem>
          <NavLink id="network_product_navLink"
                   active={hasPath('/product')}
                   className="aw-nav-link"
                   tag={Link}
                   to="/settings/network/product">
            <Translate id="genericLabel.PRODUCT.text"/>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink id="network_ndi_navLink"
                   active={hasPath('/ndi')}
                   className="aw-nav-link"
                   tag={Link}
                   to="/settings/network/ndi">
            <Translate id="genericLabel.NDI.text"/>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink id="network_advanced_navLink"
                   active={ hasPath('/advanced') }
                   className="aw-nav-link"
                   tag={Link}
                   to="/settings/network/advanced">
            <Translate id="genericLabel.ADVANCED.text"/>
          </NavLink>
        </NavItem>
      </Nav>
      <div className="content with-menu pretty-scroll">
        <Switch>
          <Route exact
                 path="/settings/network/product"
                 component={(props) => {
                   if(!config || !license){
                     return null;
                   }
                   return (
                      <Form { ...props }
                        license={license}
                        config={config}
                        onSubmit={callUpdateNetworkSettings}/>
                   )
                 }}/>
          <Route exact
                 path="/settings/network/ndi"
                 component={(props) => {
                   if(!config || !license || !configNdi){
                     return null;
                   }
                   return (
                      <FormNdi { ...props }
                        config={configNdi}
                        onSubmit={callUpdateNdiSettings}/>
                   )
                 }}/>
          <Route exact
                 path="/settings/network/advanced"
                 component={(props) => {
                   return <AdvancedForm { ...props }
                                        config={config}
                                        onSubmit={callUpdateNetworkAdvancedSettings}/>
                 }}/>
          <Redirect from="/settings/network*" to="/settings/network/product"/>
        </Switch>
      </div>
    </div>
  )
};

NetworkSettings.propTypes = propTypes;



const mapStateToProps = (state) => {

  const { details : license } = state.license;

  return {
    license,
    config: Object.keys(state.config).length > 0 ? state.config : null, // Remember, config is an empty object at first
    configNdi: state.config && state.config.ndi ? state.config.ndi : null
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    callUpdateNetworkSettings: (data) => dispatch(updateNetworkSettings(data)),
    callUpdateNdiSettings: (data) => dispatch(updateNdiSettings(data)),
    callUpdateNetworkAdvancedSettings: (data) => dispatch(updateNetworkAdvancedSettings(data))
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NetworkSettings));