import PropTypes from "prop-types";
import React, {Fragment, useState } from 'react';
import {Translate} from "react-localize-redux";
import {Label, Input, FormGroup, Button, Alert,FormFeedback} from "reactstrap";
import {Form, Formik} from "formik";
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";

import {videoCardCapabilitiesPropTypes} from "../../../../utils/models-prop-types";
import {VIDEO_CARD_TYPE_DELTACAST, VIDEO_CARD_TYPE_RIVERMAX } from "../../../../constants";
import RestartStreamhubModal from "../../tools/system/restart-streamhub-modal";
import { isEmptyString } from "../../../../utils/string-utils";
import { connect } from "react-redux";
import { licenseModal } from "../../tools/system/system.tools.actions";
import LicenseModal from "./license-form";
import { uploadLicenseSMPTE2110 } from "../../../../misc/license.actions";

const propTypes = {
  config: PropTypes.shape({
    '4KMode': PropTypes.bool,
    forceFullFrameRate: false,
    nbSDIInput: PropTypes.number,
    sdiOrder: PropTypes.number,
    videoFrameRate: PropTypes.string,
    enableDHCP1: PropTypes.bool,
    enableDHCP2: PropTypes.bool,
  }).isRequired,
  videoFrameRates: PropTypes.arrayOf(PropTypes.string),
  videoCard: videoCardCapabilitiesPropTypes.isRequired,
  onSubmit: PropTypes.func.isRequired,
  smpteIps: PropTypes.arrayOf(PropTypes.string),
  callLicenseModal: PropTypes.func.isRequired,
  licenseModalOpened: PropTypes.bool.isRequired,
  uploading: PropTypes.bool,
  result: PropTypes.shape({
    res: PropTypes.number.isRequired,
    message: PropTypes.string,
  }),
  callUploadLicense: PropTypes.func.isRequired, // temp
  licenseStatus: PropTypes.bool.isRequired,
};

const VideoCardSettingsForm = (props) => {
  const [confirmModalOpened, setConfirmModalOpened] = useState(false);
  const { config, videoFrameRates, videoCard, smpteIps,licenseModalOpened, uploading, result, callUploadLicense, licenseStatus} = props;

  const handleSubmit = (values, { resetForm }) => {
    if(values.enableDHCP1){
      values.ipaddr1=smpteIps[0];
      values.mask1='';
    }
    if(values.enableDHCP2){
      values.ipaddr2=smpteIps[1];
      values.mask2='';
    }
    values.ptpLogAnnounceInterval = parseInt(values.ptpLogAnnounceInterval, 10);
    values.ptpLogSyncInterval = parseInt(values.ptpLogSyncInterval, 10);
    //clean before sending, need to fragment the forms
    if(videoCard.sdiCardType !== VIDEO_CARD_TYPE_RIVERMAX){
      const {ptpEnable, ptpDomainNumber, ptpPriority1, ptpPriority2, ptpLogAnnounceInterval, ptpAnnounceReceiptTimeout, ptpLogSyncInterval, ptpDelay_mechanism, enableDHCP1, enableDHCP2, ipaddr1, ipaddr2, mask1, mask2, ...rest}=values;
      values=rest
    }

    props.onSubmit(videoCard.id, values);
    //console.log(values)
    resetForm(values);
    setConfirmModalOpened(false) //temp
  };

  const handleValidation = (values) => {
    const errors = {};
    const ipRegex = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))$/;
    //mask Regex
    var maskRegex = /^(((255\.){3}(255|254|252|248|240|224|192|128|0+))|((255\.){2}(255|254|252|248|240|224|192|128|0+)\.0)|((255\.)(255|254|252|248|240|224|192|128|0+)(\.0+){2})|((255|254|252|248|240|224|192|128|0+)(\.0+){3}))$/;

    if(this.props.videoCard.sdiCardType===VIDEO_CARD_TYPE_RIVERMAX){
      if(!values.enableDHCP1){
        // ipaddr1
        if(isEmptyString(values.ipaddr1)) {
          errors.ipaddr1 = 'genericLabel.REQUIRED_FIELD.text';
        } else if (!ipRegex.test(values.ipaddr1)) {
          errors.ipaddr1 = 'genericLabel.INVALID_FORMAT.text';
        }
        // mask1
        if(isEmptyString(values.mask1)) {
          errors.mask1 = 'genericLabel.REQUIRED_FIELD.text';
        }
        else if (!maskRegex.test(values.mask1)) {
          errors.mask1 = 'genericLabel.INVALID_FORMAT.text';
        }
      }
      if(!values.enableDHCP2){
        // ipaddr2
        if(isEmptyString(values.ipaddr2)) {
          errors.ipaddr2 = 'genericLabel.REQUIRED_FIELD.text';
        } else if (!ipRegex.test(values.ipaddr2)) {
          errors.ipaddr2 = 'genericLabel.INVALID_FORMAT.text';
        }
        // mask2
        if(isEmptyString(values.mask2)) {
          errors.mask2 = 'genericLabel.REQUIRED_FIELD.text';
        }
        else if (!maskRegex.test(values.mask2)) {
          errors.mask2 = 'genericLabel.INVALID_FORMAT.text';
        }
      }
    }
    return errors;
  };

  let sdiOrderArray = null;
  if(videoCard.sdiOrder != null){
    sdiOrderArray = videoCard.sdiOrder.map(order => {
      return order.name.split(',').map(value => parseInt(value));
    });
  }

  return (<div>
    {videoCard.sdiCardType === VIDEO_CARD_TYPE_RIVERMAX && !licenseStatus &&
          <Alert color="danger">
            Invalid or missing SMPTE2110 License, Please add one to continue
          </Alert>
          }
    {videoCard.sdiCardType === VIDEO_CARD_TYPE_RIVERMAX &&
    <Button id={"videoCard_"+videoCard.modelName+"_uploadLicense"}
            type="submit"//disabled={hasUserLevel || hasViewerLevel}
            color={!licenseStatus ? "primary" : "secondary"}
            onClick={() => props.callLicenseModal(true)}>
                <AWIcon name="license"/>
                <span className="text">Upload SMPTE 2110 License</span>
              </Button>}
              { licenseModalOpened &&
              <LicenseModal onCancel={() => props.callLicenseModal(false)}
                            loading={uploading}
                            result={result}
                            onSubmit={callUploadLicense}/>
              }
    <Formik initialValues={{
              '4KMode': config['4KMode'],
              sdiOrder: config.sdiOrder,
              videoFrameRate: config.videoFrameRate,
              forceFullFrameRate: config.forceFullFrameRate,
              enableDHCP1: config.enableDHCP1 ? config.enableDHCP1 : false,
              enableDHCP2: config.enableDHCP2 ? config.enableDHCP2 : false,
              ipaddr1: config.enableDHCP1 ? smpteIps[0] : (config.ipaddr1 ? config.ipaddr1 : '' ),
              ipaddr2: config.enableDHCP2 ? smpteIps[1] : (config.ipaddr2 ? config.ipaddr2 : '' ),
              mask1: config.mask1 ? config.mask1 : '',
              mask2: config.mask2 ? config.mask2 : '',
              ptpEnable: config.ptpEnable!==undefined ? config.ptpEnable : true,
              ptpDomainNumber: config.ptpDomainNumber!==undefined ? config.ptpDomainNumber : 127,
              ptpPriority1: config.ptpPriority1 ? config.ptpPriority1 : 128,
              ptpPriority2: config.ptpPriority2 ? config.ptpPriority2 : 127,
              ptpLogAnnounceInterval: config.ptpLogAnnounceInterval ? config.ptpLogAnnounceInterval : -2,
              ptpAnnounceReceiptTimeout: config.ptpAnnounceReceiptTimeout ? config.ptpAnnounceReceiptTimeout : 3,
              ptpLogSyncInterval: config.ptpLogSyncInterval!==undefined ? config.ptpLogSyncInterval : -3,
              ptpDelay_mechanism: config.ptpDelay_mechanism!==undefined ? config.ptpDelay_mechanism : "E2E",
            }}
            validateOnBlur={false}
            validateOnChange={true}
            validate= { handleValidation }
            onSubmit={ handleSubmit }>
      {({
          values,
          errors,
          dirty,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue
          /* and other goodies */
        }) => (
        <Form onSubmit={handleSubmit}>
          { dirty &&
          <Alert color="warning">
            <Translate id="genericLabel.RESTART_STREAMHUB_TO_TAKE_CHANGES_INTO_ACCOUNT.text"/>
          </Alert>
          }
          { videoCard.has4K &&
          <FormGroup check>
            <Label check>
              <Input id={"videoCard_"+videoCard.modelName+"_enable4K"}
                     type="checkbox"
                     name="4KMode"
                     onChange={handleChange}
                     checked={values['4KMode']}/>{' '}
              <Translate id="genericLabel.4K_MODE.text"/>
            </Label>
          </FormGroup>
          }
          {videoCard.sdiCardType === VIDEO_CARD_TYPE_RIVERMAX && <div>
            <div className="header">
                  <div className="title secondary">
                    Interface 1
                  </div>
                </div>
          <FormGroup check>
          <Label check>
            <Input id={"videoCard_"+videoCard.modelName+"_enableDHCP1"}
                   type="checkbox"
                   name="enableDHCP1"
                   onChange={handleChange}
                   disabled={!licenseStatus}
                   checked={values.enableDHCP1}/>{' '}
                   Enable DHCP
            {/*<Translate id="genericLabel.DISPLAY_OUTPUT_NAME.text"/> fix me  */}
          </Label>
        </FormGroup></div>}
        {props.videoCard.sdiCardType===VIDEO_CARD_TYPE_RIVERMAX &&
                <FormGroup>
                <Label for="ipaddr1">
                  Ip adress
                </Label>
                <Input type="string"
                       name="ipaddr1"
                       id={"videoCard_"+videoCard.modelName+"_ipAddr1"}
                       disabled={values.enableDHCP1 || !licenseStatus}
                       invalid={ errors.ipaddr1 !== undefined }
                       value={values.ipaddr1}
                       onBlur={handleBlur}
                       onChange={handleChange}/>
                <FormFeedback>
                  <Translate id={errors.ipaddr1}/>
                </FormFeedback>
              </FormGroup>}
              {props.videoCard.sdiCardType===VIDEO_CARD_TYPE_RIVERMAX && values.enableDHCP1===false &&
              <FormGroup>
                <Label for="mask1">
                  Mask
                </Label>
                <Input type="string"
                       name="mask1"
                       id={"videoCard_"+videoCard.modelName+"_mask1"}
                       invalid={ errors.mask1 !== undefined }
                       value={values.mask1}
                       onBlur={handleBlur}
                       onChange={handleChange}
                       disabled={!licenseStatus}/>
                <FormFeedback>
                  <Translate id={errors.mask1}/>
                </FormFeedback>
              </FormGroup>}
        {videoCard.sdiCardType === VIDEO_CARD_TYPE_RIVERMAX &&<div>
          <div className="header">
                  <div className="title secondary">
                    Interface 2
                  </div>
                </div>
          <FormGroup check>
          <Label check>
            <Input id={"videoCard_"+videoCard.modelName+"_enableDHCP2"}
                   type="checkbox"
                   name="enableDHCP2"
                   onChange={handleChange}
                   checked={values.enableDHCP2}
                   disabled={!licenseStatus}/>{' '}
                   Enable DHCP
            {/*<Translate id="genericLabel.DISPLAY_OUTPUT_NAME.text"/> fix me  */}
          </Label>
        </FormGroup></div>}
        {props.videoCard.sdiCardType===VIDEO_CARD_TYPE_RIVERMAX &&
                <FormGroup>
                <Label for="ipaddr2">
                  Ip adress
                </Label>
                <Input type="string"
                       name="ipaddr2"
                       id={"videoCard_"+videoCard.modelName+"_ipAddr2"}
                       disabled={values.enableDHCP2 || !licenseStatus}
                       invalid={ errors.ipaddr2 !== undefined }
                       value={values.ipaddr2}
                       onBlur={handleBlur}
                       onChange={handleChange}/>
                <FormFeedback>
                  <Translate id={errors.ipaddr2}/>
                </FormFeedback>
              </FormGroup>
          }
        {props.videoCard.sdiCardType===VIDEO_CARD_TYPE_RIVERMAX && values.enableDHCP2===false && 
              <FormGroup>
                <Label for="mask2">
                  Mask
                </Label>
                <Input type="string"
                       name="mask2"
                       id={"videoCard_"+videoCard.modelName+"_mask2"}
                       invalid={ errors.mask2 !== undefined }
                       value={values.mask2}
                       onBlur={handleBlur}
                       onChange={handleChange}
                       disabled={!licenseStatus}/>
                <FormFeedback>
                  <Translate id={errors.mask1}/>
                </FormFeedback>
              </FormGroup>}
          {props.videoCard.sdiCardType===VIDEO_CARD_TYPE_RIVERMAX &&
            <>
              
              <div className="header">
                  <div className="title secondary">
                    PTP
                  </div>
                </div>
                 { ((config.ptpEnableble!==undefined && config.ptpEnable !==values.ptpEnable) || (config.ptpEnable===undefined && !values.ptpEnable )) &&
                    <Alert color="warning">
                      Reboot necessary for the change to take effect
                    </Alert>
                  }
                <FormGroup check>
                  <Label check>
                    <Input id={"videoCard_"+videoCard.modelName+"_ptpEnable"}
                          type="checkbox"
                          name="ptpEnable"
                          onChange={handleChange}
                          checked={values.ptpEnable}
                          disabled={!licenseStatus}/>{' '}
                          Enable PTP
                    </Label>
                </FormGroup>
                <FormGroup>
                  <Label for="ptpDomainNumber">
                     Domain Number
                  </Label>
                  <Input type="number"
                        name="ptpDomainNumber"
                        id={"videoCard_"+videoCard.modelName+"_ptpDomainNumber"}
                        min={0}
                        max={127}
                        default={0}
                        value={values.ptpDomainNumber}
                        disabled={!values.ptpEnable}
                        onChange={handleChange}/>
                </FormGroup>
                <FormGroup>
                  <Label for="ptpPriority1">
                    Priority 1
                  </Label>
                  <Input type="number"
                        name="ptpPriority1"
                        id={"videoCard_"+videoCard.modelName+"_ptpPriority1"}
                        min={0}
                        max={255}
                        value={values.ptpPriority1}
                        disabled={!values.ptpEnable}
                        onChange={handleChange}/>
                </FormGroup>
                <FormGroup>
                  <Label for="ptpPriority2">
                  Priority 2
                  </Label>
                  <Input type="number"
                        name="ptpPriority2"
                        id={"videoCard_"+videoCard.modelName+"_ptpPriority2"}
                        min={0}
                        max={255}
                        value={values.ptpPriority2}
                        disabled={!values.ptpEnable}
                        onChange={handleChange}/>
                </FormGroup>
                <FormGroup>
                  <Label for="ptpLogAnnounceInterval">
                    Announce Interval
                  </Label>
                  <Input type="select"
                        name="ptpLogAnnounceInterval"
                        id={"videoCard_"+videoCard.modelName+"_ptpLogAnnounceInterval"}
                        value={values.ptpLogAnnounceInterval}
                        disabled={!values.ptpEnable}
                        onChange={handleChange}>
                    <option value={-3}>0.125s 8Hz</option>
                    <option value={-2}>0.25s 4Hz</option>
                    <option value={-1}>0.5s 2Hz</option>
                    <option value={0}>1s 1Hz</option>
                    <option value={1}>2s 0.5Hz</option>
                  </Input>
              </FormGroup>
                <FormGroup>
                  <Label for="ptpAnnounceReceiptTimeout">
                    Announce receipt timeout
                  </Label>
                  <Input type="number"
                        name="ptpAnnounceReceiptTimeout"
                        id={"videoCard_"+videoCard.modelName+"_ptpAnnounceReceiptTimeout"}
                        min={2}
                        max={10}
                        value={values.ptpAnnounceReceiptTimeout}
                        disabled={!values.ptpEnable}
                        onChange={handleChange}/>
                </FormGroup>
                <FormGroup>
                  <Label for="ptpLogSyncInterval">
                  Log sync interval
                  </Label>
                  <Input type="select"
                        name="ptpLogSyncInterval"
                        id={"videoCard_"+videoCard.modelName+"_ptpLogSyncInterval"}
                        value={values.ptpLogSyncInterval}
                        disabled={!values.ptpEnable}
                        onChange={handleChange}>
                    <option value={0}>1s 1Hz</option>
                    <option value={-1}>0.5s 2Hz</option>
                    <option value={-2}>0.25s 4Hz</option>
                    <option value={-3}>0.125s 8Hz</option>
                    <option value={-4}>0.0625s 16Hz</option>
                    <option value={-5}>0.03125s 32Hz</option>
                    <option value={-6}>0.015625s 64Hz</option>
                    <option value={-7}>0.0078125s 128Hz</option>
                  </Input>
              </FormGroup>
              <FormGroup>
                  <Label for="ptpDelay_mechanism">
                  delay mechanism
                  </Label>
                  <Input type="select"
                        name="ptpDelay_mechanism"
                        id={"videoCard_"+videoCard.modelName+"_ptpDelay_mechanism"}
                        value={values.ptpDelay_mechanism}
                        disabled={!values.ptpEnable}
                        onChange={handleChange}>
                    <option value={"E2E"}>E2E</option>
                    <option value={"P2P"}>P2P</option>
                  </Input>
              </FormGroup>
            </>  
          }

          { sdiOrderArray &&
          <FormGroup>
            <Label for="sdiOrder">
              <Translate id="genericLabel.SDI_ORDER.text"/>
            </Label>
            <div>
              { sdiOrderArray.map((order, index) => (
                <div key={index}
                     className="sdi-order">
                  <Button id={"videoCard_"+videoCard.modelName+"_sdiOrder"}
                          className="basic"
                          onClick={() => setFieldValue('sdiOrder', index)}>
                    <div className={`sdi-icon ref ${index === values.sdiOrder ? 'active' : ''}`}>
                      <AWIcon name="sdi"/>
                      <div className="sdi-name"><Translate id="genericLabel.SDI_REF.text"/></div>
                    </div>
                    { order.map(orderIndex => (
                      <div key={orderIndex}
                           className={`sdi-icon ${index === values.sdiOrder ? 'active' : ''}`}>
                        <AWIcon name="sdi"/>
                        <div className="sdi-name">{ orderIndex }</div>
                      </div>
                    ))}
                  </Button>
                </div>
              ))}
            </div>
          </FormGroup>
          }
          { videoCard.sdiCardType === VIDEO_CARD_TYPE_DELTACAST &&
          <Fragment>
            <FormGroup>
              <Label for="videoFrameRate">
                <Translate id="genericLabel.VIDEO_FRAME_RATE.text"/>
              </Label>
              <Input type="select"
                     name="videoFrameRate"
                     id={"videoCard_"+videoCard.modelName+"_videoFrameRate"}
                     value={values.videoFrameRate}
                     onBlur={handleBlur}
                     onChange={handleChange}>
                { videoFrameRates.map(frameRate => (
                  <option key={frameRate} value={frameRate}>{frameRate}</option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input id={"videoCard_"+videoCard.modelName+"_forceFullFrameRate"}
                       type="checkbox"
                       name="forceFullFrameRate"
                       onChange={handleChange}
                       checked={values.forceFullFrameRate}/>{' '}
                <Translate id="genericLabel.FORCE_FULL_FRAME_RATE.text"/>
              </Label>
            </FormGroup>
          </Fragment>
          }
         <FormGroup className="buttons">
            <Button id={"videoCard_"+videoCard.modelName+"_saveButton"}
                    color="primary"
                    disabled={isSubmitting || !dirty}
                    onClick={() => setConfirmModalOpened(true)}>
              <Translate id="genericLabel.SAVE.text"/>
            </Button>
          </FormGroup>
          { confirmModalOpened &&
          <RestartStreamhubModal onCancel={() => setConfirmModalOpened(false)}
                                  onConfirm={() => handleSubmit()}/>
          }
        </Form>
      )}
    </Formik></div>
  );
};

VideoCardSettingsForm.propTypes = propTypes;

const mapStateToProps = (state) => {
  const {
    licenseModalOpened
  } = state.tools.system;
  return {
    licenseModalOpened,
    uploading: state.license.uploading,
    result: state.license.result
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    callLicenseModal: (open) => dispatch(licenseModal(open)),
    callUploadLicense: (file) => dispatch(uploadLicenseSMPTE2110(file)) // temp
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoCardSettingsForm);
