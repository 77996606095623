import PropTypes from "prop-types";
import React, {Component} from "react";
import {inputPropTypes, intercomInfoPropTypes} from "../../../../../utils/models-prop-types";
import {
  inputIntercomAudioPattern,
  intercomMute,
} from "../../dashboard.actions";
import {connect} from "react-redux";
import {intercomOpen} from "../../../../../misc/socket.actions";
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";
import AWVuMeter from "@aviwest/ui-kit/dist/js/components/vu-meter";
import { Translate, withLocalize } from "react-localize-redux";

const MUTE_INTERCOM_INPUT = 'muteChannelInput';
const MUTE_INTERCOM_OUTPUT = 'muteChannelOutput';

const min = -50;
const max = 0;

const propTypes = {
  input: inputPropTypes.isRequired,
  intercomInfo: intercomInfoPropTypes,
  callAudioPatternChange: PropTypes.func.isRequired,
  callOpenIntercom: PropTypes.func.isRequired,
  callMute: PropTypes.func.isRequired
};

class IntercomTest extends Component {

  constructor(props){
    super(props);

    this.handleInputMute = this.handleInputMute.bind(this);
    this.handleOutputMute = this.handleOutputMute.bind(this);
    this.handleAudioPatternSwitch = this.handleAudioPatternSwitch.bind(this);
  }

  componentDidMount(){
    this.props.callOpenIntercom(true);
  }

  componentWillUnmount(){
    this.props.callOpenIntercom(false);
  }

  // We use class methods because of lots of re-rendering (performance issue)

  handleInputMute(){
    if(this.props.intercomInfo && this.props.intercomInfo.mute_input_status){
      this.props.callMute(MUTE_INTERCOM_INPUT, false);
    }
    else {
      this.props.callMute(MUTE_INTERCOM_INPUT, true);
    }
  }

  handleOutputMute(){
    if(this.props.intercomInfo && this.props.intercomInfo.mute_output_status){
      this.props.callMute(MUTE_INTERCOM_OUTPUT, false);
    }
    else {
      this.props.callMute(MUTE_INTERCOM_OUTPUT, true);
    }
  }

  handleAudioPatternSwitch(){
    if(this.props.intercomInfo && this.props.intercomInfo.pattern > 0){
      this.props.callAudioPatternChange(false);
    }
    else {
      this.props.callAudioPatternChange(true);
    }
  }

  render(){
    const {
      intercomInfo,
      translate
    } = this.props;
    return (
      <>
        <div className="audio-levels intercom">
          { intercomInfo &&
            <>
            <div className="audio-header">
              <Translate id="genericLabel.INTERCOM.text" />
            </div>
            <AWVuMeter peakValue={intercomInfo.output ? intercomInfo.output : 0}
              decayValue={intercomInfo.output ? intercomInfo.output : 0}/>
            <AWVuMeter peakValue={intercomInfo.input ? intercomInfo.input : 0 }
              decayValue={intercomInfo.input ? intercomInfo.input : 0}/>

              <div className="audio-scale">
                <div className="scale">
                  <div className="scale-min"><span>{max} dB</span></div>
                  <div className="scale-danger"><span>-6 dB</span></div>
                  <div className="scale-warning"><span>-18 dB</span></div>
                  <div className="scale-max"><span>{min} dB</span></div>
                </div>
              </div>
              <div className="audio-controls">
                <AWIcon onClick={ this.handleOutputMute } name={ intercomInfo.mute_output_status ? 'volume_mute' : 'volume_on'}/>
                <AWIcon onClick={ this.handleInputMute } name={ intercomInfo.mute_input_status ? 'microphone_off' : 'microphone'}/>
                <AWIcon color={intercomInfo.pattern > 0 ? 'primary' : undefined}
                        title={translate("genericLabel.INTERCOM_TEST.text")}
                        onClick={this.handleAudioPatternSwitch} name="music"/>
              </div>
            </>
          }
        </div>


      </>
    );
  }

}

IntercomTest.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
  return {
    intercomInfo: state.datastore.inputIntercomInfo[ownProps.input.id],
    profile: state.streamhub.intercomProfile
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    callAudioPatternChange: (active) => dispatch(inputIntercomAudioPattern(ownProps.input.id, active)),
    callOpenIntercom: (open) => dispatch(intercomOpen(open)),
    callMute: (channelType, mute) => dispatch(intercomMute(ownProps.input.id, channelType, mute))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(IntercomTest));