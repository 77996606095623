import PropTypes from "prop-types";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Translate} from "react-localize-redux";

import Form from "./form";
import {updateIntercomSettings} from "./intercom.settings.actions";

const propTypes = {
  config: PropTypes.object,
  inputAudioDevices: PropTypes.array,
  outputAudioDevices: PropTypes.array,
  forbiddenUDPPorts: PropTypes.arrayOf(PropTypes.number).isRequired,
};

class IntercomSettings extends Component {

  render() {
    const {
      config,
      inputAudioDevices,
      outputAudioDevices,
      forbiddenUDPPorts,
    } = this.props;
    return (
        <div className="settings-section">
          <div className="header">
            <div className="title">
            <Translate id="genericLabel.INTERCOM.text"/>
            </div>
          </div>
          <div className="content pretty-scroll">
            { config &&
            <Form config={config}
                  forbiddenUDPPorts={forbiddenUDPPorts}
                  inputAudioDevices={inputAudioDevices}
                  outputAudioDevices={outputAudioDevices}
                  onSubmit={this.props.updateIntercomSettings}/>
            }
          </div>
        </div>
    );
  }
}

IntercomSettings.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
  const intercom = state.config ? state.config.intercom : null;
  const { inputAudioDevices, outputAudioDevices } = state.streamhub;
  const forbiddenUDPPorts = state.streamhub.udpUsedPort.atpGeneralBasePorts.concat(
    state.streamhub.udpUsedPort.LiveGuestPorts,
    state.streamhub.udpUsedPort.inputProtocolPorts,
    state.streamhub.udpUsedPort.channelPorts);
  return {
    config: intercom,
    forbiddenUDPPorts,
    inputAudioDevices,
    outputAudioDevices
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateIntercomSettings: (data) => dispatch(updateIntercomSettings(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IntercomSettings);