import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import {ClipLoader} from "react-spinners";
import {XAxis, YAxis, LineSeries, XYPlot, VerticalBarSeries, HorizontalGridLines, VerticalGridLines} from 'react-vis';
import {Button, Badge, Table} from "reactstrap";
import { connect } from 'react-redux';
import DayJS from 'dayjs';
import AutoSizer from "react-virtualized-auto-sizer";
import '../../../../../../node_modules/react-vis/dist/style.css';
import {GRAPH_COLORS, GRAPH_GRID_COLOR_DARK, GRAPH_LEGEND_COLOR, GRAPH_MIN_WIDTH, SPINNER_DASHBOARD_COLOR} from "../../../../../constants";
import {formatBitrate, formatRTT, formatLost} from "../../../../../utils/global-utils";
import {Translate} from "react-localize-redux";
import {inputPropTypes} from "../../../../../utils/models-prop-types";
import AWIcon from '@aviwest/ui-kit/dist/js/components/icon';

import {
  reconnectModem,
  disconnectModem
} from "../../dashboard.actions";
import CircularBadge from '../../../../common/circular-badge';

const propTypes = {
  input: inputPropTypes.isRequired,
  stats: PropTypes.shape({
    currentNetworkInterfaces: PropTypes.arrayOf(PropTypes.string),
    maxAudioLoss: PropTypes.number.isRequired,
    maxTotalBitrate: PropTypes.number,
    maxTotalRxBitrate: PropTypes.number,
    maxTotalTxBitrate: PropTypes.number,
    maxVideoLoss: PropTypes.number.isRequired,
    data: PropTypes.arrayOf(PropTypes.shape({
      timestamp: PropTypes.number.isRequired,
      totalBitrate: PropTypes.number.isRequired,
      totalRxBitrate: PropTypes.number,
      totalTxBitrate: PropTypes.number,
      videoBitrate: PropTypes.number.isRequired,
      videoLostPackets: PropTypes.number.isRequired,
      audioBitrate: PropTypes.number.isRequired,
      audioLostPackets: PropTypes.number.isRequired,
    })).isRequired
  }),
  callReconnetModem: PropTypes.func.isRequired,
  callDisconnectModem: PropTypes.func.isRequired,
  hasViewerLevel: PropTypes.bool,
};

const margins= {
  left: 50,
  right: 20
};

const tickTotalX = 4;
const tickTotalY = 4;
const tickWidth = 0;

class NetworkInfo extends Component {

  constructor(props){
    super(props);
    this.toggleAllInterface = this.toggleAllInterface.bind(this);
    this.toggleInterface = this.toggleInterface.bind(this);
    this.toggleGraph = this.toggleGraph.bind(this);
    this.getBackgroundColor = this.getBackgroundColor.bind(this);

    this.state = {
      allInterface: true,
      graphRtt: true,
      graphRx: true,
      graphTx: true
    };
  }

  getBackgroundColor(index) {
    return index < GRAPH_COLORS.length ? GRAPH_COLORS[index + 1]: GRAPH_COLORS[0];
  }

  toggleAllInterface() {
    this.setState({
      allInterface: !this.state.allInterface
    });
  }

  toggleInterface(element) {
    this.setState({
      [element]: !this.state[element]
    });
  }

  toggleGraph(graph) {
    this.setState({
      [graph]: !this.state[graph]
    });
  }

  tickFormatBitrate(value, index, scale, tickTotal){
    return `${scale.tickFormat(tickTotal, '~s')(value)}b`;
  }

  render(){
    if(!this.props.stats){
      return null;
    }
    const lastStat = this.props.stats.data.length > 0 ? this.props.stats.data[this.props.stats.data.length - 1] : null;
    const hasViewerLevel = this.props.hasViewerLevel;
    return (
      <div className="network-info">
        <div className="title">
          <label className="text-secondary"><Translate id="genericLabel.NETWORK_INFORMATION.text"/></label>
        </div>
        <div className="content rounded">
          <div className="controls mb-1">
          <div className="flex-left">
              { (this.state.graphRtt || this.state.graphRx || this.state.graphTx) &&
              <>
                { this.props.stats.currentNetworkInterfaces.map((networkInterface, index) => (
                  <span key={ networkInterface } className="interface-toggle" onClick={() => this.toggleInterface(networkInterface)}>
                    <CircularBadge style={this.state[networkInterface] ? {} : {backgroundColor: this.getBackgroundColor(index, this.state[networkInterface])}} />
                    <span className="text-secondary font-weight-bold ml-1">{networkInterface}</span>
                  </span>
                ))}
                <span className="interface-toggle" onClick={() => this.toggleAllInterface()}>
                  <CircularBadge style={!this.state.allInterface ? {} : {backgroundColor: GRAPH_COLORS[0]}} />
                  <span className="text-secondary font-weight-bold ml-1"><Translate id="streamStats.TOTAL.text"/></span>
                </span>
              </>
              }
            </div>
            <div className="flex-right">
              <Button
                className="basic"
                active={this.state.graphRtt}
                color="primary"
                onClick={() => this.toggleGraph('graphRtt')}>
                  <AWIcon name="chart_line" style={{marginRight: '0.2rem'}}/>
                  RTT
              </Button>
              <Button
                className="basic"
                active={this.state.graphRx}
                color="primary"
                onClick={() => this.toggleGraph('graphRx')}>
                  <AWIcon name="chart_line" style={{marginRight: '0.2rem'}}/>
                  Rx
              </Button>
              <Button
                className="basic"
                active={this.state.graphTx}
                color="primary"
                onClick={() => this.toggleGraph('graphTx')}>
                  <AWIcon name="chart_line" style={{marginRight: '0.2rem'}}/>
                  Tx
              </Button>
            </div>
          </div>
          { this.state.graphRtt &&
          <div className="subtitle">
            <Translate id="genericLabel.RTT.text"/>
          </div>
          }
          { this.state.graphRtt &&
          <AutoSizer disableHeight>
            {({ width }) => {
              return width > GRAPH_MIN_WIDTH ?
                  <div className="graph">
                    <div className="base">
                      <XYPlot
                          getX={d => d.timestamp }
                          getY={d => d.totalRtt }
                          xType="time"
                          yDomain={[0, this.props.stats.maxTotalRtt + (parseInt(this.props.stats.maxTotalRtt * 0.2))]}
                          height={125}
                          width={width}
                          margin={margins}>
                        <HorizontalGridLines style={{ stroke: GRAPH_GRID_COLOR_DARK }} tickTotal={tickTotalY}/>
                        <VerticalGridLines style={{ stroke: GRAPH_GRID_COLOR_DARK }} tickTotal={tickTotalX}/>
                        <XAxis style={{ line: {stroke: GRAPH_GRID_COLOR_DARK}, text: {stroke: GRAPH_LEGEND_COLOR}}} tickTotal={tickTotalX} tickFormat={v => `${DayJS(v).format('HH:mm')}`} tickSizeOuter={0} tickSizeInner={tickWidth}/>
                        <YAxis style={{ line: {stroke: GRAPH_GRID_COLOR_DARK}, text: {stroke: GRAPH_LEGEND_COLOR}}} tickTotal={tickTotalY} tickFormat={ formatRTT } tickSizeOuter={0} tickSizeInner={tickWidth}/>
                        <LineSeries getNull={(d) => (false)} data={this.props.stats.data} color={GRAPH_COLORS[0]}/>
                      </XYPlot>
                    </div>
                    { this.props.stats.currentNetworkInterfaces.map((networkInterface, index) => {
                      if (!this.state[networkInterface]) {
                        return (
                        <div key={ networkInterface }>
                        <div className="overlay">
                          <XYPlot
                              getX={d => d.timestamp }
                              getY={d => d[networkInterface] ? d[networkInterface].rtt : 0 }
                              xType="time"
                              yDomain={[0, this.props.stats.maxTotalRtt + (parseInt(this.props.stats.maxTotalRtt * 0.2))]}
                              height={125}
                              width={width}
                              margin={margins}>
                            <LineSeries getNull={(d) => d.hidden !== true} data={this.props.stats.data} color={this.getBackgroundColor(index)}/>
                          </XYPlot>
                        </div>
                        <div className="overlay">
                          <XYPlot
                              getX={d => d.timestamp }
                              getY={d => (d[networkInterface] && d[networkInterface].lostPackets >= 0) ? d[networkInterface].lostPackets : 0 }
                              yDomain={[0, this.props.stats.maxTotalLostPackets + (parseInt(this.props.stats.maxTotalLostPackets * 0.2))]}
                              height={125}
                              width={width}
                              margin={margins}>
                            <YAxis style={{ line: {stroke: GRAPH_GRID_COLOR_DARK}, text: {stroke: GRAPH_LEGEND_COLOR}}} orientation="right" tickFormat={ formatLost } tickPadding={2} tickSizeOuter={0} tickSizeInner={tickWidth}/>
                            <VerticalBarSeries data={this.props.stats.data} color={this.getBackgroundColor(index)}/>
                          </XYPlot>
                        </div>
                        </div>
                    )}
                    else return null})}
                  </div> : null;
            }}
          </AutoSizer>
          }
          { this.state.graphRx &&
          <div className="subtitle">
            <Translate id="genericLabel.RX_BITRATE.text"/>
          </div>
          }
          { this.state.graphRx &&
          <AutoSizer disableHeight>
            {({ width }) => {
              // Y Domain depending on visible interface
              let maxRxBitrate = 0;
              for (const networkInterface in  this.props.stats.maxRxBitrate) {
                if (!this.state[networkInterface] && this.props.stats.maxRxBitrate[networkInterface] > maxRxBitrate) {
                  maxRxBitrate = this.props.stats.maxRxBitrate[networkInterface]
                }
              }
              if (this.state.allInterface || maxRxBitrate === 0) {
                maxRxBitrate = this.props.stats.maxTotalRxBitrate
              }
              return width > GRAPH_MIN_WIDTH ?
                  <div className="graph">
                    <div className="base">
                      <XYPlot
                          getX={d => d.timestamp }
                          getY={d => d.totalRxBitrate }
                          xType="time"
                          yDomain={[0, maxRxBitrate + (parseInt(maxRxBitrate * 0.2))]}
                          height={125}
                          width={width}
                          margin={margins}>
                        <HorizontalGridLines style={{ stroke: GRAPH_GRID_COLOR_DARK }} tickTotal={tickTotalY}/>
                        <VerticalGridLines style={{ stroke: GRAPH_GRID_COLOR_DARK }} tickTotal={tickTotalX}/>
                        <XAxis style={{ line: {stroke: GRAPH_GRID_COLOR_DARK}, text: {stroke: GRAPH_LEGEND_COLOR}}} tickTotal={tickTotalX} tickFormat={v => `${DayJS(v).format('HH:mm')}`} tickSizeOuter={0} tickSizeInner={tickWidth}/>
                        <YAxis style={{ line: {stroke: GRAPH_GRID_COLOR_DARK}, text: {stroke: GRAPH_LEGEND_COLOR}}} tickTotal={tickTotalY} tickFormat={ this.tickFormatBitrate } tickSizeOuter={0} tickSizeInner={tickWidth}/>
                        <LineSeries getNull={(d) => (d.hidden !== true && this.state.allInterface)} data={this.props.stats.data} color={GRAPH_COLORS[0]}/>
                      </XYPlot>
                    </div>
                    { this.props.stats.currentNetworkInterfaces.map((networkInterface, index) => {
                      if (!this.state[networkInterface]) {
                        return (
                        <div key={ networkInterface }
                            className="overlay">
                          <XYPlot
                              getX={d => d.timestamp }
                              getY={d => d[networkInterface] ? d[networkInterface].rxBitrate : 0 }
                              xType="time"
                              yDomain={
                                [0, this.state.allInterface || maxRxBitrate === 0
                                  ? this.props.stats.maxTotalRxBitrate + (parseInt(this.props.stats.maxTotalRxBitrate * 0.2))
                                  : maxRxBitrate + (parseInt(maxRxBitrate * 0.2))
                                ]
                              }
                              height={125}
                              width={width}
                              margin={margins}>
                            <LineSeries getNull={(d) => d.hidden !== true} data={this.props.stats.data} color={this.getBackgroundColor(index)}/>
                          </XYPlot>
                        </div>
                    )}
                    else return null})}
                  </div> : null;
            }}
          </AutoSizer>
          }
          { this.state.graphTx &&
          <div className="subtitle">
            <Translate id="genericLabel.TX_BITRATE.text"/>
          </div>
          }
          { this.state.graphTx &&
          <AutoSizer disableHeight>
            {({ width }) => {
              // Y Domain depending on visible interface
              let maxTxBitrate = 0;
              for (const networkInterface in  this.props.stats.maxTxBitrate) {
                if (!this.state[networkInterface] && this.props.stats.maxTxBitrate[networkInterface] > maxTxBitrate) {
                  maxTxBitrate = this.props.stats.maxTxBitrate[networkInterface]
                }
              }
              if (this.state.allInterface || maxTxBitrate === 0) {
                maxTxBitrate = this.props.stats.maxTotalTxBitrate
              }
              return width > GRAPH_MIN_WIDTH ?
                  <div className="graph">
                    <div className="base">
                      <XYPlot
                          getX={d => d.timestamp }
                          getY={d => d.totalTxBitrate }
                          xType="time"
                          yDomain={[0, maxTxBitrate + (parseInt(maxTxBitrate * 0.2))]}
                          height={125}
                          width={width}
                          margin={margins}>
                        <HorizontalGridLines style={{ stroke: GRAPH_GRID_COLOR_DARK }} tickTotal={tickTotalY}/>
                        <VerticalGridLines style={{ stroke: GRAPH_GRID_COLOR_DARK }} tickTotal={tickTotalX}/>
                        <XAxis style={{ line: {stroke: GRAPH_GRID_COLOR_DARK}, text: {stroke: GRAPH_LEGEND_COLOR}}} tickTotal={tickTotalX} tickFormat={v => `${DayJS(v).format('HH:mm')}`} tickSizeOuter={0} tickSizeInner={tickWidth}/>
                        <YAxis style={{ line: {stroke: GRAPH_GRID_COLOR_DARK}, text: {stroke: GRAPH_LEGEND_COLOR}}} tickTotal={tickTotalY} tickFormat={ this.tickFormatBitrate } tickSizeOuter={0} tickSizeInner={tickWidth}/>
                        <LineSeries getNull={(d) => (d.hidden !== true && this.state.allInterface)} data={this.props.stats.data} color={GRAPH_COLORS[0]}/>
                      </XYPlot>
                    </div>
                    { this.props.stats.currentNetworkInterfaces.map((networkInterface, index) => {
                      if (!this.state[networkInterface]) {
                        return (
                        <div key={ networkInterface }
                            className="overlay">
                          <XYPlot
                              getX={d => d.timestamp }
                              getY={d => d[networkInterface] ? d[networkInterface].txBitrate : 0 }
                              xType="time"
                              yDomain={
                                [0, this.state.allInterface || maxTxBitrate === 0
                                  ? this.props.stats.maxTotalTxBitrate + (parseInt(this.props.stats.maxTotalTxBitrate * 0.2))
                                  : maxTxBitrate + (parseInt(maxTxBitrate * 0.2))
                                ]
                              }
                              height={125}
                              width={width}
                              margin={margins}>
                            <LineSeries getNull={(d) => d.hidden !== true} data={this.props.stats.data} color={this.getBackgroundColor(index)}/>
                          </XYPlot>
                        </div>
                    )}
                    else return null })}
                  </div> : null;
            }}
          </AutoSizer>
          }
          <div className="interfaces">
            <Table borderless
                  className="aw-table"
                  size="sm">
              <thead>
              <tr>
                <th><Translate id="genericLabel.NETWORK.text"/></th>
                <th><Translate id="genericLabel.TYPE.text"/></th>
                <th><Translate id="genericLabel.ID.text"/></th>
                <th><Translate id="genericLabel.STATUS.text"/></th>
                <th><Translate id="genericLabel.LOST_PACKETS.text"/></th>
                <th><Translate id="genericLabel.BITRATE.text"/></th>
                <th><Translate id="genericLabel.RTT.text"/></th>
                <th><Translate id="genericLabel.ACTION.text"/></th>
              </tr>
              </thead>
              <tbody>
              { this.props.input.deviceNetworkInterfaces && this.props.input.deviceNetworkInterfaces.map(networkInterface => (
                <tr key={networkInterface.name}>
                  <td>{ lastStat !== null && lastStat[networkInterface.name] ? <Badge color="info"><Translate id="genericLabel.ACTIVE.text"/></Badge> : null }</td>
                  <td>
                    {networkInterface.type === 'ETHERNET' && networkInterface.sub_type !== 'BGAN' && <AWIcon name="network_interface_ethernet"/>}
                    {networkInterface.type === 'WIFI' && networkInterface.sub_type !== 'BGAN' && <AWIcon name="network_interface_wifi"/>}
                    {networkInterface.sub_type === 'BGAN' && <AWIcon name="network_interface_bgan"/>}
                    {networkInterface.type === 'GADGET' && <AWIcon name="usb"/>}
                    {networkInterface.type === 'WIFI_AP' && <AWIcon name="network_interface_wifi_ap"/>}
                    {networkInterface.type === 'CELLULAR' && networkInterface.sub_type === 'UNKNOWN' && <AWIcon name="network_interface_cellular"/>}
                    {networkInterface.type === 'CELLULAR' && networkInterface.sub_type === '3G' && <AWIcon name="network_interface_3g"/>}
                    {networkInterface.type === 'CELLULAR' && networkInterface.sub_type === '4G' && <AWIcon name="network_interface_4g"/>}
                    {networkInterface.type === 'CELLULAR' && networkInterface.sub_type === '5G' && <AWIcon name="network_interface_5g"/>}
                    <span className='supsub-block'>
                      <sup>{ networkInterface.band }</sup>
                      <sub>{ networkInterface.band_aux }</sub>
                    </span>
                  </td>
                  <td><Translate id={`interfaceName.${networkInterface.name}.text`}/></td>
                  <td>
                    { networkInterface.state === 'PENDING' &&
                      <ClipLoader loading={true} color={SPINNER_DASHBOARD_COLOR} size={18}/>
                    }
                    { networkInterface.state !== 'PENDING' && networkInterface.operator_name &&
                      <span>{ networkInterface.operator_name }</span>
                    }
                    { networkInterface.state !== 'PENDING' && !networkInterface.operator_name &&
                      <Translate id={`interfaceState.${networkInterface.error_state || networkInterface.state}.text`}/>
                    }
                  </td>
                  <td>{ lastStat !== null && lastStat[networkInterface.name] ? lastStat[networkInterface.name].lostPacketsCumul : null }</td>
                  <td className="bitrate">{ lastStat !== null && lastStat[networkInterface.name] ? formatBitrate(lastStat[networkInterface.name].rxBitrate) : null }</td>
                  <td style={{ minWidth: '4rem' }}>{ lastStat !== null && lastStat[networkInterface.name] ? formatRTT(lastStat[networkInterface.name].rtt) : null }</td>
                  <td>
                  { ((networkInterface.type === 'CELLULAR' && this.props.input.deviceProduct !== 'DMNG-APP') || networkInterface.caps === 1) &&
                    <Fragment>
                      { networkInterface.state === 'CONNECTED' &&
                        <Button className="basic" onClick={() => this.props.callDisconnectModem(networkInterface.name)} disabled={ hasViewerLevel}>
                          <AWIcon name="toggle_on" color="primary"/>
                        </Button>
                      }
                      { (networkInterface.state === 'DISCONNECTED' || networkInterface.state === 'INACTIVE' || networkInterface.state === 'UNKNOWN') &&
                        <Button className="basic" onClick={() => this.props.callReconnetModem(networkInterface.name)} disabled={ hasViewerLevel}>
                            <AWIcon name="toggle_off" color="primary"/>
                        </Button>
                      }
                      { networkInterface.state !== 'CONNECTED' && networkInterface.state !== 'DISCONNECTED' && networkInterface.state !== 'INACTIVE' && networkInterface.state !== 'UNKNOWN' &&
                        <Button className="basic" disabled>
                          <AWIcon name="toggle_off"/>
                        </Button>
                      }
                    </Fragment>
                  }
                  </td>
                </tr>
              ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    );
  }
}

NetworkInfo.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
  return {
    stats: state.datastore.inputStreamStats[ownProps.input.id]
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    callReconnetModem: (name) => dispatch(reconnectModem(name, ownProps.input)),
    callDisconnectModem: (name) => dispatch(disconnectModem(name, ownProps.input)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(NetworkInfo);