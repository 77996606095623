import { AWHeading} from "@aviwest/ui-kit";
import { FieldArray, Formik, getIn } from "formik";
import PropTypes from "prop-types";
import React, {Component, Fragment} from 'react';
import {Translate, withLocalize} from "react-localize-redux";
import { Alert, Button, Col, Form, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import { SETTINGS_OUTPUT_CUSTOM_PATTERN_PREFIX, STATUS_LIVE, STATUS_OFF, STATUS_ON, VIDEO_CARD_TYPE_RIVERMAX } from "../../../../../constants";
import { getAvailableHardwareOutputStandards, getAvailableLostStreamModes, getAvailableVideoCards } from "../../../../../misc/capabilities.selectors";
import { getBasebandPlayerOutputNames } from "../../../../../misc/config.selectors";
import { outputMatchingVideoCard } from "../../../../../utils/global-utils";
import { lostStreamModePropTypes } from "../../../../../utils/models-prop-types";
import HelpLayout from "../../../../common/help-layout";
import CustomPatternForm from "../../../settings/custom-pattern/custom-pattern-form";
import { connect } from 'react-redux';
import { isEmptyString } from '../../../../../utils/string-utils';


const propTypes = {
    onSubmit: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired,
    forbiddenNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    outputId: PropTypes.string.isRequired,
    outputStatus: PropTypes.oneOf([STATUS_LIVE, STATUS_OFF, STATUS_ON]),
    config: PropTypes.shape({
      name: PropTypes.string.isRequired,
      outputStandard: PropTypes.string.isRequired,
      sameAsInput: PropTypes.bool.isRequired,
      lostStreamMode: PropTypes.number.isRequired,
      lostPatternMode: PropTypes.number.isRequired,
      enableDisplayName: PropTypes.bool,
      enableMulticast: PropTypes.bool,
      ttl: PropTypes.number,
      destinations: PropTypes.string,
      video: PropTypes.shape({
        redundancy: PropTypes.bool,
      })
    }),
    lostStreamModes: PropTypes.arrayOf(lostStreamModePropTypes).isRequired,
    outputStandards: PropTypes.arrayOf(PropTypes.string).isRequired,
    localIps: PropTypes.arrayOf(PropTypes.string),
    licenceStatus: PropTypes.bool,
}

const PORT_MIN = 1024;
const PORT_MAX = 65535;
const CUSTOM_PATTERN_VALUE = 3;

class SMPTE2110Form extends Component {

    constructor(props) {
        super(props);
    
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCustomPattern = this.handleCustomPattern.bind(this);
    
        this.state = {
          customPatternFormVisible: false
        }
      }

      componentDidMount(){
        if(this.props.config.lostStreamMode === CUSTOM_PATTERN_VALUE || this.props.config.lostPatternMode === CUSTOM_PATTERN_VALUE){
          this.handleCustomPattern(true);
        }
      }
    
      handleCustomPattern(display){
        this.setState({
          customPatternFormVisible: display
        });
      }
    
      handleSubmit(values, {resetForm} ) {
        const data = {
          ...values,
          lostStreamMode: parseInt(values.lostStreamMode),
          lostPatternMode: parseInt(values.lostPatternMode)
        };
          let tempsdestinationsVideo = values.destinationsVideo.map(destination => `${destination.host}:${destination.port}`).join(',');
          let tempsdestinationsVideo2 = values.destinationsVideo2.map(destination => `${destination.host}:${destination.port}`).join(',');
          let tempsdestinationsAudio = values.destinationsAudio.map(destination => `${destination.host}:${destination.port}`).join(',');
          let tempsdestinationsAudio2 = values.destinationsAudio2.map(destination => `${destination.host}:${destination.port}`).join(',');
          data.video = {redundancy: values.redundancyVideo,
                        ttl: values.ttlVideo,
                        multicast: values.multicastVideo,
                        multicast2: values.multicastVideo2,
                        destinations: tempsdestinationsVideo,
                        destinations2: tempsdestinationsVideo2,
                        iface: values.ifaceVideo,
                        iface2: values.ifaceVideo2,
                        rtpPayLoadType: values.rtpPayLoadTypeVideo
                      }
          values.sameAsVideo ? data.audio = {...data.video, sameAsVideo:values.sameAsVideo} :
           data.audio = {redundancy: values.redundancyAudio,
                        ttl: values.ttlAudio,
                        multicast: values.multicastAudio,
                        multicast2: values.multicastAudio2,
                        destinations: tempsdestinationsAudio,
                        destinations2: tempsdestinationsAudio2,
                        iface: values.ifaceAudio,
                        iface2: values.ifaceAudio2,
                        sameAsVideo: values.sameAsVideo,
                        rtpPayLoadType: values.rtpPayLoadTypeAudio
                      };
          const  {redundancyVideo,redundancyAudio,ttlVideo,ttlAudio,multicastVideo,multicastVideo2,multicastAudio,multicastAudio2,destinationsVideo,destinationsVideo2,destinationsAudio,destinationsAudio2,ifaceVideo,ifaceVideo2,ifaceAudio,ifaceAudio2,sameAsVideo, rtpPayLoadTypeAudio, rtpPayLoadTypeVideo, ...rest}= data;
          this.props.onSubmit(rest);
          //console.log(rest);
          resetForm(values);
          this.props.closeModal();
        
      }

      destinationValidation(dest, values, errors, mult){
        // Destinations
          values[dest].forEach((destination, index) => {
          if(isEmptyString(destination.host)){
            if(!errors[dest]){
              errors[dest] = [];
            }
            if(!errors[dest][index]){
              errors[dest][index] = {};
            }
            errors[dest][index].host = 'genericLabel.REQUIRED_FIELD.text';
          }
                // Multicast address
          let multicastRegex = /^2(?:2[4-9]|3\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d?|0)){3}$/;
          let ipRegex = /\b(?:(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\.){3}(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\b/;
          let validMulticastAddress = true;

          // Invalid multicast IP
          if (destination.host && values[mult] && !multicastRegex.test(destination.host)) {
            validMulticastAddress = false;
          }
          // Invalid unicast IP (should not be a mutlicast IP)
          else if (destination.host && !values[mult] && (!ipRegex.test(destination.host) || multicastRegex.test(destination.host))) {
            validMulticastAddress = false;
          }

          if (!validMulticastAddress) {
            if(!errors[dest]){
              errors[dest] = [];
            }
            if(!errors[dest][index]){
              errors[dest][index] = {};
            }
            errors[dest][index].host = 'genericLabel.INVALID_IP_ADDRESS.text';
          }

          if(isEmptyString(destination.port)){
            if(!errors[dest]){
              errors[dest] = [];
            }
            if(!errors[dest][index]){
              errors[dest][index] = {};
            }
            errors[dest][index].port = 'genericLabel.REQUIRED_FIELD.text';
          } else if (destination.port < PORT_MIN || destination.port > PORT_MAX) {
            if(!errors[dest]){
              errors[dest] = [];
            }
            if(!errors[dest][index]){
              errors[dest][index] = {};
            }
            errors[dest][index].port = 'genericLabel.INVALID_FORMAT.text';
          }
        });
        return errors;
  }

    handleValidation(values){
        const errors = {};
        //Name
        if (isEmptyString(values.name)) {
          errors.name = 'genericLabel.REQUIRED_FIELD.text';
        }
        else if(this.props.forbiddenNames.indexOf(values.name) !== -1){
          errors.name = 'genericLabel.DUPLICATED_VALUES.text';
        }
    
        //SMPTE
        if(this.props.videoCard.sdiCardType===VIDEO_CARD_TYPE_RIVERMAX){
          //Destinations & iface
          this.destinationValidation('destinationsVideo', values, errors,'multicastVideo');
          if(isEmptyString(values.ifaceVideo)) {
            errors.ifaceVideo = 'genericLabel.REQUIRED_FIELD.text';
          }
          if(values.redundancyVideo){
            this.destinationValidation('destinationsVideo2',values,errors,'multicastVideo2');
            if(isEmptyString(values.ifaceVideo2)) {
              errors.ifaceVideo2 = 'genericLabel.REQUIRED_FIELD.text';
            }
          };
          if(!values.sameAsVideo){
            this.destinationValidation('destinationsAudio',values,errors,'multicastAudio');
            if(isEmptyString(values.ifaceAudio)) {
              errors.ifaceAudio = 'genericLabel.REQUIRED_FIELD.text';
            }
            if (values.redundancyAudio){
              this.destinationValidation('destinationsAudio2',values,errors,'multicastAudio2');
              if(isEmptyString(values.ifaceAudio2)) {
                errors.ifaceAudio2 = 'genericLabel.REQUIRED_FIELD.text';
              }
            }
          }
          if(isEmptyString(values.rtpPayLoadTypeVideo)){
            errors.rtpPayLoadTypeVideo = 'genericLabel.REQUIRED_FIELD.text';
          }
        }
        //console.log(errors)
        return errors;
      };

    render(){

        const props = this.props;
        const {translate, config, outputStatus, outputStandards, lostStreamModes, outputId } = props;    

        const defaultiFace = (iface)=>{
            var iface2=null;
            props.smpteIps.map(standard => {
              if(standard!==iface){
                iface2=standard
              }
            });
            return props.smpteIps.length<2 ? iface : iface2;
          };
          
          const defaultTTL = (multicast) => {
        if(multicast == null) {
          multicast = false;
        }
        return multicast ? 1 : 64;
      };

        const handleMulticastChange = (e, destination, setFieldValue, mutlname, ttlname) => {
            const multicastValue = e.target.checked;
            setFieldValue(mutlname, multicastValue);
            //setFieldValue('ttl', multicastValue ? 1 : 64);
            if(multicastValue && (mutlname==='multicastVideo' || mutlname==='multicastAudio') ){
            setFieldValue(ttlname, 1);
            //setFieldValue(destination, destination.filter((dest, index) => index === 0));
            }
            else if(mutlname==='multicastVideo' || mutlname==='multicastAudio'){
            setFieldValue(ttlname, 64);
            }
        };

        const handleSAVChange = (e, values, setFieldValue) => {
            const SAVValue = e.target.checked;
            setFieldValue('sameAsVideo', SAVValue)
            if (SAVValue){
              setFieldValue('multicastAudio', values.multicastVideo);
              setFieldValue('multicastAudio2', values.multicastVideo2);
              setFieldValue('redundancyAudio', values.redundancyVideo);
              setFieldValue('ttlAudio', values.ttlVideo);
              setFieldValue('destinationsAudio', values.destinationsVideo);
              setFieldValue('destinationsAudio2', values.destinationsVideo2);
              setFieldValue('ifaceAudio', values.ifaceVideo);
              setFieldValue('ifaceAudio2', values.ifaceVideo2);
            }
          };

    return(
        <Formik initialValues={{
                name: config.name,
                outputStandard: config.outputStandard,
                sameAsInput: config.sameAsInput,
                lostStreamMode: `${config.lostStreamMode}`, // We transform to string here so that 'dirty' prop works
                lostPatternMode: `${config.lostPatternMode}`,
                enableDisplayName: config.enableDisplayName,
                
                //SMPTE2110 video settings
                multicastVideo: config.video ? config.video.multicast : false,
                ttlVideo: config.video && config.video.ttl != null ? config.video.ttl : defaultTTL(config.video ? config.video.multicast : null),
                destinationsVideo: config.video && config.video.destinations ? config.video.destinations.split(',').map(destination => {
                  return { host: destination.split(':')[0], port: destination.split(':')[1]
                  }}) :
                [{
                  host: '',
                  port: ''
                }],
                ifaceVideo: config.video ? config.video.iface : '',
                redundancyVideo: config.video? config.video.redundancy : false,

                multicastVideo2: config.video ? config.video.multicast2 : false,
                destinationsVideo2: config.video && config.video.destinations2 ? config.video.destinations2.split(',').map(destination => {
                  return { host: destination.split(':')[0], port: destination.split(':')[1]
                  }}) :
                [{
                  host: '',
                  port: ''
                }],
                ifaceVideo2: config.video ? defaultiFace(config.video.iface) : '',
                rtpPayLoadTypeVideo: config.video ? config.video.rtpPayLoadType : 96,

                //SMPTE2110 audio settings
                sameAsVideo: config.audio ? config.audio.sameAsVideo : true,
                multicastAudio: config.audio ? config.audio.multicast : false,
                ttlAudio: config.audio && config.audio.ttl != null ? config.audio.ttl : defaultTTL(config.audio ? config.audio.multicast : null),
                destinationsAudio: config.audio && config.audio.destinations ? config.audio.destinations.split(',').map(destination => {
                  return { host: destination.split(':')[0], port: destination.split(':')[1]
                  }}) :
                [{
                  host: '',
                  port: ''
                }],
                ifaceAudio: config.audio? config.audio.iface : '',
                redundancyAudio: config.audio ? config.audio.redundancy : false,
                multicastAudio2: config.audio ? config.audio.multicast2 : false,
                destinationsAudio2: config.audio && config.audio.destinations2 ? config.audio.destinations2.split(',').map(destination => {
                  return { host: destination.split(':')[0], port: destination.split(':')[1]
                  }}) :
                [{
                  host: '',
                  port: ''
                }],
                ifaceAudio2: config.audio ? defaultiFace(config.audio.iface) : '',
                rtpPayLoadTypeAudio: config.audio ? config.audio.rtpPayLoadType : 100,
                }}
              validate={this.handleValidation}
              validateOnBlur={false}
              validateOnChange={true}
              onSubmit={this.handleSubmit}>
            {({
            values,
            errors,
            dirty,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue
            /* and other goodies */
          }) => (
           <Form className="output-settings-form"
                 onSubmit={handleSubmit}>

<HelpLayout filename="c_sh_sdi_output_settings.html"
              form={<Fragment>

                {outputStatus !== STATUS_OFF &&
                <Alert color="warning">
                  <Translate id="genericLabel.WARNING_LOCAL_OUTPUT.text"/>
                </Alert>
                }
                {props.videoCard.sdiCardType===VIDEO_CARD_TYPE_RIVERMAX && !props.licenceStatus &&
                <Alert color="danger">
                  Invalid or missing SMPTE 2110 license, please add one to continue
                </Alert>
                }

                <FormGroup>
                  <Label for="name">
                    <Translate id="genericLabel.NAME.text"/>
                  </Label>
                  <Input type="text"
                         required
                         name="name"
                         id="output_hardwareSetting_name"
                         invalid={errors.name !== undefined}
                         placeholder={translate("genericLabel.NAME.text")}
                         value={values.name}
                         onBlur={handleBlur}
                         onChange={handleChange}/>
                  <FormFeedback>
                    <Translate id={errors.name}/>
                  </FormFeedback>
                  <div className="indicator">
                    <Translate id={`genericLabel.NAME_DISPLAYED_ON_PREVIEW_AND_OUTPUT.text`}/>
                  </div>
                </FormGroup>

                <FormGroup>
                  <Label for="outputStandard">
                    <Translate id="genericLabel.OUTPUT_STANDARD.text"/>
                  </Label>
                  <Input type="select"
                         name="outputStandard"
                         id="output_hardwareSetting_outputStandard"
                         onBlur={handleBlur}
                         onChange={handleChange}
                         value={values.outputStandard}>
                    {outputStandards.map(standard => {
                      return <option key={standard} value={standard}>{standard}</option>
                    })}
                  </Input>
                </FormGroup>

                <FormGroup check>
                  <Label check>
                    <Input type="checkbox"
                           name="sameAsInput"
                           id="output_hardwareSetting_sameAsInput"
                           onBlur={handleBlur}
                           onChange={handleChange}
                           checked={values.sameAsInput}/>{' '}
                    <Translate id="genericLabel.SAME_AS_CAMERA_VIDEO_STANDARD.text"/>
                  </Label>
                </FormGroup>

                {values.lostStreamMode !== 0 &&
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox"
                           name="enableDisplayName"
                           id="output_hardwareSetting_enableDisplayName"
                           onChange={handleChange}
                           checked={values.enableDisplayName}/>{' '}
                    <Translate id="genericLabel.DISPLAY_OUTPUT_NAME.text"/>
                  </Label>
                </FormGroup>
                }
                {props.videoCard.sdiCardType===VIDEO_CARD_TYPE_RIVERMAX && <div>
                <AWHeading priority={6}>
                  <Translate id="genericLabel.VIDEO.text"/>
                </AWHeading>
                <Col>
                  <Row form>
                    <Col xs={6}>
                      <FormGroup check>
                        <Label check>
                          <Input type="checkbox"
                                name="multicastVideo"
                                id="output_hardwareSetting_enableMulticastVideo"
                                checked={values.multicastVideo}
                                onChange={ (e) => handleMulticastChange(e, values.destinationsVideo, setFieldValue, 'multicastVideo', 'ttlVideo')}
                                disabled={!props.licenceStatus}/>{' '}
                          <Translate id="genericLabel.MULTICAST.text"/>
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col xs={4}>
                      <FormGroup check>
                        <Label check>
                          <Input type="checkbox"
                                name="redundancyVideo"
                                id="output_hardwareSetting_enableredundancyVideo"
                                checked={values.redundancyVideo}
                                onChange={ handleChange}
                                disabled={values.ifaceVideo2===values.ifaceVideo || !props.licenceStatus}/>{' '}
                          Redundancy
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                <Row form>
                  <Col xs={6}>
                    <FormGroup>
                        <Label for="ifaceVideo">
                          Interface
                        </Label>
                        <Input type="select"
                              name="ifaceVideo"
                              id="output_hardwareSetting_ifaceVideo"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.ifaceVideo}
                              invalid={ getIn(errors, `ifaceVideo`) != null }
                              disabled={!props.licenceStatus}>
                          <option value=""></option>
                          {props.smpteIps.map(standard => {
                            return <option key={standard} value={standard}>{standard}</option>
                          })}
                        </Input>
                        <FormFeedback>
                          { getIn(errors, `ifaceVideo`) != null && <Translate id={errors.ifaceVideo}/> }
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col xs={4}>
                      <FormGroup>
                      <Label>
                        <Translate id="genericLabel.TTL.text">
                          TTL
                        </Translate>
                      </Label>
                      <Input type="number"
                            min="0"
                            max="64"
                            name="ttlVideo"
                            id="output_hardwareSetting_ttlVideo"
                            invalid={errors.ttlVideo !== undefined}
                            placeholder={ translate('genericLabel.TTL.text') }
                            value={values.ttlVideo}
                            onChange={handleChange}
                            disabled={!props.licenceStatus}/>
                      <FormFeedback>
                        <Translate id={errors.ttlVideo} />
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                  <FormGroup>
                    <Label>
                      <Translate id="genericLabel.DESTINATION.text" />
                    </Label>
                    <FieldArray name="destinationsVideo"
                                validateOnChange={false}>
                      {({ push, remove }) => {
                        return (
                          <Fragment>
                            { values.destinationsVideo.map((destination, index) => (
                              <Row key={index}
                                  className="destination-row"
                                  form>
                                <Col xs={6}>
                                  <FormGroup>
                                    <Input type="text"
                                          invalid={ getIn(errors, `destinationsVideo[${index}].host`) != null }
                                          name={`destinationsVideo[${index}].host`}
                                          id={"output_hardwareSetting_desinationsVideo_"+index}
                                          onChange={handleChange}
                                          placeholder={ translate('genericLabel.HOST.text') }
                                          value={values.destinationsVideo[index].host}
                                          disabled={!props.licenceStatus}/>
                                    <FormFeedback>
                                      { getIn(errors, `destinationsVideo[${index}].host`) != null && <Translate id={errors.destinationsVideo[index].host}/> }
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                                <Col xs={4}>
                                  <FormGroup>
                                    <Input type="number"
                                          invalid={ getIn(errors, `destinationsVideo[${index}].port`) != null }
                                          name={`destinationsVideo[${index}].port`}
                                          min={PORT_MIN}
                                          max={PORT_MAX}
                                          id={"output_hardwareSetting_portsVideo_"+index}
                                          onChange={handleChange}
                                          placeholder={ translate('genericLabel.PORT.text') }
                                          value={values.destinationsVideo[index].port}
                                          disabled={!props.licenceStatus}/>
                                    <FormFeedback>
                                      { getIn(errors, `destinationsVideo[${index}].port`) != null && <Translate id={errors.destinationsVideo[index].port}/> }
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                                </Row>
                            ))}
                              { errors.destinationsGlobal &&
                              <div className="form-error">
                                <Translate id={errors.destinationsGlobal} /> { errors.duplicateUriFrom }
                              </div>
                            }
                          </Fragment>
                        )}}
                    </FieldArray>
                  </FormGroup>
                  {values.redundancyVideo &&
                    <div>
                      <Label className="label warning">
                        Redundancy Settings
                      </Label>
                      <FormGroup check>
                  <Label check>
                    <Input type="checkbox"
                          name="multicastVideo2"
                          id="output_hardwareSetting_enableMulticastVideo2"
                          checked={values.multicastVideo2}
                          onChange={ (e) => handleMulticastChange(e, values.destinationsVideo2, setFieldValue, 'multicastVideo2', 'ttlVideo')}
                          disabled={!props.licenceStatus}/>{' '}
                    <Translate id="genericLabel.MULTICAST.text"/>
                  </Label>
                </FormGroup>
                <Row form>
                  <Col xs={6}>
                    <FormGroup>
                        <Label for="ifaceVideo2">
                          Interface
                        </Label>
                        <Input type="select"
                              name="ifaceVideo2"
                              id="output_hardwareSetting_ifaceVideo2"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={defaultiFace(values.ifaceVideo)}
                              disabled
                              invalid={ getIn(errors, `ifaceVideo2`) != null }>
                              <option value=""></option>
                              {props.smpteIps.map(standard => {
                                return <option key={standard} value={standard}>{standard}</option>
                              })}
                        </Input>
                        <FormFeedback>
                          { getIn(errors, `ifaceVideo2`) != null && <Translate id={errors.ifaceVideo2}/> }
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                </Row>
                  <FormGroup>
                    <Label>
                      <Translate id="genericLabel.DESTINATION.text" />
                    </Label>
                    <FieldArray name="destinationsVideo2"
                                validateOnChange={false}>
                      {({ push, remove }) => {
                        return (
                          <Fragment>
                            { values.destinationsVideo2.map((destination, index) => (
                              <Row key={index}
                                  className="destination-row"
                                  form>
                                <Col xs={6}>
                                  <FormGroup>
                                    <Input type="text"
                                          invalid={ getIn(errors, `destinationsVideo2[${index}].host`) != null }
                                          name={`destinationsVideo2[${index}].host`}
                                          id={"output_hardwareSetting_destinationsVideo2_"+index}
                                          onChange={handleChange}
                                          placeholder={ translate('genericLabel.HOST.text') }
                                          value={values.destinationsVideo2[index].host}
                                          disabled={!props.licenceStatus}/>
                                    <FormFeedback>
                                      { getIn(errors, `destinationsVideo2[${index}].host`) != null && <Translate id={errors.destinationsVideo2[index].host}/> }
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                                <Col xs={4}>
                                  <FormGroup>
                                    <Input type="number"
                                          invalid={ getIn(errors, `destinationsVideo2[${index}].port`) != null }
                                          name={`destinationsVideo2[${index}].port`}
                                          id={"output_hardwareSetting_portsVideo2_"+index}
                                          min={PORT_MIN}
                                          max={PORT_MAX}
                                          onChange={handleChange}
                                          placeholder={ translate('genericLabel.PORT.text') }
                                          value={values.destinationsVideo2[index].port}
                                          disabled={!props.licenceStatus}/>
                                    <FormFeedback>
                                      { getIn(errors, `destinationsVideo2[${index}].port`) != null && <Translate id={errors.destinationsVideo2[index].port}/> }
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                                </Row>
                            ))}
                              { errors.destinationsGlobal &&
                              <div className="form-error">
                                <Translate id={errors.destinationsGlobal} /> { errors.duplicateUriFrom }
                              </div>
                            }
                          </Fragment>
                        )}}
                    </FieldArray>
                  </FormGroup>
                    </div>
                  }
                  <Row form>
                    <Col xs={10}>
                      <FormGroup>
                          <Label>
                              RTP Payload Type
                          </Label>
                          <Input type="number"
                                min="96"
                                max="127"
                                name="rtpPayLoadTypeVideo"
                                id="output_hardwareSetting_rtpPayLoadTypeVideo"
                                invalid={errors.rtpPayLoadTypeVideo !== undefined}
                                placeholder={ "rtp Payload Type" }
                                value={values.rtpPayLoadTypeVideo}
                                onChange={handleChange}
                                disabled={!props.licenceStatus}/>
                          <FormFeedback>
                            <Translate id={errors.rtpPayLoadTypeVideo} />
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                </Col>

              <AWHeading priority={6}>
                <Translate id="genericLabel.AUDIO.text"/>
              </AWHeading>
              <Col>
                <Row form>
                  <Col xs={4}>
                    <FormGroup check>
                      <Label check>
                        <Input type="checkbox"
                              name="sameAsVideo"
                              id="output_hardwareSetting_sameAsVideo"
                              checked={values.sameAsVideo}
                              onChange={(e) => handleSAVChange(e,values, setFieldValue)}
                              disabled={!props.licenceStatus}/>{' '}
                        Same as Video
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col xs={3}>
                    <FormGroup check
                               hidden={values.sameAsVideo}>
                      <Label check>
                        <Input type="checkbox"
                              name="multicastAudio"
                              id="output_hardwareSetting_enableMulticastAudio"
                              checked={values.multicastAudio}
                              onChange={ (e) => handleMulticastChange(e, values.destinationsAudio, setFieldValue, 'multicastAudio','ttlAudio')}
                              disabled={values.sameAsVideo || !props.licenceStatus}/>{' '}
                        <Translate id="genericLabel.MULTICAST.text"/>
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col xs={3}>
                    <FormGroup check
                               hidden={values.sameAsVideo}>
                      <Label check>
                        <Input type="checkbox"
                              name="redundancyAudio"
                              id="output_hardwareSetting_enableRedundancyAudio"
                              checked={values.redundancyAudio}
                              onChange={ handleChange}
                              disabled={values.ifaceAudio2===values.ifaceAudio || !props.licenceStatus}/>{' '}
                        Redundancy
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
              <Row form>
                <Col xs={6}>
                  <FormGroup hidden={values.sameAsVideo}>
                      <Label for="ifaceAudio">
                        Interface
                      </Label>
                      <Input type="select"
                            name="ifaceAudio"
                            id="output_hardwareSetting_ifaceAudio"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.ifaceAudio}
                            disabled={values.sameAsVideo || !props.licenceStatus}
                            invalid={ getIn(errors, `ifaceAudio`) != null }>
                            <option value=""></option>
                        {props.smpteIps.map(standard => {
                          return <option key={standard} value={standard}>{standard}</option>
                        })}
                      </Input>
                      <FormFeedback>
                          { getIn(errors, `ifaceAudio`) != null && <Translate id={errors.ifaceAudio}/> }
                        </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col xs={4}>
                    <FormGroup hidden={values.sameAsVideo}>
                    <Label>
                      <Translate id="genericLabel.TTL.text">
                        TTL
                      </Translate>
                    </Label>
                    <Input type="number"
                           min="0"
                           max="64"
                           name="ttlAudio"
                          id="output_hardwareSetting_ttlAudio"
                           invalid={errors.ttlAudio !== undefined}
                           placeholder={ translate('genericLabel.TTL.text') }
                           value={values.ttlAudio}
                           onChange={handleChange}
                           disabled={values.sameAsVideo || !props.licenceStatus}/>
                    <FormFeedback>
                      <Translate id={errors.ttlAudio} />
                    </FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
                <FormGroup hidden={values.sameAsVideo}>
                  <Label>
                    <Translate id="genericLabel.DESTINATION.text" />
                  </Label>
                  <FieldArray name="destinationsAudio"
                              validateOnChange={false}>
                    {({ push, remove }) => {
                      return (
                        <Fragment>
                          { values.destinationsAudio.map((destination, index) => (
                            <Row key={index}
                                className="destination-row"
                                form>
                              <Col xs={6}>
                                <FormGroup>
                                  <Input type="text"
                                        invalid={ getIn(errors, `destinationsAudio[${index}].host`) != null }
                                        name={`destinationsAudio[${index}].host`}
                                        id={"output_hardwareSetting_destinationsAudio"+index}
                                        onChange={handleChange}
                                        placeholder={ translate('genericLabel.HOST.text') }
                                        value={values.destinationsAudio[index].host}
                                        disabled={values.sameAsVideo || !props.licenceStatus}/>
                                  <FormFeedback>
                                    { getIn(errors, `destinationsAudio[${index}].host`) != null && <Translate id={errors.destinationsAudio[index].host}/> }
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                              <Col xs={4}>
                                <FormGroup>
                                  <Input type="number"
                                        invalid={ getIn(errors, `destinationsAudio[${index}].port`) != null }
                                        name={`destinationsAudio[${index}].port`}
                                        id={"output_hardwareSetting_portsAudio"+index}
                                        min={PORT_MIN}
                                        max={PORT_MAX}
                                        onChange={handleChange}
                                        placeholder={ translate('genericLabel.PORT.text') }
                                        value={values.destinationsAudio[index].port}
                                        disabled={values.sameAsVideo || !props.licenceStatus}/>
                                  <FormFeedback>
                                    { getIn(errors, `destinationsAudio[${index}].port`) != null && <Translate id={errors.destinationsAudio[index].port}/> }
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                              </Row>
                          ))}
                            { errors.destinationsGlobal &&
                            <div className="form-error">
                              <Translate id={errors.destinationsGlobal} /> { errors.duplicateUriFrom }
                            </div>
                          }
                        </Fragment>
                      )}}
                  </FieldArray>
                </FormGroup>
                {values.redundancyAudio && !values.sameAsVideo &&
                  <div>
                    <Label className="label warning">
                      Redundancy Settings
                    </Label>
                    <FormGroup check>
                <Label check>
                  <Input type="checkbox"
                        name="multicastAudio2"
                        id="output_hardwareSetting_enableMulticastAudio2"
                        checked={values.multicastAudio2}
                        onChange={ (e) => handleMulticastChange(e, values.destinationsAudio2, setFieldValue, 'multicastAudio2','ttlAudio')}
                        disabled={values.sameAsVideo || !props.licenceStatus}/>{' '}
                  <Translate id="genericLabel.MULTICAST.text"/>
                </Label>
              </FormGroup>
              <Row form>
                <Col xs={6}>
                  <FormGroup>
                      <Label for="ifaceAudio2">
                        Interface
                      </Label>
                      <Input type="select"
                            name="ifaceAudio2"
                            id="output_hardwareSetting_ifaceAudio2"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={defaultiFace(values.ifaceAudio)}
                            disabled
                            invalid={ getIn(errors, `ifaceAudio2`) != null }>
                            <option value=""></option>
                            {props.smpteIps.map(standard => {
                              return <option key={standard} value={standard}>{standard}</option>
                            })}
                      </Input>
                      <FormFeedback>
                          { getIn(errors, `ifaceAudio2`) != null && <Translate id={errors.ifaceAudio2}/> }
                        </FormFeedback>
                    </FormGroup>
                  </Col>
              </Row>
                <FormGroup>
                  <Label>
                    <Translate id="genericLabel.DESTINATION.text" />
                  </Label>
                  <FieldArray name="destinationsAudio2"
                              validateOnChange={false}>
                    {({ push, remove }) => {
                      return (
                        <Fragment>
                          { values.destinationsAudio2.map((destination, index) => (
                            <Row key={index}
                                className="destination-row"
                                form>
                              <Col xs={6}>
                                <FormGroup>
                                  <Input type="text"
                                        invalid={ getIn(errors, `destinationsAudio2[${index}].host`) != null }
                                        name={`destinationsAudio2[${index}].host`}
                                        id={"output_hardwareSetting_destinationsAudio2_"+index}
                                        onChange={handleChange}
                                        placeholder={ translate('genericLabel.HOST.text') }
                                        value={values.destinationsAudio2[index].host}
                                        disabled={values.sameAsVideo || !props.licenceStatus}/>
                                  <FormFeedback>
                                    { getIn(errors, `destinationsAudio2[${index}].host`) != null && <Translate id={errors.destinationsAudio2[index].host}/> }
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                              <Col xs={4}>
                                <FormGroup>
                                  <Input type="number"
                                        invalid={ getIn(errors, `destinationsAudio2[${index}].port`) != null }
                                        name={`destinationsAudio2[${index}].port`}
                                        id={"output_hardwareSetting_portsAudio2_"+index}
                                        min={PORT_MIN}
                                        max={PORT_MAX}
                                        onChange={handleChange}
                                        placeholder={ translate('genericLabel.PORT.text') }
                                        value={values.destinationsAudio2[index].port}
                                        disabled={values.sameAsVideo || !props.licenceStatus}/>
                                  <FormFeedback>
                                    { getIn(errors, `destinationsAudio2[${index}].port`) != null && <Translate id={errors.destinationsAudio2[index].port}/> }
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                              </Row>
                          ))}
                            { errors.destinationsGlobal &&
                            <div className="form-error">
                              <Translate id={errors.destinationsGlobal} /> { errors.duplicateUriFrom }
                            </div>
                          }
                        </Fragment>
                      )}}
                  </FieldArray>
                </FormGroup>
                  </div>
                }
                <Row form>
                    <Col xs={10}>
                      <FormGroup>
                          <Label>
                              RTP Payload Type
                          </Label>
                          <Input type="number"
                                min="96"
                                max="127"
                                name="rtpPayLoadTypeAudio"
                                id="output_hardwareSetting_rtpPayLoadTypeVideo"
                                invalid={errors.rtpPayLoadTypeAudio !== undefined}
                                placeholder={ "rtp Payload Type" }
                                value={values.rtpPayLoadTypeAudio}
                                onChange={handleChange}
                                disabled={!props.licenceStatus}/>
                          <FormFeedback>
                            <Translate id={errors.rtpPayLoadTypeAudio} />
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
              </Col>
            </div>}
                <FormGroup>
                  <Label for="lostStreamMode">
                    <Translate id="genericLabel.DEFAULT_VIDEO_PATTERN.text"/>
                  </Label>
                  <Input type="select"
                         name="lostStreamMode"
                         id="output_hardwareSetting_lostStreamMode"
                         onBlur={handleBlur}
                         onChange={(e) => {
                           handleChange(e);
                           if (parseInt(e.target.value) !== CUSTOM_PATTERN_VALUE && parseInt(values.lostPatternMode) !== CUSTOM_PATTERN_VALUE) {
                            this.handleCustomPattern(false);
                           }
                           else {
                             this.handleCustomPattern(true);
                           }
                         }}
                         value={values.lostStreamMode}>
                    {lostStreamModes.map(dest => {
                      return <option key={dest.value}
                                     value={dest.value}>{translate(`lostStreamMode.${dest.name}.text`)}</option>
                    })}
                  </Input>
                </FormGroup>

                <FormGroup>
                  <Label for="lostPatternMode">
                    <Translate id="genericLabel.POLICY_VIDEO_PATTERN.text"/>
                  </Label>
                  <Input type="select"
                         name="lostPatternMode"
                         id="output_hardwareSetting_lostPatternMode"
                         onBlur={handleBlur}
                         onChange={(e) => {
                           handleChange(e);
                           if (parseInt(e.target.value) !== CUSTOM_PATTERN_VALUE && parseInt(values.lostStreamMode) !== CUSTOM_PATTERN_VALUE) {
                             this.handleCustomPattern(false);
                           }
                           else {
                             this.handleCustomPattern(true);
                           }
                         }}
                         value={values.lostPatternMode}>
                    {lostStreamModes.map(dest => {
                      return <option key={dest.value}
                                     value={dest.value}>{translate(`lostStreamMode.${dest.name}.text`)}</option>
                    })}
                  </Input>
                </FormGroup>

                { this.state.customPatternFormVisible &&
                  <CustomPatternForm prefix={SETTINGS_OUTPUT_CUSTOM_PATTERN_PREFIX} id={outputId} timestamp={new Date().getTime()}/>
                }
                </Fragment>}

                buttons={
                  <FormGroup className="buttons">
                    <Button id="output_hardwareSetting_saveButton"
                            color="primary"
                            disabled={!dirty}
                            type="submit">
                      <Translate id="genericLabel.SAVE.text"/>
                    </Button>
                  </FormGroup>
                } />

            </Form>
          )}
      </Formik>
    );
    }
}

SMPTE2110Form.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
    const existingBasebandPlayerOutputNames = getBasebandPlayerOutputNames(state);
    return {
      outputStatus: state.streamhub.hardwareOutputs.find(output => output.id === ownProps.outputId).status,
      videoCard: outputMatchingVideoCard(getAvailableVideoCards(state), state.streamhub.hardwareOutputs.find(output => output.id === ownProps.outputId)),
      config: state.config.basebandPlayer[ownProps.outputId],
      lostStreamModes: getAvailableLostStreamModes(state),
      outputStandards: getAvailableHardwareOutputStandards(state, ownProps),
      forbiddenNames: existingBasebandPlayerOutputNames.filter(name => name !== state.config.basebandPlayer[ownProps.outputId].name),
      smpteIps: state.streamhub.smpteIps,
      localIps: state.streamhub.localIps,
      licenceStatus: state.streamhub.smpte2110[1] ? state.streamhub.smpte2110[0].allowed2110 : false,
    }
  };
  
  export default connect(mapStateToProps)(withLocalize(SMPTE2110Form));