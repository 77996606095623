import {
  DEVICE_PRODUCT_DMNGPRO,
  DEVICE_TYPE_AIR,
  DEVICE_TYPE_HE4000,
  DEVICE_TYPE_RACK,
  DEVICE_TYPE_MOJOPRO_APP,
  DEVICE_TYPE_MOJOPRO_LAPTOP,
  DEVICE_TYPE_PRO,
  DEVICE_TYPE_STUDIO,
  DEVICE_TYPE_UNKNOWN,
  FORWARD_STATUS_DONE,
  FORWARD_STATUS_FORWARDING,
  HLS,
  INPUT_TYPE_DEFAULT,
  INPUT_TYPE_DEVICE,
  INPUT_TYPE_IP,
  INTERCOM_STATUS_ERROR,
  INTERCOM_STATUS_NOT_SUPPORTED,
  INTERCOM_STATUS_OFF,
  INTERCOM_STATUS_ON,
  INTERCOM_STATUS_STARTING,
  LICENSE_STATUS_VALID,
  LIVE_STATUS_CONNECTING,
  LIVE_STATUS_ERROR,
  LIVE_STATUS_OFF,
  LIVE_STATUS_ON,
  IAP_STATUS_OFF,
  IAP_STATUS_ON,
  OUTPUT_TYPE_HARDWARE,
  OUTPUT_TYPE_NDI,
  OUTPUT_TYPE_IP,
  PLAYBACK_STATUS_OFF,
  PLAYBACK_STATUS_PAUSED,
  PLAYBACK_STATUS_PLAYING,
  PLAYBACK_STATUS_ERROR,
  RECORD_STATUS_OFF,
  RECORD_STATUS_ON,
  RECORD_STATUS_STARTING,
  RECORD_STATUS_CODEC_NOT_SUPPORTED,
  RECORD_STATUS_ERROR,
  VIDEO_IFB_STATUS_OFF,
  VIDEO_IFB_STATUS_ERROR,
  VIDEO_IFB_STATUS_ON,
  VIDEO_IFB_STATUS_STARTED,
  REF_INPUT_STATUS_DISABLED,
  REF_INPUT_STATUS_OFF,
  REF_INPUT_STATUS_ON,
  RTMP,
  RTSP,
  SDI,
  NDI,
  SRT,
  SST,
  STATUS_ERROR,
  STATUS_WARNING,
  STATUS_LIVE,
  STATUS_OFF,
  STATUS_ON,
  TS,
  WEBRTC,
  FILE_TRANSFER_TYPE_FTP
} from "../constants";
import {isString} from "../utils/string-utils";

export const mapSystemSpec = (metadata) => {
  const { cpuModelName, hardwareIdentifier, nbGpiInputs, enabledIntelGPU, isDocker } = metadata;
  return {
    cpuModelName,
    enabledIntelGPU,
    hardwareIdentifier,
    nbGpiInputs,
    isDocker
  }
};

export const createInputs = (nbInputs) => {
  const inputs = [];
  for(let i = 1; i <= nbInputs; i++){
    inputs.push({
      deviceType: null,
      deviceName: null,
      deviceProduct: null,
      deviceDisplayName: null,
      deviceMessage: null,
      deviceErrorStatus: null,
      deviceErrorStatusParam: null,
      deviceLiveStatus: null,
      deviceIAPStatus: null,
      deviceRecordStatus: null,
      deviceVideoIFBStatus: VIDEO_IFB_STATUS_OFF,
      deviceVideoReturnProfile: null,
      deviceForwardStatus: null,
      deviceIntercomStatus: null,
      deviceForwardFiles: null,
      firmwareName: null,
      id: `${i}`,
      info: null,
      intercomStatus: INTERCOM_STATUS_OFF,
      name: `IN${i}`,
      port: 4000 + i*10,
      playbackFilename: null,
      playbackStatus: PLAYBACK_STATUS_OFF,
      recordStatus: RECORD_STATUS_OFF,
      status: STATUS_OFF,
      type: INPUT_TYPE_DEFAULT
    });
  }
  return inputs;
};

export const createHardwareOutputs = (nbOutputs) => {
  const outputs = [];
  for(let i = 1; i <= nbOutputs; i++){
    outputs.push({
      id: `${i}`,
      inputId: '1',
      name: `OUT${i}`,
      status: STATUS_OFF,
      refInputStatus: REF_INPUT_STATUS_DISABLED,
      type: OUTPUT_TYPE_HARDWARE,
      videoCardId: null,
      has4K: false
    });
  }
  return outputs;
};

export const createNDIOutputs = (nbNDIOutputs) => {
  const outputs = [];
  for(let i = 1; i <= nbNDIOutputs; i++){
    outputs.push({
      id: `${i}`,
      inputId: '1',
      name: `OUT${i}`,
      status: STATUS_OFF,
      refInputStatus: REF_INPUT_STATUS_DISABLED,
      type: OUTPUT_TYPE_NDI
    });
  }
  return outputs;
};

export const createEncoders = (nbEncoders) => {
  const encoders = [];
  for(let i = 1; i <= nbEncoders; i++){
    encoders.push({
      id: `${i}`,
      name: `ENC${i}`,
      status: STATUS_OFF,
      profileId: '-1'
    });
  }
  return encoders;
};

export const mapConfigToStreamhubName = (config) => {
  return config.device && config.device.Identifier != null ? config.device.Identifier : null;
};

export const mapConfigToInputIPProfiles = (config, publicIp, localIps, orionPlatform) => {
  const protocolsObject = config.inputProtocol;
  const profiles = [];
  if(protocolsObject){
    Object.keys(protocolsObject).forEach((propertyName) => {
      const protocolObj = protocolsObject[propertyName];
      //TODO: Complete this here
      const ipProfile = {
        id: `${propertyName}`,
        name: protocolObj.name,
        protocol: protocolObj.type,
        enable: protocolObj.enable !== undefined ? protocolObj.enable : false,
        inputId: protocolObj.channelProfileId !== -1 ? `${protocolObj.channelProfileId}` : undefined
      };
      switch(protocolObj.type){
        case RTSP:
          ipProfile.uri = inputRtspURI(protocolObj);
          break;
        case RTMP:
          if(protocolObj.serverMode){
            if(localIps){
              ipProfile.localUris = inputRtmpPushLocalURIs(localIps, protocolObj);
            }
            if(publicIp){
              ipProfile.publicUri = inputRtmpPushPublicURI(publicIp, protocolObj);
            }
            if (config.hostname) {
              ipProfile.hostnameUri = inputRtmpPushPublicURI(config.hostname, protocolObj);
            }
          }
          else {
            ipProfile.uri = inputRtmpPullURI(protocolObj);
          }
          break;
        case HLS:
          ipProfile.uri = inputHlsURI(protocolObj);
          break;
        case TS:
          if (protocolObj.enableMulticast) {
            ipProfile.uri = inputTsMulticastURI(protocolObj);
          }
          ipProfile.port = protocolObj.port;
          break;
        case SRT:
          if(protocolObj.serverMode){
            if(localIps){
              ipProfile.localUris = inputSrtPushLocalURIs(localIps, protocolObj);
            }
            if(publicIp){
              ipProfile.publicUri = inputSrtPushPublicURI(publicIp, protocolObj);
            }
            if (config.hostname) {
              ipProfile.hostnameUri = inputSrtPushPublicURI(config.hostname, protocolObj);
            }
          }
          else {
            ipProfile.uri = inputSrtPullURI(protocolObj);
          }
          break;
        case SDI:
          ipProfile.enableEncoder = protocolObj.enableEncoder;
          ipProfile.encoderVideoType = protocolObj.encoderVideoType;
          ipProfile.encoderVideoBitrate = protocolObj.videoBitrate;
          ipProfile.encoderAudioType = protocolObj.encoderAudioType;
          ipProfile.encoderAudioBitrate = protocolObj.audioBitrate;
          ipProfile.sdiIdx = protocolObj.sdiIdx;
          ipProfile.sdiNbChannels = protocolObj.sdiNbChannels;
          break;
        case NDI:
          ipProfile.uri = inputNdiURI(protocolObj);
          break;
        case WEBRTC:
          ipProfile.uri = inputLiveGuestURI(protocolObj, orionPlatform);
          break;
        default:
          break;
      }
      profiles.push(ipProfile);
    });
  }
  return profiles;
};

export const mapConfigToUdpUsedPort = (config) => {
  // Ports used by Input Protocol
  const protocolsObject = config.inputProtocol;
  const udpUsedPort = {
    inputProtocolPorts: [],
    intercomPorts: [],
    channelPorts: [],
    atpGeneralBasePorts: [],
    LiveGuestPorts: [],
    remoteControlPorts: []
  };
  if(protocolsObject){
    Object.keys(protocolsObject).forEach((propertyName) => {
      const protocolObj = protocolsObject[propertyName];
      if (protocolObj.type === 'TS' || (protocolObj.type === 'SRT' && protocolObj.serverMode === true)) {
        udpUsedPort.inputProtocolPorts.push(protocolObj.port);
      }
    });
  }
  // Ports used by Intercom
  if (config.intercom) {
    for (let port = config.intercom.sipRtpPortStart; port <= config.intercom.sipRtpPortStart + config.intercom.sipRtpPortRange; port++) {
      udpUsedPort.intercomPorts.push(port);
    }
  }
  // Ports 4000
  udpUsedPort.channelPorts.push(4000);

  // Ports Live Guest video and audio streams
  for (let port = 20000; port <= 20100; port++) {
    udpUsedPort.LiveGuestPorts.push(port);
  }
  // Input ports Live Guest, usually = 5000
  if(config['guest-interview'].udpPort) {
    // 5000 + (10 * nbChannel) + 3
    for(let port = config['guest-interview'].udpPort; port <= config['guest-interview'].udpPort + (10 * Object.keys(config.channelProfile).length) + 3 ; port++) {
      udpUsedPort.LiveGuestPorts.push(port);
    }
  }


  // Ports 4010 + (10 * nbChannel)
  if (config.channelProfile) {
    for (let port = 4010; port <= 4010 + (10 * Object.keys(config.channelProfile).length); port++) {
      udpUsedPort.channelPorts.push(port);
    }
  }

  // Ports atpGeneralBasePort
  if (config.channelProfile && config.ebonding) {
    for (let port = config.ebonding.atpGeneralBasePort; port <= config.ebonding.atpGeneralBasePort + Object.keys(config.channelProfile).length; port++) {
      udpUsedPort.atpGeneralBasePorts.push(port);
    }
  }

  // Ports Remote Control
  if(config.tunRemoteControl) {
    udpUsedPort.remoteControlPorts.push(config.tunRemoteControl.webProxyPort);
    udpUsedPort.remoteControlPorts.push(config.tunRemoteControl.webProxyPortHttps);
  }

  return udpUsedPort;
};


export const mapConfigToEncoderProfiles = (config) => {
  if(!config.encoderProfile){
    return [];
  }
  return Object.keys(config.encoderProfile).map(key => {
    return {
      id: `${key}`,
      ...config.encoderProfile[key]
    }
  });
};

export const mapConfigToOutputIPProfiles = (config, publicIp, localIps) => {
  const streamingObject = config.streamingOutput;
  const profiles = [];
  if(streamingObject){
    Object.keys(streamingObject).forEach((propertyName) => {
      const streamingObj = streamingObject[propertyName];
      //TODO: Complete this here
      const ipProfile = {
        id: `${propertyName}`,
        name: streamingObj.name,
        protocol: streamingObj.mode,
        enable: streamingObj.enable !== undefined ? streamingObj.enable : false,
        available: streamingObj.outputOrder === -1,
        inputId: streamingObj.channelIndex != null && streamingObj.channelIndex >= 0 ? `${streamingObj.channelIndex + 1}` : null,
        encoderId: streamingObj.encoderIndex != null && streamingObj.encoderIndex >= 0 ? `${streamingObj.encoderIndex + 1}` : null,
      };
      switch(streamingObj.mode){
        case RTSP:
          if(streamingObj.serverMode === false){ //Note that serverMode is reversed compared to inputs -> serverMode now means Push -> not Pull
            ipProfile.uri = outputRtspPushURI(streamingObj);
          }
          else {
            if(localIps){
              ipProfile.localUris = outputRtspLocalURIs(localIps, streamingObj, config.rtspserver);
            }
            if(publicIp){
              ipProfile.publicUri = outputRtspPublicURI(publicIp, streamingObj, config.rtspserver);
            }
            if (config.hostname) {
              ipProfile.hostnameUri = outputRtspPublicURI(config.hostname, streamingObj, config.rtspserver);
            }
          }
          break;
        case RTMP:
          if(!streamingObj.serverMode){ //Note that serverMode is reversed compared to inputs -> serverMode now means Push -> not Pull
            ipProfile.uri = outputRtmpPushURI(streamingObj);
          }
          else {
            if(localIps){
              ipProfile.localUris = outputRtmpPullLocalURIs(localIps, streamingObj);
            }
            if(publicIp){
              ipProfile.publicUri = outputRtmpPullPublicURI(publicIp, streamingObj);
            }
            if (config.hostname) {
              ipProfile.hostnameUri = outputRtmpPullPublicURI(config.hostname, streamingObj);
            }
          }
          break;
        case HLS:
          if(localIps){
            ipProfile.localUris = outputHlsLocalURIs(localIps, streamingObj);
          }
          if(publicIp){
            ipProfile.publicUri = outputHlsPublicURI(publicIp, streamingObj);
          }
          if (config.hostname) {
            ipProfile.hostnameUri = outputHlsPublicURI(config.hostname, streamingObj);
          }
          break;
        case TS:
          ipProfile.uris = outputTsURIs(streamingObj);
          break;
        case SRT:
          ipProfile.serverMode = streamingObj.serverMode
          if(!streamingObj.serverMode){ //Note that serverMode is reversed compared to inputs -> serverMode now means Push -> not Pull
            ipProfile.uri = outputSrtPushURI(streamingObj);
          }
          else {
            if(localIps){
              ipProfile.localUris = outputSrtPullLocalURIs(localIps, streamingObj);
            }
            if(publicIp){
              ipProfile.publicUri = outputSrtPullPublicURI(publicIp, streamingObj);
            }
            if (config.hostname) {
              ipProfile.hostnameUri = outputSrtPullPublicURI(config.hostname, streamingObj);
            }
          }
          break;
        case SST:
          ipProfile.uri = outputSafestreamsURI(streamingObj);
          break;
        case WEBRTC:
          ipProfile.uri = outputWebRTCUri(streamingObj);
          break;
        default:
          break;
      }
      profiles.push(ipProfile);
    });
  }
  return profiles;
};

export const mapConfigToMultiView = (config, status, license) => {
  if(!config.mosaic || Object.keys(config.mosaic).length === 0 || status !== LICENSE_STATUS_VALID || !license.multiView){
    return null;
  }
  return {
    ...config.mosaic,
    status: config.mosaic.enable ? STATUS_LIVE : STATUS_OFF
  }
};

export const mapConfigToInputs = (config) => {
  const channelsObject = config.channelProfile;
  const inputs = [];
  let index = 0;
  Object.keys(channelsObject).forEach((propertyName) => {
    const channelObj = channelsObject[propertyName];
    inputs.push({
      id: propertyName,
      index: index,
      name: channelObj.name,
      autoRecord: channelObj.autoRecord,
      autoPlay: channelObj.autoPlay,
      recorderPrefix: channelObj.recorderPrefix,
      lostStreamTimeout: channelObj.lostStreamTimeout,
      rtpPort: channelObj.rtpPort,
      videoIFBSourceIdx: channelObj.videoIFBSourceIdx,
      videoIFBEncoderIdx: channelObj.videoIFBEncoderIdx,
      videoIFBLatency: channelObj.videoIFBLatency
    });
    index++;
  });
  return inputs;
};

export const mapConfigToEncoders = (config, status, license) => {
  const encoders = [];
  if(!config.enc || !config.encoderProfile || status !== LICENSE_STATUS_VALID || license.encoder === 0){
    return encoders;
  }
  Object.keys(config.enc).filter((key, index) => index < license.encoder).forEach(key => {
    const encoder = config.enc[key];
    const encoderProfile = config.encoderProfile[encoder.encoderProfileId];
    encoders.push({
      id: `${key}`,
      name: `ENC${key} ${encoderProfile ? encoderProfile.name : ''}`,
      videoReturnMode: encoderProfile ? encoderProfile.videoReturnMode : false,
      inputId: encoder.inputIndex != null ? `${encoder.inputIndex + 1}` : null,
      profileId: encoder.encoderProfileId != null ? `${encoder.encoderProfileId}` : null
    });
  });
  return encoders;
};

export const mapConfigToHardwareOutputs = (config) => {
  const outputs = [];
  // Hardware outputs
  const basebandPlayerObject = config.basebandPlayer;
  Object.keys(basebandPlayerObject).forEach((propertyName) => {
    const outputObj = basebandPlayerObject[propertyName];
    outputs.push({
      id: propertyName,
      name: outputObj.name,
      inputId: outputObj.channelIndex != null && outputObj.channelIndex >= 0 ? `${outputObj.channelIndex + 1}` : null,
      encoderId: outputObj.encoderIndex != null && outputObj.encoderIndex >= 0 ? `${outputObj.encoderIndex + 1}` : null,
      lockstate:  outputObj.lockstate !== undefined ? outputObj.lockstate : false
    });
  });
  return outputs;
};

export const mapConfigToNDIOutputs = (config) => {
  const outputs = [];
  const ndiOutputObject = config.NDIOutput;
  Object.keys(ndiOutputObject).forEach((propertyName) => {
    const outputObj = ndiOutputObject[propertyName];
    outputs.push({
      id: propertyName,
      name: outputObj.name,
      streamName: outputObj.streamName,
      inputId: outputObj.channelIndex != null && outputObj.channelIndex >= 0 ? `${outputObj.channelIndex + 1}` : null,
      lockstate:  outputObj.lockstate !== undefined ? outputObj.lockstate : false
    });
  });
  return outputs;
};

export const mapConfigToIPOutputs = (config, status, license) => {
  const outputs = [];
  if(status !== LICENSE_STATUS_VALID || license.IPOutput === 0){
    return outputs;
  }
  // IP outputs
  const streamingOutputObject = config.streamingOutput;
  Object.keys(streamingOutputObject)
    .filter(propertyName => streamingOutputObject[propertyName].outputOrder >= 0)
    .forEach(propertyName => {
      const output = streamingOutputObject[propertyName];
      outputs.push({
        id: `${propertyName}`,
        index: output.outputOrder,
        type: OUTPUT_TYPE_IP,
        inputId: output.channelIndex !== null ? (output.channelIndex >= 0 ? `${output.channelIndex + 1}` : "-1") : null,
        encoderId: output.encoderIndex !== null ? (output.encoderIndex >= 0 ? `${output.encoderIndex + 1}` : "-1") : null,
        ipProfileId: `${propertyName}`,
        lockstate:  output.lockstate !== undefined ? output.lockstate : false
      });
  });
  return outputs;
};

export const mapConfigToFileTransfers = (config) => {
  if(!config.fileTransfer) return [];
  return Object.keys(config.fileTransfer).map((key) => {
    const obj = config.fileTransfer[key];
    return {
      id: `${key}`,
      enabled: obj.enable,
      protocol: obj.transferType,
      name: obj.name,
      uri: ftpURI(obj),
      host: obj.host,
      port: obj.port,
      transferMode: obj.transferMode,
      path: obj.path ? obj.path : '/',
      transferType: obj.transferType,
      username: obj.username,
      password: obj.password,
    }
  });
};

export const mapChannelStatusToMultiView = (data) => {
  let status = null;
  switch(data.mosaic.mosaicStatus){
    case 1:
      status = STATUS_ON;
      break;
    case 2:
      status = STATUS_LIVE;
      break;
    case 3:
      status = STATUS_ERROR;
      break;
    default:
      status = STATUS_OFF;
      break;
  }
  return {
    status
  }
};

export const mapChannelStatusToInputs = (data) => {
  if(!data.channel){
    return [];
  }
  return data.channel.map((channel, index) => {
    let playbackStatus = null;
    let isPlaybackLooping = null;
    switch(channel.playbackStatus){
      case 1:
        playbackStatus = PLAYBACK_STATUS_PLAYING;
        isPlaybackLooping = channel.isPlaybackLooping;
        break;
      case 2:
        playbackStatus = PLAYBACK_STATUS_PAUSED;
        isPlaybackLooping = channel.isPlaybackLooping;
        break;
      case 5:
        playbackStatus = PLAYBACK_STATUS_ERROR;
        break;
      default:
        playbackStatus = PLAYBACK_STATUS_OFF;
    }
    let status = null;
    switch(channel.channelStatus){
      case 1:
        status = STATUS_ON;
        break;
      case 2:
        status = playbackStatus ===  PLAYBACK_STATUS_PAUSED ? STATUS_ON : STATUS_LIVE;
        break;
      case 3:
        status = STATUS_ERROR;
        break;
      case 4:
        status = STATUS_WARNING;
        break;
      default:
        status = STATUS_OFF;
    }
    let recordStatus = null;
    switch(channel.recorderStatus){
      case 0:
        recordStatus = RECORD_STATUS_CODEC_NOT_SUPPORTED;
        break;
      case 2:
        recordStatus = RECORD_STATUS_STARTING;
        break;
      case 3:
        recordStatus = RECORD_STATUS_ERROR;
        break;
      case 4:
        recordStatus = RECORD_STATUS_ON;
        break;
      default:
        recordStatus = RECORD_STATUS_OFF;
    }
    let intercomStatus = null;
    switch(channel.intercomStatus){
      case 1:
        intercomStatus = INTERCOM_STATUS_OFF;
        break;
      case 2:
        intercomStatus = INTERCOM_STATUS_STARTING;
        break;
      case 3:
        intercomStatus = INTERCOM_STATUS_ERROR;
        break;
      case 4:
        intercomStatus = INTERCOM_STATUS_ON;
        break;
      default:
        intercomStatus = INTERCOM_STATUS_NOT_SUPPORTED;
    }
    const playbackFilename = playbackStatus !== PLAYBACK_STATUS_OFF ? channel.identifier : null;
    let device = {};
    device.channelType = channel.channelType;
    device.inputInfo = channel.inputInfo;
    device.outputInfo = channel.outputInfo;
    device.videoIFBDecoderCapability = channel.videoIFBDecoderCapability ? channel.videoIFBDecoderCapability : null;
    device.videoReturnEncSrcIdx = channel.videoReturnEncSrcIdx;
    device.videoReturnSrcIdx = channel.videoReturnSrcIdx;
    device.booking = channel.booking ? channel.booking : null;
    device.firmwareName = channel.firmwareName;
    if(channel.product && channel.product.length > 0){
      if(channel.product === 'IP-INPUT') {
        device.type = INPUT_TYPE_IP;
        device.ipProfileId = channel.inputProtocolId > 0 ? `${channel.inputProtocolId}` : null;
      }
      else {
        switch(channel.product){
          case 'HE4000':
            device.deviceType = DEVICE_TYPE_HE4000;
            break;
          case 'DMNG-APP':
            device.deviceType = DEVICE_TYPE_MOJOPRO_APP;
            break;
          case 'DMNG-LAPTOP':
            device.deviceType = DEVICE_TYPE_MOJOPRO_LAPTOP;
            break;
          case 'DMNG-STUDIO':
            device.deviceType = DEVICE_TYPE_STUDIO;
            break;
          default:
            if(channel.product === DEVICE_PRODUCT_DMNGPRO || channel.product.startsWith('PRO')){
              device.deviceType = DEVICE_TYPE_PRO;
            }
            else if(channel.product.startsWith('AIR')){
              device.deviceType = DEVICE_TYPE_AIR;
            }
            else if(channel.product.startsWith('RACK')){
              device.deviceType = DEVICE_TYPE_RACK;
            }
            else {
              device.deviceType = DEVICE_TYPE_UNKNOWN;
            }
            break;
        }
        device.instanceId = channel.instanceId;
        device.deviceName = channel.identifier;
        device.deviceProduct = channel.product;
        device.deviceDisplayName = channel.displayName;
        device.deviceHardwareID = channel.hardwareIdentifier;
        device.remoteControl = channel.remoteControl;
        device.deviceVersion = channel.version;
        // binary AND with DEVICE_CAPS_DYNAMIC_LATENCY (1 << 0) = 1
        device.sliderLatencyCapability = (channel.deviceCaps & 1) ? true : false;
        // binary AND with DEVICE_CAPS_REMOTE_CONTROL (1 << 6) = 64
        device.remoteControlCapability = (channel.deviceCaps & 64) ? true : false;
        device.type = INPUT_TYPE_DEVICE;
      }
    }
    else {
      device.type = INPUT_TYPE_DEFAULT;
      device.deviceErrorStatus = null;
      device.deviceErrorStatusParam = null;
      device.deviceType = null;
      device.instanceId = null;
      device.deviceName = null;
      device.deviceProduct = null;
      device.deviceDisplayName = null;
      device.deviceIntercomStatus = null;
      device.deviceRecordStatus = null;
      device.deviceVideoIFBStatus = null;
      device.deviceVideoReturnProfile = null;
      device.deviceForwardStatus = null;
      device.deviceLiveStatus = null;
      device.deviceIAPStatus = null;
      device.deviceLiveInfo = null;
      device.deviceMetaData = null;
      device.deviceForwardFiles = null;
      device.deviceTunnelIPAddress = null;
      device.deviceNetworkInterfaces = null;
      device.deviceHardwareID = null;
      device.sliderLatencyCapability = null;
      device.remoteControlCapability = null;
      device.deviceVersion = null;
      device.firmwareName = null;
      device.inputInfo = null;
      device.outputInfo = null;
    }
    return {
      id: `${index + 1}`,
      //name: channel.name,
      status,
      recordStatus,
      intercomStatus,
      playbackStatus,
      isPlaybackLooping,
      playbackFilename,
      ...device
    };
  });
};

export const mapEncoderStatusToEncoders = (data) => {
  return data.encoder.map((enc, index) => {
    let recorderStatus = null;
    switch(enc.recorderStatus){
      case 0:
        recorderStatus = RECORD_STATUS_CODEC_NOT_SUPPORTED;
        break;
      case 2:
        recorderStatus = RECORD_STATUS_STARTING;
        break;
      case 4:
        recorderStatus = RECORD_STATUS_ON;
        break;
      default:
        recorderStatus = RECORD_STATUS_OFF;
    }
    return {
      id: `${index + 1}`,
      index: index,
      status: mapEncoderStatus(enc.status),
      inputInfo: enc.inputInfo,
      outputInfo: enc.outputInfo,
      enable: enc.enable,
      displayName: `${enc.inputIdentifier} - ${enc.name}`,
      message: enc.message,
      recordStatus: recorderStatus,
    }
  });
};

export const mapOutputStatusToHardwareOutputs = (data) => {
  if(!data.output){
    return [];
  }
  return data.output.map((output, index) => {
    let status = mapOutputStatus(output);
    let refInputStatus = REF_INPUT_STATUS_DISABLED;
    const videoStandard = output.outputStandard;
    const has4K = output.has4K;
    const videoCardId = `${output.videoCardIdx}`;
    if(output.hasRefInput === 1){
      refInputStatus = output.refInputStatus === 1 ? REF_INPUT_STATUS_ON : REF_INPUT_STATUS_OFF
    }
    return {
      id: `${index + 1}`,
      status,
      videoCardId,
      has4K,
      refInputStatus,
      videoStandard,
      inputInfo: output.inputInfo,
      outputInfo: output.outputInfo,
      booking: output.booking ? output.booking : null,
      message : output.message
    }
  });
};

export const mapOutputStatusToNDIOutputs = (data) => {
  if(!data.NDIOutput){
    return [];
  }
  return data.NDIOutput.map((output, index) => {
    let status = mapOutputStatus(output);
    let refInputStatus = REF_INPUT_STATUS_DISABLED;
    const videoStandard = output.outputStandard;
    if(output.hasRefInput === 1){
      refInputStatus = output.refInputStatus === 1 ? REF_INPUT_STATUS_ON : REF_INPUT_STATUS_OFF
    }
    return {
      id: `${index + 1}`,
      status,
      refInputStatus,
      videoStandard,
      nbConnect: output.nbConnect,
      tallyState: output.tallyState,
      inputInfo: output.inputInfo,
      outputInfo: output.outputInfo,
      booking: output.booking ? output.booking : null,
      message : output.message
    }
  });
};

export const mapOutputStatusToIPOutputs = (data) => {
  if(!data.IPOutput){
    return [];
  }
  return data.IPOutput.map((output) => {
    let status = mapOutputStatus(output);
    // We only keep status and connections from this message -> All other properties are handled in 'getConfig' message
    return {
      id: `${output.configKey}`,
      status: status,
      connections: output.connections,
      inputInfo: output.inputInfo,
      outputInfo: output.outputInfo,
      message: output.message,
      booking: output.booking ? output.booking : null
    }
  });
};

export const mapStatusDevicesChangeToInputs = (data) => {
  return data.device
      .map((device, index) => {
        // Reset previous message
        if (device.channel === undefined) {
          return {
            id: `${index + 1}`,
            deviceMessage: null,
            deviceErrorStatus: null,
            deviceErrorStatusParam: null
          }
        }
        return {
          id: `${device.channel}`,
          deviceTunnelIPAddress: device.tunIpAddress,
          deviceMessage: device.message,
          deviceErrorStatus: mapErrorStatus(device),
          deviceErrorStatusParam: mapErrorStatusParam(device),
          deviceIntercomStatus: mapIntercomStatus(device.intercomStatus),
          deviceRecordStatus: mapRecordStatus(device.recordStatus),
          deviceVideoIFBStatus: mapVideoIFBStatus(device.videoIFBStatus),
          deviceVideoReturnProfile: device.videoReturnProfile,
          deviceForwardStatus: mapForwardStatus(device.forwardStatus),
          deviceForwardInfo: mapForwardInfo(device.forwardInfo),
          deviceLiveStatus: mapLiveStatus(device.liveStatus),
          deviceIAPStatus: mapIAPStatus(device.IAPStatus),
          batteryLevel: device.batteryLevel
        }
      });
};

export const mapLiveProfileToInputs = (data) => {
  let deviceLiveProfiles = [];
  if(data.result && data.result.list){
    const dataObject = JSON.parse(data.result.list);
    const liveProfileObject = dataObject.liveProfile;
    deviceLiveProfiles = Object.keys(liveProfileObject).map(key => {
      return {
        id: `${key}`,
        ...liveProfileObject[key]
      }
    });
  }
  return [{
    id: `${data.channelId}`,
    deviceLiveProfiles
  }]
};

export const mapLiveInfoToInputs = (data) => {
  return [{
    id: `${data.channelId}`,
    deviceLiveProfileID: data.result ? data.result.liveProfileID : null,
    deviceLiveInfo: data.result ? data.result : null
  }];
};

export const mapMetaDataToInputs = (data) => {
  return [{
    id: `${data.channelId}`,
    deviceMetaData: data.metaData ? data.metaData : null
  }];
};

export const mapNetworkInterfaceToInputs = (data) => {
  return [{
    id: `${data.channelId}`,
    deviceNetworkInterfaces: data.result.interfaces_status
  }]
};

export const mapInputAudioDevices = (data) => {
  return data.input_devices;
};

export const mapOutputAudioDevices = (data) => {
  return data.output_devices;
};

export const matchingAudioDevice = (devices, deviceName) => {
  const result = devices.find(device => device.name === deviceName);
  return result !== undefined ? result : null;
};

export const matchInputWithProfiles = (inputs, ipProfiles) => {
  return inputs.map(input => {
    const matchingProfile = ipProfiles.find(profile => profile.inputId === input.id);
    if(matchingProfile){
      return {
        ...input,
        ipProfileId: matchingProfile.id,
        type: INPUT_TYPE_IP
      }
    }
    return {
      ...input,
      ipProfileId: null
    };
  });
};

export const updateInputsById = (baseInputs, newInputs) => {
  return baseInputs.map(input => {
    return { ...input, ...newInputs.find(newInput => newInput.id === input.id) }
  });
};

export const updateOutputsById = (baseOutputs, newOutputs, insertElementIfMissing = false) => {
  if(insertElementIfMissing){
    return newOutputs.map(newOutput => {
      let existingOutput = baseOutputs.find(output => output.id === newOutput.id);
      if(!existingOutput){
        existingOutput = {
          status: STATUS_OFF
        };
      }
      return { ...existingOutput, ...newOutput };
    });
  }
  else {
    return baseOutputs.map(output => {
      return { ...output, ...newOutputs.find(newOutput => newOutput.id === output.id) }
    });
  }
};

export const updateEncodersById = (baseEncoders, newEncoders) => {
  return baseEncoders.map(encoder => {
    return { ...encoder, ...newEncoders.find(newEncoder => newEncoder.id === encoder.id) }
  });
};

export const mapOutputStatus = (output) => {
  if(!output.enable){
    return STATUS_OFF;
  }
  switch (output.status) {
    case 1:
      return STATUS_ON;
    case 2:
      return STATUS_LIVE;
    case 3:
      return STATUS_ERROR;
    default:
      return STATUS_OFF;
  }
};

export const mapErrorStatus = (device) => {
  if (device.liveStatus < 0) {
    return device.liveStatus
  }
  if (device.IAPStatus < 0) {
    return device.IAPStatus
  }
  if (device.recordStatus < 0) {
    return device.recordStatus
  }
  if (device.forwardStatus < 0) {
    return device.forwardStatus
  }
  return 0
};

export const mapErrorStatusParam = (device) => {
  const errorStatusParam = {}
  let params = [];
  if (device.liveStatusErrorParam && device.liveStatusErrorParam !== '') {
    params = device.liveStatusErrorParam.split(';');
  }
  if (device.recordStatusErrorParam && device.recordStatusErrorParam !== '') {
    params = device.recordStatusErrorParam.split(';');
  }
  errorStatusParam['param.arg1'] = params[0] ? params[0] : '720p50';
  errorStatusParam['param.arg2'] = params[1] ? params[1] : '20';
  return errorStatusParam
};

export const mapLiveStatus = (liveStatus) => {
  if (liveStatus < 0) {
    return LIVE_STATUS_ERROR
  }
  switch(liveStatus){
    case 0:
      return LIVE_STATUS_OFF;
    case 5:
      return LIVE_STATUS_ON;
    default:
      return LIVE_STATUS_CONNECTING;
  }
};

export const mapIAPStatus = (IAPStatus) => {
  switch(IAPStatus){
    case 0:
      return IAP_STATUS_OFF;
    case 5:
      return IAP_STATUS_ON;
    default:
      return IAP_STATUS_OFF;
  }
};

export const mapForwardStatus = (forwardStatus) => {
  switch(forwardStatus){
    case 5:
      return FORWARD_STATUS_FORWARDING;
    default:
      return FORWARD_STATUS_DONE;
  }
};

export const mapForwardInfo = (forwardInfo) => {
  const percent = forwardInfo ? parseInt(forwardInfo.status) : 0;
  return {
    // If percent is not a number, it is a message
    progress: isNaN(percent) ? 100 : percent,
    message: isNaN(percent) ? forwardInfo.status : '',
    name: forwardInfo ? forwardInfo.fileName : '',
    size: forwardInfo ? forwardInfo.size : '',
    fileListCount: forwardInfo ? forwardInfo.fileListCount : 0
  };
};

export const mapRecordStatus = (recordStatus) => {
  switch(recordStatus){
    case 5:
      return RECORD_STATUS_ON;
    default:
      return RECORD_STATUS_OFF;
  }
};

export const mapVideoIFBStatus = (videoIFBStatus) => {
  switch(videoIFBStatus){
    case -1:
      return VIDEO_IFB_STATUS_ERROR;
    case 0:
      return VIDEO_IFB_STATUS_OFF;
    case 1:
      return VIDEO_IFB_STATUS_ON;
    case 2:
      return VIDEO_IFB_STATUS_STARTED;
    default:
      return VIDEO_IFB_STATUS_OFF;
  }
};

export const mapIntercomStatus = (intercomStatus) => {
  switch(intercomStatus){
    case 5:
      return INTERCOM_STATUS_ON;
    default:
      return INTERCOM_STATUS_OFF;
  }
};

export const mapEncoderStatus = (encoderStatus) => {
  switch(encoderStatus){
    case 1:
      return STATUS_ON;
    case 2:
      return STATUS_LIVE;
    case 3:
      return STATUS_ERROR;
    default:
      return STATUS_OFF;
  }
};

export const inputRtspURI = (ipProfile) => {
  return `rtsp://${ipProfile.host}:${ipProfile.port}/${ipProfile.label}`;
};

export const inputTsMulticastURI = (ipProfile) => {
  if (ipProfile.enableSSM && ipProfile.uriSSM) {
    return `udp://${ipProfile.uri}:${ipProfile.port}?sources=${ipProfile.uriSSM}`;
  } else {
    return `udp://${ipProfile.uri}:${ipProfile.port}`;
  }
};

export const inputRtmpPullURI = (ipProfile) => {
  return `${ipProfile.uri}/${ipProfile.streamKey}`;
};

export const inputRtmpPushLocalURIs = (localIps, ipProfile) => {
  const protocol = ipProfile.rtmpsMode === true ? 'rtmps' : 'rtmp'
  const port = ipProfile.rtmpsMode === true ? 19350 : 1935
  return localIps.map(localIp => `${protocol}://${localIp}:${port}/input/${ipProfile.streamName}`);
};

export const inputRtmpPushPublicURI = (host, ipProfile) => {
  const protocol = ipProfile.rtmpsMode === true ? 'rtmps' : 'rtmp'
  const port = ipProfile.rtmpsMode === true ? 19350 : 1935
  return `${protocol}://${host}:${port}/input/${ipProfile.streamName}`;
};

export const inputHlsURI = (ipProfile) => {
  return ipProfile.uri;
};

export const inputNdiURI = (ipProfile) => {
  return ipProfile.uri;
};

export const inputLiveGuestURI = (ipProfile, orionPlatform) => {
  return `https://${ orionPlatform && orionPlatform !== 'prod' ? `${orionPlatform}.` : ""}liveguest.aviwest.com/call/${ipProfile.identifier}`;
};

export const inputSrtPushLocalURIs = (localIps, ipProfile) => {
  return localIps.map(localIp => `srt://${localIp}:${ipProfile.port}`);
};

export const inputSrtPushPublicURI = (host, ipProfile) => {
  return `srt://${host}:${ipProfile.port}`;
};

export const inputSrtPullURI = (ipProfile) => {
  return ipProfile.uri;
};





export const outputRtspLocalURIs = (localIps, ipProfile, rtspServer) => {
  return localIps.map(localIp => `rtsp://${localIp}:${rtspServer ? rtspServer.port : ''}/${ipProfile.label}`);
};

export const outputRtspPublicURI = (host, ipProfile, rtspServer) => {
  return `rtsp://${host}:${rtspServer ? rtspServer.port : ''}/${ipProfile.label}`;
};

export const outputRtspPushURI = (ipProfile) => {
  return `${ipProfile.uri}/${ipProfile.streamKey}`;
};

export const outputRtmpPushURI = (ipProfile) => {
  return `${ipProfile.uri}/${ipProfile.streamKey}`;
};

export const outputRtmpPullLocalURIs = (localIps, ipProfile) => {
  const protocol = ipProfile.rtmpsMode === true ? 'rtmps' : 'rtmp'
  const port = ipProfile.rtmpsMode === true ? 19350 : 1935
  return localIps.map(localIp => `${protocol}://${localIp}:${port}/live/${ipProfile.streamName}`);
};

export const outputRtmpPullPublicURI = (host, ipProfile) => {
  const protocol = ipProfile.rtmpsMode === true ? 'rtmps' : 'rtmp'
  const port = ipProfile.rtmpsMode === true ? 19350 : 1935
  return `${protocol}://${host}:${port}/live/${ipProfile.streamName}`;
};

export const outputHlsLocalURIs = (localIps, ipProfile) => {
  return localIps.map(localIp => `${window.location.protocol}//${localIp}${window.location.protocol.indexOf('https') !== -1  ? '' : ':8888'}/hls/${ipProfile.dir}/playlist.m3u8`);
};

export const outputHlsPublicURI = (host, ipProfile) => {
  return `${window.location.protocol}//${host}${window.location.protocol.indexOf('https') !== -1  ? '' : ':8888'}/hls/${ipProfile.dir}/playlist.m3u8`;
};

export const outputTsURIs = (ipProfile) => {
  if (!ipProfile.protocol) {
    return [];
  }
  else if(isString(ipProfile.destinations)){
    return ipProfile.destinations.split(',').map(destination => `${ipProfile.protocol.toLowerCase()}://${destination}`);
  }
  return ipProfile.destinations.map(destination => `${ipProfile.protocol.toLowerCase()}://${destination.host}:${destination.port}`);
};

export const outputSafestreamsURI = (ipProfile) => {
  return `udp://${ipProfile.ipAddress}:${ipProfile.port}/${ipProfile.destChannel}`;
};

export const outputSrtPullLocalURIs = (localIps, ipProfile) => {
  return localIps.map(localIp => `srt://${localIp}:${ipProfile.port}`);
};

export const outputSrtPullPublicURI = (host, ipProfile) => {
  return `srt://${host}:${ipProfile.port}`;
};

export const outputSrtPushURI = (ipProfile) => {
  return ipProfile.uri;
};

export const outputWebRTCUri = (ipProfile) => {
  return ipProfile.uriStunServer ? ipProfile.uriStunServer : '';
};

export const ftpURI = (fileTransfer) => {
  const protocol = fileTransfer.transferType === FILE_TRANSFER_TYPE_FTP ? 'ftp' : 'sftp';
  return `${protocol}://${fileTransfer.host}:${fileTransfer.port}${fileTransfer.path}`;
};
