import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import {Translate} from "react-localize-redux";
import {Col, Container, Nav, NavItem, NavLink, Row} from "reactstrap";
import {Redirect, Route, Switch, withRouter} from "react-router";
import {Link} from "react-router-dom";

import GeneralSettings from './general';
import SecuritySettings from './security';
import NetworkSettings from "./network";
import MediaSettings from './media';
import VideoCardSettings from "./videocards";
import FTSettings from './file-transfer';
import IntercomSettings from './intercom';

import {licensePropTypes} from "../../../utils/models-prop-types";

import {canSetIntercom} from "../../../misc/license.selectors";

const propTypes = {
  license: licensePropTypes,
  canSetIntercom: PropTypes.bool.isRequired,
};


class SettingsPage extends Component {

  constructor(props){
    super(props);

    this.matchingUrl = this.matchingUrl.bind(this);
  }

  matchingUrl(pattern){
    return this.props.location.pathname.indexOf(pattern) !== -1;
  }

  render() {

    const {
      license,
      canSetIntercom
    } = this.props;

    return (
        <div className="settings">
          <Container>
            <Row>
              <Col className="at-least-medium"
                   xs={{ offset: 0, size: 0 }}
                   md={{ offset: 0, size: 3 }}>
                <Nav className="aw-nav"
                     pills
                     vertical>
                  <NavItem>
                    <NavLink id="settings_general_navLink" className="aw-nav-link" tag={Link} to="/settings/general" active={this.matchingUrl('/general')}>
                      <Translate id="genericLabel.GENERAL.text"/>
                    </NavLink>
                    <NavLink id="settings_security_navLink" className="aw-nav-link" tag={Link} to="/settings/security" active={this.matchingUrl('/security')}>
                      <Translate id="genericLabel.SECURITY.text"/>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink id="settings_network_navLink" className="aw-nav-link" tag={Link} to="/settings/network" active={this.matchingUrl('/network')}>
                      <Translate id="genericLabel.NETWORK.text"/>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink id="settings_media_navLink" className="aw-nav-link" tag={Link} to="/settings/media" active={this.matchingUrl('/media')}>
                      <Translate id="genericLabel.MEDIA.text"/>
                    </NavLink>
                  </NavItem>
                  {license != null && license.physicalOutput > 0 &&
                  <NavItem>
                    <NavLink id="settings_videoCard_navLink" className="aw-nav-link" tag={Link} to="/settings/video-cards" active={this.matchingUrl('/video-cards')}>
                      <Translate id="genericLabel.VIDEO_CARDS.text"/></NavLink>
                  </NavItem>
                  }
                  {license != null && license.FTPPush > 0 &&
                  <NavItem>
                    <NavLink id="settings_fileTransfer_navLink" className="aw-nav-link" tag={Link} to="/settings/file-transfer" active={this.matchingUrl('/file-transfer')}>
                      <Translate id="genericLabel.FILE_TRANSFER.text"/>
                    </NavLink>
                  </NavItem>
                  }
                  {canSetIntercom &&
                  <NavItem>
                    <NavLink id="settings_intercom_navLink" className="aw-nav-link" tag={Link} to="/settings/intercom" active={this.matchingUrl('/intercom')}>
                      <Translate id="genericLabel.INTERCOM.text"/>
                    </NavLink>
                  </NavItem>
                  }
                </Nav>
              </Col>
              <Col>
                <Switch>
                  <Route exact path="/settings/general" component={GeneralSettings}/>
                  <Route path="/settings/security" component={SecuritySettings}/>
                  <Route path="/settings/network" component={NetworkSettings}/>
                  <Route path="/settings/media" component={MediaSettings}/>
                  <Route path="/settings/video-cards" component={VideoCardSettings}/>
                  <Route exact path="/settings/file-transfer" component={FTSettings}/>
                  <Route exact path="/settings/intercom" component={IntercomSettings}/>
                  <Redirect from="/settings*" to="/settings/general"/>
                </Switch>
              </Col>
            </Row>
          </Container>
        </div>
    );
  }
}

SettingsPage.propTypes = propTypes;

const mapStateToProps = (state) => {
  return {
    license: state.license.details,
    canSetIntercom: canSetIntercom(state)
  };
};



//export default withRouter(SettingsPage);
export default withRouter(connect(mapStateToProps)(SettingsPage));