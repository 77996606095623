import PropTypes from "prop-types";
import React from 'react';
import {Translate, withLocalize} from "react-localize-redux";
import {Button, Form, FormFeedback, FormGroup, Input, Label} from "reactstrap";
import {Formik} from "formik";

import {isHostnameValid} from "../../../../utils/string-utils";

const propTypes = {
  config: PropTypes.shape({
    hostname: PropTypes.string
  }).isRequired,
  onSubmit: PropTypes.func.isRequired
};

const AdvancedNetworkSettingsForm = (props) => {

  const { config } = props;
  if(!config){
    return null;
  }

  const handleSubmit = (values, { resetForm }) => {
    let data = { ...values };
    props.onSubmit(data);
    resetForm();
  };

  const handleValidation = (values) => {
    const errors = {};

    if (!isHostnameValid(values.hostname)){
      errors.hostname = 'genericLabel.INVALID_FORMAT.text';
    }

    return errors;
  };


  return (
    <Formik initialValues={{
              hostname: config.hostname ? config.hostname : ''
            }}
            validate={ handleValidation }
            validateOnBlur={false}
            validateOnChange={true}
            onSubmit={ handleSubmit }>
      {({
          values,
          errors,
          dirty,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue
          /* and other goodies */
        }) => {
          return (
            <Form onSubmit={ handleSubmit }>

              <FormGroup>
                <Label for="hostname">
                  <Translate id="genericLabel.HOSTNAME.text"/>
                </Label>
                <Input type="text"
                      name="hostname"
                      id="network_advanced_hostname"
                      invalid={errors.hostname !== undefined}
                      value={values.hostname}
                      onChange={handleChange}/>
                <FormFeedback>
                  <Translate id={errors.hostname}/>
                </FormFeedback>
              </FormGroup>

              <FormGroup className="buttons">
                <Button id="network_advanced_saveButton"
                        color="primary"
                        disabled={isSubmitting || !dirty}
                        type="submit">
                  <Translate id="genericLabel.SAVE.text"/>
                </Button>
              </FormGroup>
            </Form>
          )
        }
      }
    </Formik>
  )
};

AdvancedNetworkSettingsForm.propTypes = propTypes;

export default withLocalize(AdvancedNetworkSettingsForm);