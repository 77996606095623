import PropTypes from "prop-types";
import React from 'react';
import {connect} from 'react-redux';
import {Translate} from "react-localize-redux";
import {Link} from "react-router-dom";
import {Nav, NavItem, NavLink} from "reactstrap";
import {Redirect, Route, Switch, withRouter} from "react-router";

import FormCleaner from "./form-cleaner";
import FormRename from "./form-rename";
import FormTranscoder from "./form-transcoder";
import {
  updateMediaFileCleanerSettings,
  updateMediaReceivedFilesSettings,
  updateMediaRecordedFilesSettings,
  updateMediaRenameFilesSettings,
} from "./media.settings.actions";
import { getAvailableReceivedFileTranscoder } from "../../../../misc/capabilities.selectors";

const propTypes = {
  callUpdateMediaReceivedFilesSettings: PropTypes.func.isRequired,
  callUpdateMediaRecordedFilesSettings: PropTypes.func.isRequired,
  callUpdateMediaFileCleanerSettings: PropTypes.func.isRequired,
  callUpdateMediaRenameFilesSettings: PropTypes.func.isRequired
};

const MediaSettings = (props) => {

  const {
    location,
    receivedFile,
    receivedFileTranscoderCapabilities,

    recordedFile,
    recordedFileTranscoderCapabilities,

    mediaCleaner,

    renameFile,

    callUpdateMediaReceivedFilesSettings,
    callUpdateMediaRecordedFilesSettings,
    callUpdateMediaFileCleanerSettings,
    callUpdateMediaRenameFilesSettings
  } = props;

  const hasPath = (path) => {
    return location.pathname.includes(path);
  };

  return (
    <div className="settings-section">
      <div className="header">
        <div className="title">
          <Translate id="genericLabel.MEDIA.text"/>
        </div>
      </div>
      <Nav tabs>
        <NavItem>
          <NavLink id="settings_received_navLink"
                   active={hasPath('/received')}
                   className="aw-nav-link"
                   tag={Link}
                   to="/settings/media/received">
            <Translate id="genericLabel.RECEIVED_FILES.text"/>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink id="settings_recorded_navLink"
                   active={hasPath('/recorded')}
                   className="aw-nav-link"
                   tag={Link}
                   to="/settings/media/recorded">
            <Translate id="genericLabel.RECORDED_FILES.text"/>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink id="settings_renamer_navLink"
                   active={hasPath('/rename')}
                   className="aw-nav-link"
                   tag={Link}
                   to="/settings/media/rename">
            <Translate id="genericLabel.RENAME_FILES.text"/>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink id="settings_cleaner_navLink"
                   active={hasPath('/cleaner')}
                   className="aw-nav-link"
                   tag={Link}
                   to="/settings/media/cleaner">
            <Translate id="genericLabel.CLEANER.text"/>
          </NavLink>
        </NavItem>
      </Nav>
      <div className="content with-menu pretty-scroll">
        <Switch>
          <Route exact
                 path="/settings/media/received"
                 component={(props) => {
                   if(!receivedFile || !receivedFileTranscoderCapabilities){
                     return null;
                   }
                   return (
                     <FormTranscoder { ...props }
                                     config={receivedFile}
                                     transcoderCapabilities={receivedFileTranscoderCapabilities}
                                     onSubmit={callUpdateMediaReceivedFilesSettings}
                                     type={"received"}/>
                   )
                 }}/>
          <Route exact
                 path="/settings/media/recorded"
                 component={(props) => {
                   if(!recordedFile || !recordedFileTranscoderCapabilities){
                     return null;
                   }
                   return (
                     <FormTranscoder { ...props }
                                     config={recordedFile}
                                     transcoderCapabilities={recordedFileTranscoderCapabilities}
                                     onSubmit={callUpdateMediaRecordedFilesSettings}
                                     type={"recorded"}/>
                   )
                 }}/>
          <Route exact
                 path="/settings/media/rename"
                 component={(props) => {
                   if(!renameFile){
                     return null;
                   }
                   return (
                     <FormRename { ...props }
                                  config={renameFile}
                                  onSubmit={callUpdateMediaRenameFilesSettings}/>
                   )
                 }}/>
          <Route exact
                 path="/settings/media/cleaner"
                 component={(props) => {
                   if(!mediaCleaner){
                     return null;
                   }
                   return (
                     <FormCleaner { ...props }
                                  config={mediaCleaner}
                                  onSubmit={callUpdateMediaFileCleanerSettings}/>
                   )
                 }}/>
          <Redirect from="/settings/media*" to="/settings/media/received"/>
        </Switch>
      </div>
    </div>
  );
};

MediaSettings.propTypes = propTypes;

const mapStateToProps = (state) => {
  return {
    receivedFile: state.config && state.config.fileTranscoder ? state.config.fileTranscoder.receivedFile : null,
    recordedFile: state.config && state.config.fileTranscoder ? state.config.fileTranscoder.recordedFile : null,
    receivedFileTranscoderCapabilities: getAvailableReceivedFileTranscoder(state),
    recordedFileTranscoderCapabilities: getAvailableReceivedFileTranscoder(state),
    mediaCleaner: state.config ? state.config.mediaCleaner : null,
    renameFile: state.config && state.config.fileTranscoder ? state.config.fileTranscoder.renameFile : null
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    callUpdateMediaReceivedFilesSettings: (data) => dispatch(updateMediaReceivedFilesSettings(data)),
    callUpdateMediaRecordedFilesSettings: (data) => dispatch(updateMediaRecordedFilesSettings(data)),
    callUpdateMediaFileCleanerSettings: (data) => dispatch(updateMediaFileCleanerSettings(data)),
    callUpdateMediaRenameFilesSettings: (data) => dispatch(updateMediaRenameFilesSettings(data)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MediaSettings));