import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withLocalize} from "react-localize-redux";
import {Button, Container, Input, ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle, InputGroup, InputGroupAddon} from "reactstrap";
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";

import PlayerModal from "../../common/player-modal";
import {
  changeFilesLayout,
  deleteStudioFiles,
  previewVideoFile,
  renameStudioFile,
  uploadDirectoryContent
} from './files.actions';
import {USER_ROLE_ADMIN, USER_ROLE_VIEWER, FILE_LAYOUT_INLINE, LICENSE_STATUS_VALID} from "../../../constants";

import {filePropTypes} from "../../../utils/models-prop-types";
import FileLayoutSelector from "../../common/file-explorer/layout-selector";
import FileExplorer from "../../common/file-explorer";
import Breadcrumbs from "../../common/file-explorer/breadcrumbs";
import {inputPlaybackStart} from "../dashboard/dashboard.actions";
import { addLog } from '../../../misc/socket.actions';

const propTypes = {
  hasAdminLevel: PropTypes.bool,
  hasViewerLevel: PropTypes.bool,
  canPlayback: PropTypes.bool.isRequired,
  currentFiles : PropTypes.arrayOf(filePropTypes),
  currentPath : PropTypes.string,
  currentBreadcrumbs : PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    path: PropTypes.string
  })),
  previewFile: filePropTypes,

  callUploadDirectoryContent: PropTypes.func.isRequired,
  callRenameStudioFile: PropTypes.func.isRequired,
  callDeleteStudioFiles: PropTypes.func.isRequired,
  callPreviewVideo: PropTypes.func.isRequired,
  callClosePreviewVideo: PropTypes.func.isRequired,
  callPlayback: PropTypes.func.isRequired
};

class FilesPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      filterText: '',
      selectionMode: false,
      selectedFiles: [],
      sort: {
        type: 'numeric',
        reverse: true
      },
      dropdownOpen: false
    };

    this.handleFilterTextChange = this.handleFilterTextChange.bind(this);
    this.toggleSelectionMode = this.toggleSelectionMode.bind(this);
    this.sortBy = this.sortBy.bind(this);
    this.toggleDropdownOpen = this.toggleDropdownOpen.bind(this);
  }

  sortBy(type, reverse) {
    console.log('sort', type, reverse)
    this.setState({
      sort: {
        type: type,
        reverse: reverse
      }
    });
  }

  toggleDropdownOpen(){
    this.setState((state) => {
      return {
        ...state,
        dropdownOpen: !state.dropdownOpen
      }
    });
  }

  componentDidMount(){
    this.props.callUploadDirectoryContent(this.props.currentPath);
  }

  componentDidUpdate(prevProps, prevState){
    if (prevProps.currentFiles !== this.props.currentFiles
      || prevState.filterText !== this.state.filterText) {
      // Reset selected mode
      this.setState ({
        selectionMode: false,
        selectedFiles: []
      });
      // Reset filter
      if (prevProps.currentFiles !== this.props.currentFiles) {
        this.setState({
          filterText: ''
        });
      }
    }

  }

  handleFilterTextChange(e) {
    this.setState({
      filterText: e.target.value
    });
  }

  handleSelectionChange(element, checked) {
    // Add a selected file
    if (checked) {
      this.setState(prevState => ({
        selectedFiles: [
          ...prevState.selectedFiles,
          element
        ]
      }))
    }
    // Remove a selected file
    else {
      this.setState(prevState => ({
        selectedFiles: this.state.selectedFiles.filter(item => item !== element)
      }))
    }
  }

  toggleSelectionMode() {
    this.setState ({
      selectionMode: !this.state.selectionMode,
      selectedFiles: []
    });
  }


  render() {
    const { hasAdminLevel, hasViewerLevel, currentBreadcrumbs, currentFiles, currentPath, layout, loading, translate } = this.props;
    const { filterText, selectedFiles } = this.state;
    return (
      <div className="files">
        <Container>
          <div className="header">
            <div className="title">
              <Breadcrumbs breadcrumbs={currentBreadcrumbs}
                           onSelect={this.props.callUploadDirectoryContent}/>
            </div>
            <div className="display-options">
              { hasAdminLevel && this.state.selectionMode && layout === FILE_LAYOUT_INLINE &&
              <Button id="fileExplorer_selectMultiple_deleteButton"
                      className="basic"
                      disabled={ this.state.selectedFiles.length === 0 }
                      onClick={() => this.props.callDeleteStudioFiles(this.state.selectedFiles)}>
                <AWIcon name="delete"/>
              </Button>
              }
              { hasAdminLevel && layout === FILE_LAYOUT_INLINE &&
              <Button id="fileExplorer_selectMultiple"
                      className="basic"
                      onClick={this.toggleSelectionMode}>
                <AWIcon name="select_multiple"
                      className={this.state.selectionMode ? 'primary' : undefined}/>
              </Button>
              }

              <InputGroup>
                <ButtonDropdown id="fileExplorer_filter"
                                isOpen={ this.state.dropdownOpen }
                                toggle={ this.toggleDropdownOpen }>
                  <DropdownToggle>
                    { this.state.sort.type === 'numeric' ? translate('genericLabel.DATE.text') : translate('genericLabel.NAME.text') }
                  </DropdownToggle>

                  <DropdownMenu>
                      <DropdownItem id="fileExplorer_filter_numeric"
                                    key='numeric'
                                    active={this.state.sort.type === 'numeric'}
                                    onClick={() => this.sortBy('numeric', this.state.sort.reverse)}>
                        {translate('genericLabel.DATE.text')}
                      </DropdownItem>
                      <DropdownItem id="fileExplorer_filter_alphabetical"
                                    key='alpha'
                                    active={this.state.sort.type === 'alpha'}
                                    onClick={() => this.sortBy('alpha', this.state.sort.reverse)}>
                        {translate('genericLabel.NAME.text')}
                      </DropdownItem>
                  </DropdownMenu>
                </ButtonDropdown>
                <InputGroupAddon addonType="append">
                  <Button id="fileExplorer_filter_sortDirection"
                    onClick={() => this.sortBy(this.state.sort.type, !this.state.sort.reverse)}>
                    <AWIcon name={this.state.sort.reverse ? 'sort_asc' : 'sort_desc'}/>
                  </Button>
                </InputGroupAddon>
              </InputGroup>

              <Input id="fileExplorer_search"
                     className="search"
                     onChange={this.handleFilterTextChange}
                     placeholder={translate('genericLabel.SEARCH.text')}
                     value={this.state.filterText}/>
              <FileLayoutSelector onChange={this.props.callChangeFilesLayout}
                                  selected={layout}/>
            </div>
          </div>
          <div className="content pretty-scroll">
            <FileExplorer hasAdminLevel={hasAdminLevel}
                          hasViewerLevel={hasViewerLevel}
                          enableEdition={true}
                          enableDownload={true}
                          enableFTPPush={true}
                          enablePlayback={true}
                          enablePreview={true}
                          enableSelection={this.state.selectionMode}
                          files={currentFiles}
                          layout={layout}
                          loading={loading}
                          onRename={this.props.callRenameStudioFile}
                          onDelete={this.props.callDeleteStudioFiles}
                          onDisplayDirectoryContent={this.props.callUploadDirectoryContent}
                          onPlayback={this.props.callPlayback}
                          onPreviewVideo={this.props.callPreviewVideo}
                          onDeselect={(element) => this.handleSelectionChange(element, false)}
                          onSelect={(element) => this.handleSelectionChange(element, true)}
                          path={currentPath}
                          search={filterText}
                          selectedFiles={selectedFiles}
                          sort={this.state.sort}
                          addLog={this.props.callAddLog}/>
          </div>
        </Container>
        <PlayerModal file={ this.props.previewFile }
                     onClose={ this.props.callClosePreviewVideo }/>
      </div>
    );
  }
}

FilesPage.propTypes = propTypes;


const mapStateToProps = (state, ownProps) => {
  const { breadcrumbs, files, layout, loading, path, previewFile } = state.files;
  const { status, details } = state.license;
  return {
    hasAdminLevel: state.identity.role === USER_ROLE_ADMIN,
    hasViewerLevel: state.identity.role === USER_ROLE_VIEWER,
    canPlayback: status === LICENSE_STATUS_VALID && (details && details.playback === 1),
    currentFiles : files,
    currentPath : path,
    currentBreadcrumbs: breadcrumbs,
    layout,
    loading,
    previewFile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    callChangeFilesLayout: (layout) => dispatch(changeFilesLayout(layout)),
    callUploadDirectoryContent: (path) => dispatch(uploadDirectoryContent(path)),
    callRenameStudioFile: (oldPath, newPath) => dispatch(renameStudioFile(oldPath, newPath)),
    callDeleteStudioFiles: (items) => dispatch(deleteStudioFiles(items)),
    callPreviewVideo: (item) => dispatch(previewVideoFile(item)),
    callClosePreviewVideo: () => dispatch(previewVideoFile(null)),
    callPlayback: (input, item) => dispatch(inputPlaybackStart(input, item)),
    callAddLog:(input) => dispatch(addLog(input))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(FilesPage));