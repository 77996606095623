import {
  PASSWORD_SAMBA_UPDATE,
  PASSWORD_UPDATE,
  SETTINGS_SECURITY_UNBLOCK_ACCOUNTS,
  SETTINGS_SECURITY_UPDATE,
  SETTINGS_LDAP_UPDATE
} from "../../../../constants";

export const updateUserPassword = (password, enable, sessionTimeout) => {
  return {
    type: PASSWORD_UPDATE,
    role: 'user',
    new: password,
    confirm: password,
    enable: enable,
    sessionTimeout: sessionTimeout
  }
};

export const updateAdminPassword = (oldPassword, newPassword, sessionTimeout) => {
  return {
    type: PASSWORD_UPDATE,
    role: 'admin',
    old: oldPassword,
    new: newPassword,
    confirm: newPassword,
    sessionTimeout: sessionTimeout
  }
};

export const updateViewerPassword = (password, enable, sessionTimeout) => {
  return {
    type: PASSWORD_UPDATE,
    role: 'viewer',
    new: password,
    confirm: password,
    enable: enable,
    sessionTimeout: sessionTimeout
  }
};

export const updateSambaFTPPassword = (password) => {
  return {
    type: PASSWORD_SAMBA_UPDATE,
    new: password,
    confirm: password
  }
};

export const updateSecuritySettings = (data) => {
  return {
    type: SETTINGS_SECURITY_UPDATE,
    data
  }
};

export const updateLdapSettings = (data) => {
  return {
    type: SETTINGS_LDAP_UPDATE,
    data
  }
};

export const unblockAccounts = () => {
  return {
    type: SETTINGS_SECURITY_UNBLOCK_ACCOUNTS
  }
};