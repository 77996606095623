import React, {Fragment} from "react";
import {Translate} from "react-localize-redux";
import {inputPropTypes} from "../../../../../utils/models-prop-types";
import {formatMilliToSeconds} from "../../../../../utils/global-utils";
import {
  LIVE_STATUS_ON
} from "../../../../../constants";


const propTypes = {
  input: inputPropTypes.isRequired
};

const LiveInfo = (props) => {
  const liveInfo = props.input.deviceLiveInfo;
  const deviceLiveStatus = props.input.deviceLiveStatus;
  if(!liveInfo){
    return null;
  }
  const selectedLiveProfile = props.input.deviceLiveProfiles ? props.input.deviceLiveProfiles.find(liveProfile => liveProfile.id === props.input.deviceLiveProfileID) : null;
  return (
      <div className="live-info">
        <div className="title">
          <label className="text-secondary"><Translate id="genericLabel.GENERAL_INFORMATION.text"/></label>
        </div>
        <dl className="row no-gutters rounded">
          <dt className="col-sm-6"><Translate id="genericLabel.LIVE_PROFILE.text"/></dt>
          <dd className="col-sm-6">{ selectedLiveProfile ? selectedLiveProfile.name : null }</dd>

          { liveInfo.videoInput &&
          <Fragment>
            <dt className="col-sm-6"><Translate id="genericLabel.VIDEO_INPUT.text"/></dt>
            <dd className="col-sm-6">{ liveInfo.videoInput }</dd>
          </Fragment>
          }

          { liveInfo.videoFrequency &&
          <Fragment>
            <dt className="col-sm-6"><Translate id="genericLabel.VIDEO_FREQUENCY.text"/></dt>
            <dd className="col-sm-6">{ liveInfo.videoFrequency }</dd>
          </Fragment>
          }

          { liveInfo.resolution &&
          <Fragment>
            <dt className="col-sm-6"><Translate id="genericLabel.RESOLUTION.text"/></dt>
            <dd className="col-sm-6">{ liveInfo.resolution }</dd>
          </Fragment>
          }

          {liveInfo.delay && deviceLiveStatus === LIVE_STATUS_ON &&
          <Fragment>
            <dt className="col-sm-6"><Translate id="genericLabel.LATENCY.text"/></dt>
            <dd className="col-sm-6">{ formatMilliToSeconds(liveInfo.delay) }</dd>
          </Fragment>
          }

          {liveInfo.videoBitrateMode && deviceLiveStatus === LIVE_STATUS_ON &&
          <Fragment>
            <dt className="col-sm-6"><Translate id="genericLabel.VIDEO_BITRATE_MODE.text"/></dt>
            <dd className="col-sm-6">{ liveInfo.videoBitrateMode }</dd>
          </Fragment>
          }

          { liveInfo.audioChannelMode && deviceLiveStatus === LIVE_STATUS_ON &&
          <Fragment>
            <dt className="col-sm-6"><Translate id="genericLabel.AUDIO_MODE.text"/></dt>
            <dd className="col-sm-6">{liveInfo.audioChannelMode}</dd>
          </Fragment>
          }
        </dl>
      </div>
  );
};

LiveInfo.propTypes = propTypes;

export default LiveInfo;