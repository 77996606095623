import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Sidebar from "react-sidebar";
import {Helmet} from "react-helmet";

import BannerSection from './banner-section';
import Footer from '../common/footer';
import Navbar from '../common/navbar';
import {logOut} from '../pages/login/login.actions';
import SidebarMenu from "../common/sidebar";
import {mobileDisplayOutputs} from "../pages/dashboard/dashboard.actions";
import LicenseModal from "../common/license-modal";
import {openLicenseModal} from "../../misc/license.actions";
import {openLanguageModal} from "../../misc/language.actions";
import LanguageModal from "../common/language-modal";
import HelpModal from "../common/help-modal";
import ServerWaitingModal from "../common/server-waiting-modal";
import {refreshPage} from "../../misc/streamhub.actions"
import PasswordModal from "../common/password-modal";

const propTypes = {
  apiKey: PropTypes.string,
  children: PropTypes.element.isRequired,
  identity: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  outputsDisplayed: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
  openLicenseModal: PropTypes.func.isRequired,
  streamhubName: PropTypes.string,
  callMobileDisplayOutputs: PropTypes.func.isRequired,
  serverWaitingModalOpened: PropTypes.bool.isRequired,
  serverWaitingReason: PropTypes.string
};

class DefaultLayout extends Component {

  constructor(props) {
    super(props);
    this.state = {
      sidebarOpened: false
    };
    this.handleSidebarToggle = this.handleSidebarToggle.bind(this);
  }

  handleSidebarToggle() {
    this.setState({ sidebarOpened: !this.state.sidebarOpened });
  }

  render() {
    const { apiKey, callMobileDisplayOutputs, children, helpLink, identity, location, logout, openLicenseModal, openLanguageModal, outputsDisplayed, streamhubName, serverWaitingModalOpened,
      serverWaitingReason } = this.props;
    return (
        <Sidebar
            sidebar={
              <SidebarMenu apiKey={apiKey}
                           helpLink={helpLink}
                           location={location}
                           onCloseMenu={ this.handleSidebarToggle }
                           onLicenseDetailsRequested={openLicenseModal}
                           onLogoutRequested={logout} />
            }
            open={this.state.sidebarOpened}
            onSetOpen={ this.handleSidebarToggle }
            styles={{ sidebar: { width: '300px', zIndex: 1000 } }}>
          <div id="default-layout">
            <Helmet>
              { streamhubName && <title>{`${streamhubName}`}</title> }
            </Helmet>
            <Navbar apiKey={apiKey}
                    helpLink={helpLink}
                    identity={identity}
                    location={location}
                    outputsDisplayed={outputsDisplayed}
                    onBurgerMenuClick={ this.handleSidebarToggle }
                    onOutputsButtonClick={ () => callMobileDisplayOutputs(!outputsDisplayed) }
                    onLanguageChangeRequested={openLanguageModal}
                    onLicenseDetailsRequested={openLicenseModal}
                    onLogoutRequested={logout}/>
            <div className="content">
            {children}
            </div>
            { serverWaitingModalOpened &&
            <ServerWaitingModal onRefresh={this.props.callRefreshPage}
                                reason={ serverWaitingReason }/>
            }
            <PasswordModal/>
            <LicenseModal/>
            <LanguageModal/>
            <HelpModal/>
            <BannerSection/>
            <Footer/>
          </div>
        </Sidebar>
    )
  }
}

DefaultLayout.propTypes = propTypes;

const mapStateToProps = (state) => {
  const { identity } = state;
  return {
    identity,
    apiKey: state.config.APIkey,
    helpLink: state.streamhub.helpLink,
    outputsDisplayed: state.dashboard.mobileOutputsVisible,
    streamhubName: state.streamhub.name,
    serverWaitingModalOpened: state.streamhub.serverWaitingModalOpened,
    serverWaitingReason: state.streamhub.serverWaitingReason
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logOut()),
    openLicenseModal: () => dispatch(openLicenseModal(true)),
    openLanguageModal: () => dispatch(openLanguageModal(true)),
    callMobileDisplayOutputs: (display) => dispatch(mobileDisplayOutputs(display)),
    callRefreshPage: () => {
      dispatch(refreshPage());
    },
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout);