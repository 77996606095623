import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import { Button, ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";
import CopyToClipboardButton from "../../../../common/copy-to-clipboard-button/index";
import {
  INPUT_TYPE_IP,
  INTERCOM_STATUS_ERROR,
  INTERCOM_STATUS_NOT_SUPPORTED,
  INTERCOM_STATUS_OFF,
  INTERCOM_STATUS_ON,
  INTERCOM_STATUS_STARTING,
  LIVE_STATUS_CONNECTING,
  LIVE_STATUS_ERROR,
  LIVE_STATUS_OFF,
  LIVE_STATUS_ON,
  RECORD_STATUS_OFF,
  RECORD_STATUS_ON,
  RECORD_STATUS_STARTING,
  RECORD_STATUS_CODEC_NOT_SUPPORTED,
  RECORD_STATUS_ERROR,
  FORWARD_STATUS_FORWARDING,
  STATUS_OFF,
  DASHBOARD_LAYOUT_FULLSIZE,
  DASHBOARD_LAYOUT_GRID,
  DASHBOARD_LAYOUT_INLINE,
  DEVICE_TYPE_STUDIO,
  SST,
  WEBRTC,
  NDI
} from "../../../../../constants";
import StatusButton from '../../../../common/status-button/index';
import { inputIpProfilePropTypes } from '../../../../../utils/models-prop-types';

const propTypes = {
  hasViewerLevel: PropTypes.bool,
  input: PropTypes.object.isRequired,
  ipProfile: inputIpProfilePropTypes,
  layout: PropTypes.oneOf([DASHBOARD_LAYOUT_GRID, DASHBOARD_LAYOUT_INLINE, DASHBOARD_LAYOUT_FULLSIZE]),
  onIntercomStart: PropTypes.func.isRequired,
  onIntercomStop: PropTypes.func.isRequired,
  onRecordStart: PropTypes.func.isRequired,
  onRecordStop: PropTypes.func.isRequired,
  onRemoteLiveStart: PropTypes.func.isRequired,
  onRemoteLiveStop: PropTypes.func.isRequired,
  onRemoteForwardStop: PropTypes.func.isRequired,
  onRemoteProfileSwitch: PropTypes.func.isRequired,
  onStatusButtonChange: PropTypes.func.isRequired,
  onShowDetails: PropTypes.func.isRequired,
  onCloseDetails: PropTypes.func.isRequired,
  orionConnectionStatus: PropTypes.bool.isRequired,
  liveGuestHasIntercom: PropTypes.bool
};

class Controls extends Component {

  constructor(props){
    super(props);

    this.toggle = this.toggle.bind(this);

    this.state = {
      dropdownOpen: false
    }
  }

  toggle(){
    this.setState((state) => {
      return {
        ...state,
        dropdownOpen: !state.dropdownOpen
      }
    });
  }

  render(){
    const {
      input,
      ipProfile,
      layout,
      onIntercomStart,
      onIntercomStop,
      onRecordStart,
      onRecordStop,
      onRemoteLiveStart,
      onRemoteLiveStop,
      onRemoteForwardStop,
      onRemoteProfileSwitch,
      onStatusButtonChange,
      onShowDetails,
      onCloseDetails,
      configInputProtocol,
      orionConnectionStatus,
      hasViewerLevel,
      liveGuestHasIntercom
    } = this.props;

    const protocol = ipProfile ? ipProfile.protocol : SST;
    const { deviceType, deviceLiveStatus, deviceLiveProfiles, deviceLiveProfileID, deviceRecordStatus, intercomStatus, recordStatus, type, deviceForwardStatus, status } = input;
    const remoteProfiles = deviceLiveProfiles;
    const remoteSelectedProfileId = deviceLiveProfileID;

    if( status === STATUS_OFF && ipProfile === null) {
      return null;
    }
    if( status === STATUS_OFF && ipProfile != null) {
      return (
        <Fragment>
          <CopyToClipboardButton ipProfile={ipProfile}/>
          <StatusButton onChange={(status) => {
              onStatusButtonChange(status)
            }}
            id={"dashboard_input_"+ipProfile.inputId}
            disabled={hasViewerLevel || (configInputProtocol && configInputProtocol.enable) || (protocol === WEBRTC && !orionConnectionStatus)}
            status={status}/>
        </Fragment>
      )
    }
    // Input SST menu from StreamHub (Bugs #15696)
    if(deviceType === DEVICE_TYPE_STUDIO){
      return (
        <Button className="basic icon"
                onClick={ () => layout === DASHBOARD_LAYOUT_FULLSIZE ? onCloseDetails(input) : onShowDetails(input) }>
          <AWIcon name={layout === DASHBOARD_LAYOUT_FULLSIZE ? 'shrink' : 'expand'}/>
        </Button>
      );
    }
    if(type === INPUT_TYPE_IP){
      return (
          <Fragment>
            { protocol === WEBRTC && liveGuestHasIntercom &&  <Button className="basic icon"
              color={ intercomStatus === INTERCOM_STATUS_ON ? 'success' : (intercomStatus === INTERCOM_STATUS_STARTING ? 'info' : 'secondary') }
              active={ intercomStatus === INTERCOM_STATUS_ON || intercomStatus === INTERCOM_STATUS_STARTING}
              disabled={ hasViewerLevel || intercomStatus === INTERCOM_STATUS_NOT_SUPPORTED || intercomStatus === INTERCOM_STATUS_ERROR }
              onClick={ () => intercomStatus === INTERCOM_STATUS_OFF ? onIntercomStart(input) : onIntercomStop(input) }>
              <AWIcon name="headset"/>
            </Button>}
            <CopyToClipboardButton ipProfile={ipProfile}/>
            { protocol !== NDI &&
            <Button className="basic icon"
                    color={ recordStatus === RECORD_STATUS_ON ? 'success' : (recordStatus === RECORD_STATUS_STARTING ? 'info' : 'secondary') }
                    active={ recordStatus === RECORD_STATUS_ON || recordStatus === RECORD_STATUS_STARTING}
                    disabled={ hasViewerLevel || recordStatus === RECORD_STATUS_CODEC_NOT_SUPPORTED || recordStatus === RECORD_STATUS_ERROR }
                    onClick={ () => recordStatus === RECORD_STATUS_OFF ? onRecordStart(input) : onRecordStop(input) }>
              <AWIcon name="record"/>
            </Button>
            }
            <StatusButton id={"dashboard_input_"}
                          onChange={onStatusButtonChange}
                          active={status !== STATUS_OFF}
                          disabled={hasViewerLevel || (configInputProtocol && !configInputProtocol.enable && status !== STATUS_OFF)}
                          status={status}/>
            <Button id="dasboard_input_shrinkExpand"
                    className="basic icon"
                    onClick={ () => layout === DASHBOARD_LAYOUT_FULLSIZE ? onCloseDetails(input) : onShowDetails(input) }>
              <AWIcon name={layout === DASHBOARD_LAYOUT_FULLSIZE ? 'shrink' : 'expand'}/>
            </Button>
          </Fragment>
      );
    }
    return (
        <Fragment>
          <Button className="basic icon"
                  color={ intercomStatus === INTERCOM_STATUS_ON ? 'success' : (intercomStatus === INTERCOM_STATUS_STARTING ? 'info' : 'secondary') }
                  active={ intercomStatus === INTERCOM_STATUS_ON || intercomStatus === INTERCOM_STATUS_STARTING}
                  disabled={ hasViewerLevel || intercomStatus === INTERCOM_STATUS_NOT_SUPPORTED || intercomStatus === INTERCOM_STATUS_ERROR }
                  onClick={ () => intercomStatus === INTERCOM_STATUS_OFF ? onIntercomStart(input) : onIntercomStop(input) }>
            <AWIcon name="headset"/>
          </Button>
          { deviceForwardStatus !== FORWARD_STATUS_FORWARDING &&
          <Button className="basic icon"
                  color={ recordStatus === RECORD_STATUS_ON ? 'success' : (recordStatus === RECORD_STATUS_STARTING ? 'info' : 'secondary') }
                  active={ recordStatus === RECORD_STATUS_ON || recordStatus === RECORD_STATUS_STARTING}
                  disabled={ hasViewerLevel || recordStatus === RECORD_STATUS_CODEC_NOT_SUPPORTED  || recordStatus === RECORD_STATUS_ERROR }
                  onClick={ () => recordStatus === RECORD_STATUS_OFF ? onRecordStart(input) : onRecordStop(input) }>
            <AWIcon name="record"/>
          </Button>
          }
          { deviceForwardStatus !== FORWARD_STATUS_FORWARDING &&
          <Button className="basic icon"
            color={ deviceLiveStatus === LIVE_STATUS_ON ? 'success' : (deviceLiveStatus === LIVE_STATUS_CONNECTING ? 'info' : 'secondary') }
            active={ deviceLiveStatus === LIVE_STATUS_ON || deviceLiveStatus === LIVE_STATUS_CONNECTING}
            disabled={ hasViewerLevel || (deviceRecordStatus !== RECORD_STATUS_OFF && deviceLiveStatus === LIVE_STATUS_OFF) || deviceLiveStatus === null}
            onClick={ () => deviceLiveStatus === LIVE_STATUS_OFF || deviceLiveStatus === LIVE_STATUS_ERROR ? onRemoteLiveStart(input) : onRemoteLiveStop(input) }>
            <AWIcon name={ deviceLiveStatus === LIVE_STATUS_OFF || deviceLiveStatus === LIVE_STATUS_ERROR ? 'play' : 'stop' }/>
          </Button>
          }
          { deviceForwardStatus !== FORWARD_STATUS_FORWARDING &&
          <ButtonDropdown isOpen={ this.state.dropdownOpen }
                          toggle={ this.toggle }
                          style={{marginLeft: ".5rem"}}>
            <DropdownToggle className="basic icon remote-profiles-toggle" disabled={ hasViewerLevel || remoteProfiles === null || (deviceLiveStatus !== LIVE_STATUS_OFF && deviceLiveStatus !== LIVE_STATUS_ERROR) }>
              <AWIcon name="caret_down"/>
            </DropdownToggle>
            { remoteProfiles && (deviceLiveStatus === LIVE_STATUS_OFF || deviceLiveStatus === LIVE_STATUS_ERROR) &&
            <DropdownMenu>
              { remoteProfiles.map((currentProfile) => (
                <DropdownItem key={currentProfile.id}
                                     active={currentProfile.id === remoteSelectedProfileId}
                                     onClick={() => onRemoteProfileSwitch(input, currentProfile.id)}>
                  {currentProfile.name}
                </DropdownItem>
              ))}
            </DropdownMenu>
            }
          </ButtonDropdown>
          }
          { deviceForwardStatus === FORWARD_STATUS_FORWARDING &&
          <Button className="basic icon"
                  color='success'
                  disabled={hasViewerLevel}
                  onClick={ () => onRemoteForwardStop(input) }>
            <AWIcon name='stop'/>
          </Button>
          }
          <Button className="basic icon"
                  onClick={ () => layout === DASHBOARD_LAYOUT_FULLSIZE ? onCloseDetails(input) : onShowDetails(input) }>
            <AWIcon name={layout === DASHBOARD_LAYOUT_FULLSIZE ? 'shrink' : 'expand'}/>
          </Button>
        </Fragment>
    );
  }
}

Controls.propTypes = propTypes;

export default Controls;