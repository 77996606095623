import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {withLocalize} from 'react-localize-redux';
import {XAxis, YAxis, LineSeries, VerticalBarSeries, XYPlot, HorizontalGridLines, VerticalGridLines, DiscreteColorLegend} from 'react-vis';
import AutoSizer from "react-virtualized-auto-sizer";
import { connect } from 'react-redux';
import DayJS from 'dayjs';

import '../../../../../../node_modules/react-vis/dist/style.css';
import {GRAPH_COLORS, GRAPH_GRID_COLOR, GRAPH_LEGEND_COLOR, GRAPH_MIN_WIDTH} from "../../../../../constants";

const propTypes = {
  inputId: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
  stats: PropTypes.shape({
    maxAudioLoss: PropTypes.number.isRequired,
    maxTotalBitrate: PropTypes.number,
    maxVideoLoss: PropTypes.number.isRequired,
    data: PropTypes.arrayOf(PropTypes.shape({
      timestamp: PropTypes.number.isRequired,
      totalBitrate: PropTypes.number.isRequired,
      videoBitrate: PropTypes.number.isRequired,
      videoLostPackets: PropTypes.number.isRequired,
      audioBitrate: PropTypes.number.isRequired,
      audioLostPackets: PropTypes.number.isRequired,
    })).isRequired
  })
};

const margins= {
  left: 50,
  right: 50,
  bottom: 18
};

const tickTotalX = 4;
const tickTotalY = 4;
const tickWidth = 0;

class Graph extends Component {

  constructor(props){
    super(props);

    this.toggleLegend = this.toggleLegend.bind(this);

    this.state = {
      legend: false
    };
  }

  toggleLegend() {
    this.setState({
      legend: !this.state.legend
    });
  }

  formatBitrate(value, index, scale, tickTotal){
    return `${scale.tickFormat(tickTotal, '~s')(value)}b`;
  }

  render(){
    if(!this.props.stats){
      return null;
    }
    return (
        <AutoSizer disableHeight>
          {({ width }) => {
            return width > GRAPH_MIN_WIDTH ?
              <div className="graph">
                <div className="base">
                  <XYPlot
                      getX={d => d.timestamp }
                      getY={d => d.totalBitrate }
                      xType="time"
                      yDomain={[0, this.props.stats.maxTotalBitrate + (parseInt(this.props.stats.maxTotalBitrate * 0.2))]}
                      height={135}
                      width={width}
                      margin={margins}>
                    <HorizontalGridLines style={{ stroke: GRAPH_GRID_COLOR }} tickTotal={tickTotalY}/>
                    <VerticalGridLines style={{ stroke: GRAPH_GRID_COLOR }} tickTotal={tickTotalX}/>
                    <XAxis style={{ line: {stroke: GRAPH_GRID_COLOR}, text: {stroke: GRAPH_LEGEND_COLOR}}} tickTotal={tickTotalX} tickFormat={v => `${DayJS(v).format('HH:mm')}`} tickSizeOuter={0} tickSizeInner={tickWidth}/>
                    <YAxis title={this.props.translate("genericLabel.BITRATE.text")} style={{ line: {stroke: GRAPH_GRID_COLOR}, text: {stroke: GRAPH_LEGEND_COLOR}}} tickTotal={tickTotalY} tickFormat={ this.formatBitrate } tickSizeOuter={0} tickSizeInner={tickWidth}/>
                    <LineSeries getNull={(d) => d.hidden !== true} data={this.props.stats.data} color={GRAPH_COLORS[0]}/>
                  </XYPlot>
                </div>
                <div className="overlay">
                  <XYPlot
                      getX={d => d.timestamp }
                      getY={d => d.videoLostPackets }
                      yDomain={[0, Math.max(this.props.stats.maxVideoLoss, this.props.stats.maxAudioLoss)]}
                      height={135}
                      width={width}
                      margin={margins}>
                    <YAxis style={{ line: {stroke: GRAPH_GRID_COLOR}, text: {stroke: GRAPH_LEGEND_COLOR}}} orientation="right" tickSizeOuter={0} tickSizeInner={tickWidth}/>
                    <VerticalBarSeries data={this.props.stats.data} color={GRAPH_COLORS[1]}/>
                  </XYPlot>
                </div>
                { this.state.legend &&
                  <div className="overlay">
                    <DiscreteColorLegend
                      width={width}
                      orientation="horizontal"
                      items={
                        this.props.stats.mpegtsUpMode ?
                        [
                          {title: this.props.translate("streamStats.STREAM_BITRATE.text"), color: GRAPH_COLORS[0], strokeWidth:10},
                          {title: this.props.translate("streamStats.STREAM_LOST.text"), color: GRAPH_COLORS[1], strokeWidth:10},
                        ] :
                        [
                          {title: this.props.translate("streamStats.STREAM_BITRATE.text"), color: GRAPH_COLORS[0], strokeWidth:10},
                          {title: this.props.translate("streamStats.VIDEO_LOST.text"), color: GRAPH_COLORS[1], strokeWidth:10},
                          {title: this.props.translate("streamStats.AUDIO_LOST.text"), color: GRAPH_COLORS[2], strokeWidth:10}
                        ]
                      }
                    />
                  </div>
                }

                <div className="overlay" onMouseOver={ this.toggleLegend } onMouseOut={ this.toggleLegend }>
                  <XYPlot
                      getX={d => d.timestamp }
                      getY={d => d.audioLostPackets }
                      yDomain={[0, Math.max(this.props.stats.maxVideoLoss, this.props.stats.maxAudioLoss)]}
                      height={135}
                      width={width}
                      margin={margins}>
                    <YAxis title={this.props.translate("genericLabel.LOST_PACKETS.text")} style={{ line: {stroke: GRAPH_GRID_COLOR}, text: {stroke: GRAPH_LEGEND_COLOR}}} orientation="right" tickSizeOuter={0} tickSizeInner={tickWidth}/>
                    <VerticalBarSeries data={this.props.stats.data} color={GRAPH_COLORS[2]}/>
                  </XYPlot>
                </div>
              </div> : null;
          }}
        </AutoSizer>
    );
  }
}

Graph.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
  return {
    stats: state.datastore.inputStreamStats[ownProps.inputId]
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(Graph));