import {LICENSE_STATUS_VALID} from "../constants";
import {createSelector} from "reselect";

const isLicenseValid = (state) => state.license.status === LICENSE_STATUS_VALID;
const getLicenseDetails = (state) => state.license.details;
const getConfig = (state) => state.config;

export const getCurrentIPOutputsCount = createSelector(
  [ getConfig ],
  (config) => {
    if(config.streamingOutput == null){
      return 0;
    }
    return Object.values(config.streamingOutput).filter(output => output.outputOrder >= 0).length;
  }
);

export const canAddNewIPOutput = createSelector(
  [ isLicenseValid, getLicenseDetails, getCurrentIPOutputsCount ],
  (isValid, license, ipOutputCount) => {
    if(!isValid){
      return false;
    }
    return ipOutputCount < license.IPOutput;
  }
);

export const canUsePlayback = createSelector(
  [ isLicenseValid, getLicenseDetails ],
  (valid, license) => {
    if(!valid){
      return false;
    }
    return license.playback > 0;
  }
);

export const canUseEncoders = createSelector(
  [ isLicenseValid, getLicenseDetails ],
  (valid, license) => {
    if(!valid){
      return false;
    }
    return license.encoder > 0;
  }
);

export const canSetIPInput = createSelector(
  [ isLicenseValid, getLicenseDetails ],
  (valid, license) => {
    if(!valid){
      return false;
    }
    return license.IPInput || license.WebRTCInput > 0 || license.NDIInput > 0;
  }
);

export const canSetIntercom = createSelector(
  [ isLicenseValid, getLicenseDetails ],
  (valid, license) => {
    if(!valid){
      return false;
    }
    return license.intercom;
  }
);