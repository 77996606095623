import {
  ADD_LOG,
  INTERCOM_OPEN,
  DECRYPT_PASSWORD,
  LICENSE_STATUS_INVALID,
  LICENSE_STATUS_VALID,
  RECEIVED_AUDIO_DEVICES,
  RECEIVED_CAPABILITIES,
  RECEIVED_CHANNEL_STATUS,
  RECEIVED_CONFIG,
  RECEIVED_ENCODERS_PREVIEW,
  RECEIVED_ENCODERS_STATUS,
  RECEIVED_FTP_TEST,
  RECEIVED_NDI_STREAMS_CHANGE,
  RECEIVED_HELP_LINK,
  RECEIVED_DECRYPT_PASSWORD,
  RECEIVED_INPUT_INTERCOM_INFO,
  RECEIVED_INPUT_INTERCOM_VOLUME,
  RECEIVED_IP_LOCAL,
  RECEIVED_IP_PUBLIC,
  RECEIVED_IP_SMPTE,
  EMITTING_CONFIG,
  RECEIVED_LANGUAGES,
  RECEIVED_LICENSE,
  RECEIVED_LIVE_INFO,
  RECEIVED_META_DATA,
  RECEIVED_LIVE_PROFILE,
  RECEIVED_LOGS,
  RECEIVED_MONITOR_INFO,
  RECEIVED_TRANSCODER_STATUS,
  RECEIVED_NETWORK_INTERFACES_STATUS,
  RECEIVED_NEW_LOG,
  RECEIVED_OUTPUT_STATS,
  RECEIVED_OUTPUT_STATUS,
  RECEIVED_REPORT_STATUS,
  RECEIVED_STATUS_DEVICES_CHANGE,
  RECEIVED_STREAM_STATS,
  RECEIVED_STREAMHUB_METADATA,
  RECEIVED_USER_ROLE,
  RECEIVED_USER_SESSIONS,
  RECEIVED_NEW_TOKEN,
  LS_KEY_IDENTITY,
  SERVER_WAITING,
  USER_ROLE_ADMIN,
  USER_ROLE_USER,
  RECEIVED_ORION_PLATFORM,
  TOOLS_SYSTEM_UPDATE_FIRMWARE_MODAL,
  USER_ROLE_VIEWER
} from "../constants";
import {inputEnterDetails, inputSubscribe} from "../components/pages/dashboard/dashboard.actions";

export const serverWaiting = (reason, isOpen) => {
  return {
    type: SERVER_WAITING,
    open: isOpen,
    reason
  }
};

export const closeFirmwareModal = () => {
  return {
    type: TOOLS_SYSTEM_UPDATE_FIRMWARE_MODAL,
    open: false
  }
};

export const intercomOpen = (open) => {
  return {
    type: INTERCOM_OPEN,
    open
  }
};

export const decryptPassword = (adminPassword, encryptedPassword) => {
  return {
    type: DECRYPT_PASSWORD,
    adminPassword,
    encryptedPassword
  }
};

export const updateUserRole = (role, changePasswordRequired) => {
  return {
    type: RECEIVED_USER_ROLE,
    role: role === 'admin' ? USER_ROLE_ADMIN : ( role === 'user' ? USER_ROLE_USER : USER_ROLE_VIEWER),
    changePasswordRequired
  }
};

export const updateIdentityToken = (token) => {
  let identity = JSON.parse(window.sessionStorage.getItem(LS_KEY_IDENTITY));
  if(identity){
    identity.token = token
    window.sessionStorage.setItem(LS_KEY_IDENTITY, JSON.stringify(identity));
  }
  return {
    type: RECEIVED_NEW_TOKEN,
    token
  }
};

export const updateLicense = (licenseData, config) => {
  return {
    type: RECEIVED_LICENSE,
    license: JSON.parse(licenseData.license),
    status: licenseData.status === 'valid' ? LICENSE_STATUS_VALID : LICENSE_STATUS_INVALID,
    features: JSON.parse(licenseData.features).features,
    orionConnectionStatus: licenseData.orionConnectionStatus,
    hotdeployInProgress: licenseData.hotdeployInProgress,
    config
  }
};

export const emittingConfig = () => {
  return {
    type: EMITTING_CONFIG
  }
};

export const updateLanguages = (languages) => {
  return {
    type: RECEIVED_LANGUAGES,
    languages
  }
};

export const updateStreamHubMetadata = (data) => {
  return {
    type: RECEIVED_STREAMHUB_METADATA,
    metadata: data
  }
};

export const updateFromConfig = (config, status, license) => {
  return {
    type: RECEIVED_CONFIG,
    config,
    status,
    license
  }
};

// Redux Thunk middleware action
export const updateFromConfigAndSubscribeIfNecessary = (config) => (dispatch, getState) => {
  const { dashboard, license } = getState();
  dispatch(updateFromConfig(config, license.status, license.details));
  if(!dashboard.ready){
    const { streamhub, router } = getState();
    const urlParts = router.location.pathname.split('/');
    const lastPart = urlParts[urlParts.length - 1];
    const currentInput = streamhub.inputs ? streamhub.inputs.find(input => input.id === lastPart) : null;
    if(currentInput){
      dispatch(inputEnterDetails(currentInput));
      dispatch(inputSubscribe(currentInput));
    }
  }
};

export const updateFromCapabilities = (capabilities) => {
  return {
    type: RECEIVED_CAPABILITIES,
    capabilities
  }
};

export const updateFromOrionPlatform = (platformData, config) => {
  return {
    type: RECEIVED_ORION_PLATFORM,
    platformData,
    config
  }
}

export const updateFromChannelStatus = (data) => {
  return {
    type: RECEIVED_CHANNEL_STATUS,
    data
  }
};

export const updateFromStatusDevicesChange = (data) => {
  return {
    type: RECEIVED_STATUS_DEVICES_CHANGE,
    data
  }
};

export const updateFromLiveProfile = (data) => {
  return {
    type: RECEIVED_LIVE_PROFILE,
    data
  }
};

export const updateFromLiveInfo = (data) => {
  return {
    type: RECEIVED_LIVE_INFO,
    data
  }
};

export const updateFromMetaData = (data) => {
  return {
    type: RECEIVED_META_DATA,
    data
  }
};

export const updateFromNetworkInterfaceStatuses = (data) => {
  return {
    type: RECEIVED_NETWORK_INTERFACES_STATUS,
    data
  }
};

export const updateFromEncodersStatus = (data) => {
  return {
    type: RECEIVED_ENCODERS_STATUS,
    data
  }
};

export const updateFromEncoderPreview = (data) => {
  const { service, event, ...otherProps } = data;
  return {
    type: RECEIVED_ENCODERS_PREVIEW,
    data: otherProps
  }
};

export const updateFromOutputStatus = (data) => {
  return {
    type: RECEIVED_OUTPUT_STATUS,
    data
  }
};

export const updateFromNDIStreamsChange = (data) => {
  return {
    type: RECEIVED_NDI_STREAMS_CHANGE,
    data
  }
};

export const updateFromStreamStat = (data) => {
  const { event, service, ...otherProps } = data;
  return {
    type: RECEIVED_STREAM_STATS,
    data: {
      ...otherProps
    }
  }
};

export const updateFromOutputStats = (data) => {
  const { event, service, ...otherProps } = data;
  return {
    type: RECEIVED_OUTPUT_STATS,
    ...otherProps
  }
};

export const updateFromMonitorInfo = (data) => {
  const { event, service, ...otherProps } = data;
  return {
    type: RECEIVED_MONITOR_INFO,
    ...otherProps
  }
};

export const updateFromTranscoderStatus = (data) => {
  const { event, service, ...otherProps } = data;
  return {
    type: RECEIVED_TRANSCODER_STATUS,
    ...otherProps
  }
};

export const updateFromAudioDevices = (data) => {
  return {
    type: RECEIVED_AUDIO_DEVICES,
    data
  }
};

export const updateFromIPLocal = (data, config) => {
  return {
    type: RECEIVED_IP_LOCAL,
    data,
    config
  }
};

export const updateFromIPPublic = (data, config) => {
  return {
    type: RECEIVED_IP_PUBLIC,
    data,
    config
  }
};

export const updateFromSMPTEIP = (data, config) => {
  return {
    type: RECEIVED_IP_SMPTE,
    data,
    config
  }
};

export const updateFromUserSessions = (data) => {
  return {
    type: RECEIVED_USER_SESSIONS,
    data
  }
};

export const updateFromLogs = (data) => {
  const logs = data.logs.map(log => {
    return {
      type: log.type,
      label: log.label,
      timestamp: new Date(log.timestamp)
    }
  });
  return {
    type: RECEIVED_LOGS,
    logs,
    counterWarning: data.counterWarning,
    counterDanger: data.counterDanger,
    pagesTotal: data.pagesTotal
  }
};

export const updateFromNewLog = (data) => {
  return {
    type: RECEIVED_NEW_LOG,
    log: {
      type: data.type,
      label: data.label,
      timestamp: new Date(data.timestamp)
    },
    counterWarning: data.counterWarning,
    counterDanger: data.counterDanger
  }
};

export const updateFTPTest = (res) => {
  return {
    type: RECEIVED_FTP_TEST,
    res
  }
};

export const updateInputIntercomInfo = (data) => {
  return {
    type: RECEIVED_INPUT_INTERCOM_INFO,
    data
  }
};

export const updateInputIntercomVolume = (data) => {
  return {
    type: RECEIVED_INPUT_INTERCOM_VOLUME,
    data
  }
};

export const updateHelpLink = (link) => {
  return {
    type: RECEIVED_HELP_LINK,
    link
  }
};

export const updateDecryptPassword = (password) => {
  return {
    type: RECEIVED_DECRYPT_PASSWORD,
    password
  }
};

export const updateReportStatus = (preparing) => {
  return {
    type: RECEIVED_REPORT_STATUS,
    preparing
  }
};

export const addLog = (log) => {
  return {
    type: ADD_LOG,
    log
  }
};