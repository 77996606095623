import {
  DASHBOARD_INPUT_DISCONNECT_MODEM,
  DASHBOARD_INPUT_RECONNECT_MODEM,
  DASHBOARD_INPUT_RECORD_START,
  DASHBOARD_INPUT_RECORD_STOP,
  DASHBOARD_ENCODER_RECORD_START,
  DASHBOARD_ENCODER_RECORD_STOP,
  DASHBOARD_INPUT_REMOTE_LIVE_START,
  DASHBOARD_INPUT_REMOTE_LIVE_STOP,
  DASHBOARD_OUTPUTS_CHANGE_POSITION,
  EMITTING_CONFIG,
  INTERCOM_OPEN,
  LANGUAGE_MODAL,
  RECEIVED_AUDIO_DEVICES,
  RECEIVED_CAPABILITIES,
  RECEIVED_CHANNEL_STATUS,
  RECEIVED_CONFIG,
  RECEIVED_ENCODERS_STATUS,
  RECEIVED_HELP_LINK,
  RECEIVED_DECRYPT_PASSWORD,
  RECEIVED_IP_LOCAL,
  RECEIVED_IP_PUBLIC,
  RECEIVED_IP_SMPTE,
  RECEIVED_LANGUAGES,
  RECEIVED_LICENSE,
  RECEIVED_LIVE_INFO,
  RECEIVED_LIVE_PROFILE,
  RECEIVED_META_DATA,
  RECEIVED_NETWORK_INTERFACES_STATUS,
  RECEIVED_ORION_PLATFORM,
  RECEIVED_OUTPUT_STATUS,
  RECEIVED_STATUS_DEVICES_CHANGE,
  RECEIVED_STREAMHUB_METADATA,
  RECEIVED_USER_SESSIONS,
  REFRESH_PAGE,
  SERVER_WAITING,
  SETTINGS_INPUT_TMP_PROFILE_CREATE,
  SETTINGS_INPUT_TMP_PROFILE_RESULT
} from "../constants";

import {
  createEncoders,
  createHardwareOutputs,
  createInputs,
  createNDIOutputs,
  mapChannelStatusToInputs,
  mapChannelStatusToMultiView,
  mapConfigToEncoderProfiles,
  mapConfigToEncoders,
  mapConfigToFileTransfers,
  mapConfigToHardwareOutputs,
  mapConfigToInputIPProfiles,
  mapConfigToInputs,
  mapConfigToIPOutputs,
  mapConfigToMultiView,
  mapConfigToNDIOutputs,
  mapConfigToOutputIPProfiles,
  mapConfigToStreamhubName,
  mapConfigToUdpUsedPort,
  mapEncoderStatusToEncoders,
  mapInputAudioDevices,
  mapLiveInfoToInputs,
  mapLiveProfileToInputs,
  mapMetaDataToInputs,
  mapNetworkInterfaceToInputs,
  mapOutputAudioDevices,
  mapOutputStatusToHardwareOutputs,
  mapOutputStatusToIPOutputs,
  mapOutputStatusToNDIOutputs,
  mapStatusDevicesChangeToInputs,
  mapSystemSpec,
  matchingAudioDevice,
  matchInputWithProfiles,
  updateEncodersById,
  updateInputsById,
  updateOutputsById
} from "./streamhub.adapters";

const initialState = {
  name: null,
  version: null,
  helpLink: null,
  availableLanguages: [],
  inputs: [],
  hardwareOutputs: [],
  ndiOutputs: [],
  multiView: null,
  ipOutputs: [],
  inputIPProfiles: [],
  udpUsedPort: {
    inputProtocolPorts: [],
    intercomPorts: [],
    channelPorts: [],
    atpGeneralBasePorts: [],
    remoteControlPorts: []
  },
  outputIPProfiles: [],
  inputAudioDevices: [],
  inputAudioDeviceSelected: null,
  outputAudioDevices: [],
  outputAudioDeviceSelected: null,
  outputProtocols: ['SST'],
  publicIp: null,
  localIps: null,
  smpteIps: null,
  sessionsCount: 0,
  //destinationInputs: [],
  //lostStreamModes: [],
  //encoderLostStreamModes: [],
  //hardwareOutputStandards: {},
  //ndiOutputStandards: [],
  //mosaicOutputStandards: [],
  //mosaicAudioChannelModes: [],
  //receivedFileTranscoderCapabilities: null,
  //recordedFileTranscoderCapabilities: null,
  fileTransfers: [],
  //ftpTransferModes: [],
  //fileTransferTypes: [],
  intercomOpened: false,
  //videoCards: null,
  //videoFrameRates: [],
  //srtKeyLengths: [],
  encoderProfiles: [],
  encoders: null,
  languageModalOpened: false,
  pendingForConfig: true,
  serverWaitingModalOpened: false,
  serverWaitingReason: null,
  tmpProfilesLoading: {},
  orionPlatform: "",
};

const streamhubReducer = (state = initialState, action) => {

  if(action.type === RECEIVED_LANGUAGES){
    return {
      ...state,
      availableLanguages: action.languages
    }
  }

  if(action.type === RECEIVED_LICENSE){
    return {
      ...state,
      multiView: mapConfigToMultiView(action.config, action.status, action.license)
    };
  }

  if(action.type === RECEIVED_STREAMHUB_METADATA){
    const version = action.metadata.version;
    const isoVersion = action.metadata.isoVersion;
    const hardwareIdentifier = action.metadata.hardwareIdentifier;
    const inputs = state.inputs.length!==action.metadata.nbChannel ? createInputs(action.metadata.nbChannel) : state.inputs;
    const hardwareOutputs = state.hardwareOutputs.length!==action.metadata.nbOutput ? createHardwareOutputs(action.metadata.nbOutput) : state.hardwareOutputs;
    const ndiOutputs = state.ndiOutputs.length!==action.metadata.NDIOutput ? createNDIOutputs(action.metadata.NDIOutput): state.ndiOutputs;
    const encoders = (state.encoders===null || state.encoders.length!==action.metadata.allowedEncoders) ? createEncoders(action.metadata.allowedEncoders): state.encoders;
    const system = mapSystemSpec(action.metadata);
    const smpte2110 = action.metadata.smpte2110;
    const configTooBig = action.metadata.configTooBig;
    const serverWaitingModalOpened = action.metadata.serverReady!==undefined  ? !action.metadata.serverReady : state.serverWaitingModalOpened;
    const serverWaitingReason =  action.metadata.serverReady!==undefined  ? 'waitForServer' : state.serverWaitingReason;
    return {
      ...state,
      inputs,
      hardwareOutputs,
      ndiOutputs,
      encoders,
      version,
      hardwareIdentifier,
      isoVersion,
      system,
      smpte2110,
      configTooBig,
      serverWaitingModalOpened,
      serverWaitingReason

    };
  }

  if(action.type === RECEIVED_CONFIG){
    const inputs = mapConfigToInputs(action.config);
    const hardwareOutputs = mapConfigToHardwareOutputs(action.config);
    const ndiOutputs = mapConfigToNDIOutputs(action.config);
    const ipOutputs = mapConfigToIPOutputs(action.config, action.status, action.license);
    const inputIPProfiles = mapConfigToInputIPProfiles(action.config, state.publicIp, state.localIps, state.orionPlatform);
    const udpUsedPort = mapConfigToUdpUsedPort(action.config);
    const outputIPProfiles = mapConfigToOutputIPProfiles(action.config, state.publicIp, state.localIps);
    const multiView = mapConfigToMultiView(action.config, action.status, action.license);
    const fileTransfers = mapConfigToFileTransfers(action.config);
    const encoderProfiles = mapConfigToEncoderProfiles(action.config);
    const encoders = mapConfigToEncoders(action.config, action.status, action.license);
    const name = mapConfigToStreamhubName(action.config);
    return {
      ...state,
      name,
      inputs: updateInputsById(state.inputs, matchInputWithProfiles(inputs, inputIPProfiles)),
      multiView,
      inputIPProfiles,
      udpUsedPort,
      hardwareOutputs: updateOutputsById(state.hardwareOutputs, hardwareOutputs),
      ndiOutputs: updateOutputsById(state.ndiOutputs, ndiOutputs),
      ipOutputs: updateOutputsById(state.ipOutputs, ipOutputs, true),
      outputIPProfiles,
      inputAudioDeviceSelected: action.config.intercom ? matchingAudioDevice(state.inputAudioDevices, action.config.intercom.inputDevice) : null,
      outputAudioDeviceSelected: action.config.intercom ? matchingAudioDevice(state.outputAudioDevices, action.config.intercom.outputDevice) : null,
      fileTransfers,
      encoderProfiles,
      encoders: updateEncodersById(state.encoders, encoders),
      pendingForConfig: false
    };
  }

  if(action.type === RECEIVED_CAPABILITIES){
    return {
      ...state,
    };
  }

  if(action.type === RECEIVED_CHANNEL_STATUS){
    const inputs = mapChannelStatusToInputs(action.data);
    const multiViewState = mapChannelStatusToMultiView(action.data);
    return {
      ...state,
      inputs: updateInputsById(state.inputs, inputs),
      multiView: (multiViewState && state.multiView) ? { ...state.multiView, ...multiViewState } : state.multiView
    };
  }

  if(action.type === RECEIVED_ENCODERS_STATUS){
    const encoders = mapEncoderStatusToEncoders(action.data);
    const existingEncoders = state.encoders ? state.encoders : [];
    return {
      ...state,
      encoders: updateEncodersById(existingEncoders, encoders)
    };
  }

  if(action.type === RECEIVED_OUTPUT_STATUS){
    const hardwareOutputs = mapOutputStatusToHardwareOutputs(action.data);
    const ndiOutputs = mapOutputStatusToNDIOutputs(action.data);
    const ipOutputs = mapOutputStatusToIPOutputs(action.data);
    return {
      ...state,
      hardwareOutputs: updateOutputsById(state.hardwareOutputs, hardwareOutputs),
      ndiOutputs: updateOutputsById(state.ndiOutputs, ndiOutputs),
      ipOutputs: updateOutputsById(state.ipOutputs, ipOutputs)
    };
  }

  if(action.type === RECEIVED_STATUS_DEVICES_CHANGE){
    const inputs = mapStatusDevicesChangeToInputs(action.data);
    return {
      ...state,
      inputs: updateInputsById(state.inputs, inputs)
    };
  }

  if(action.type === RECEIVED_LIVE_PROFILE){
    const inputs = mapLiveProfileToInputs(action.data);
    return {
      ...state,
      inputs: updateInputsById(state.inputs, inputs)
    };
  }

  if(action.type === RECEIVED_LIVE_INFO){
    const inputs = mapLiveInfoToInputs(action.data);
    return {
      ...state,
      inputs: updateInputsById(state.inputs, inputs)
    };
  }

  if(action.type === RECEIVED_META_DATA){
    const inputs = mapMetaDataToInputs(action.data);
    return {
      ...state,
      inputs: updateInputsById(state.inputs, inputs)
    };
  }

  if(action.type === RECEIVED_NETWORK_INTERFACES_STATUS){
    const inputs = mapNetworkInterfaceToInputs(action.data);
    return {
      ...state,
      inputs: updateInputsById(state.inputs, inputs)
    };
  }

  if(action.type === RECEIVED_AUDIO_DEVICES){
    return {
      ...state,
      inputAudioDevices: mapInputAudioDevices(action.data),
      outputAudioDevices: mapOutputAudioDevices(action.data)
    };
  }

  if(action.type === RECEIVED_IP_PUBLIC){
    return {
      ...state,
      inputIPProfiles: action.config ? mapConfigToInputIPProfiles(action.config, action.data, state.localIps, state.orionPlatform) : state.inputIPProfiles,
      outputIPProfiles: action.config ? mapConfigToOutputIPProfiles(action.config, action.data, state.localIps, state.orionPlatform) : state.outputIPProfiles,
      publicIp: action.data
    };
  }

  if(action.type === RECEIVED_IP_LOCAL){
    return {
      ...state,
      inputIPProfiles: action.config ? mapConfigToInputIPProfiles(action.config, state.publicIp, action.data, state.orionPlatform) : state.inputIPProfiles,
      outputIPProfiles: action.config ? mapConfigToOutputIPProfiles(action.config, state.publicIp, action.data, state.orionPlatform) : state.outputIPProfiles,
      localIps: action.data
    };
  }
  
  if(action.type === RECEIVED_IP_SMPTE){
    return {
      ...state,
      inputIPProfiles: action.config ? mapConfigToInputIPProfiles(action.config, state.publicIp, action.data, state.orionPlatform) : state.inputIPProfiles,
      outputIPProfiles: action.config ? mapConfigToOutputIPProfiles(action.config, state.publicIp, action.data, state.orionPlatform) : state.outputIPProfiles,
      smpteIps: action.data
    };
  }

  if(action.type === RECEIVED_USER_SESSIONS){
    return {
      ...state,
      sessionsCount: action.data
    };
  }

  if(action.type === INTERCOM_OPEN){
    return {
      ...state,
      intercomOpened: action.open
    }
  }

  if(action.type === RECEIVED_HELP_LINK){
    return {
      ...state,
      helpLink: action.link
    }
  }

  if(action.type === RECEIVED_DECRYPT_PASSWORD){
    return {
      ...state,
      decryptedPassword: action.password
    }
  }

  if(action.type === LANGUAGE_MODAL){
    return {
      ...state,
      languageModalOpened: action.open
    }

  }

  if(action.type === DASHBOARD_OUTPUTS_CHANGE_POSITION){
    const movingOutput = action.output;
    const ipOutputs = [ ...state.ipOutputs];
    const indexFrom = movingOutput.index;
    const indexTo = action.index;

    if(indexFrom < indexTo) {
      ipOutputs.forEach((output, index) => {
        if(output.index === indexFrom) {
          output.index = indexTo;
        }
        else if (output.index > indexFrom && output.index <= indexTo) {
          output.index--;
        }
      });
    }
    else {
      ipOutputs.forEach((output, index) => {
        if(output.index === indexFrom) {
          output.index = indexTo;
        }
        else if (output.index < indexFrom && output.index >= indexTo) {
          output.index++;
        }
      });
    }
    return {
      ...state,
      ipOutputs
    }
  }

  // Disable action button when starting and stopping a live
  if(action.type === DASHBOARD_INPUT_REMOTE_LIVE_START || action.type === DASHBOARD_INPUT_REMOTE_LIVE_STOP){
    const inputs = [ ...state.inputs];
    inputs.forEach((input, index) => {
      if(input.id === action.input.id) {
        input.deviceLiveStatus = null;
      }
    });
    return {
      ...state,
      inputs
    };
  }

  // Disable action button when starting and stopping a record
  if(action.type === DASHBOARD_INPUT_RECORD_START || action.type === DASHBOARD_INPUT_RECORD_STOP){
    const inputs = [ ...state.inputs];
    inputs.forEach((input, index) => {
      if(input.id === action.input.id) {
        input.recordStatus = null;
      }
    });
    return {
      ...state,
      inputs
    };
  }
  if(action.type === DASHBOARD_ENCODER_RECORD_START || action.type === DASHBOARD_ENCODER_RECORD_STOP){
    const encoders = [ ...state.encoders];
    encoders.forEach((encoder, index) => {
      if(encoder.id === action.encoder.id) {
        encoder.recordStatus = null;
      }
    });
    return {
      ...state,
      encoders
    };
  }

  if(action.type === EMITTING_CONFIG){
    return {
      ...state,
      pendingForConfig: true
    };
  }

  // Reset state when reconnect modem
  if(action.type === DASHBOARD_INPUT_RECONNECT_MODEM || action.type === DASHBOARD_INPUT_DISCONNECT_MODEM){
    const inputs = [ ...state.inputs];
    if (inputs[action.input.index].deviceNetworkInterfaces) {
      inputs[action.input.index].deviceNetworkInterfaces.forEach((deviceNetworkInterface, index) => {
        if(deviceNetworkInterface.name === action.name) {
          deviceNetworkInterface.state = 'PENDING';
        }
      });
    }
    return {
      ...state,
      inputs
    };
  }

  if(action.type === SERVER_WAITING){
    return {
      ...state,
      serverWaitingModalOpened: action.open,
      serverWaitingReason: action.reason
    }
  }

  if(action.type === REFRESH_PAGE){
    window.location.href = '/';
  }

  if(action.type === SETTINGS_INPUT_TMP_PROFILE_CREATE){
    if(action.profile.name){
      return {
        ...state,
        tmpProfilesLoading: {
          ...state.tmpProfilesLoading,
          [action.profile.name]: {
            loading: true,
            errorCode: null
          }
        }
      }
    }
  }

  if(action.type === SETTINGS_INPUT_TMP_PROFILE_RESULT) {
    return {
      ...state,
      tmpProfilesLoading: {
        ...state.tmpProfilesLoading,
        [action.name]: {
          loading: false,
          errorCode: action.errorCode ?? null
        }
      },
    }
  }

  if (action.type === RECEIVED_ORION_PLATFORM) {
    return {
      ...state,
      orionPlatform: action.platformData ? action.platformData.platform : "",
      inputIPProfiles: action.config ? mapConfigToInputIPProfiles(action.config, state.publicIp, state.localIps, state.orionPlatform): state.inputIPProfiles,
    }
  }
  return state;
};

export default streamhubReducer;