import PropTypes from "prop-types";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Translate} from "react-localize-redux";
import {Nav, NavItem, NavLink} from "reactstrap";
import {Redirect, Route, Switch, withRouter} from "react-router";

import {updateVideoCardSettings} from "./videocards.settings.actions";
import withLogger from "../../../hoc/with-logger";
import {videoCardCapabilitiesPropTypes} from "../../../../utils/models-prop-types";
import Form from "./form";
import { getAvailableVideoCards, getAvailableVideoFrameRates } from "../../../../misc/capabilities.selectors";
import { Link } from "react-router-dom";

const propTypes = {
  config: PropTypes.object,
  videoCards: PropTypes.arrayOf(videoCardCapabilitiesPropTypes),
  videoFrameRates: PropTypes.arrayOf(PropTypes.string).isRequired,
  callUpdateVideoCardSettings: PropTypes.func.isRequired
};

class VideoCardsSettings extends Component {

  constructor(props){
    super(props);

    this.handleCardIndexChange = this.handleCardIndexChange.bind(this);

    this.state = {
      currentCardIndex: (props.videoCards && props.videoCards.length > 0) ? 0 : null
    };
  }

  static getDerivedStateFromProps(props, state){
    if(state.currentCardIndex === null && (props.videoCards && props.videoCards.length > 0)){
      return {
        currentCardIndex: 0
      };
    }
    return null;
  }

  handleCardIndexChange(cardIndex){
    this.setState({
      currentCardIndex: cardIndex
    });
  }
  render() {
    const {
      config,
      videoCards,
      videoFrameRates,
      callUpdateVideoCardSettings,
      smpteIps,
      location,
      licenseStatus
    } = this.props;

    const hasPath = (path) => {
      return location.pathname.includes(path);
    };

    return (
      <div className="settings-section">
        <div className="header">
          <div className="title">
            <Translate id="genericLabel.VIDEO_CARDS.text"/>
          </div>
        </div>
        <Nav tabs>
          { videoCards && videoCards.map((videoCard, index) => (
            <NavItem key={index}>
              <NavLink id={"settings_"+videoCard.modelName+"_navLink"}
                       active={ hasPath('/'+index) }
                       className="aw-nav-link"
                       tag={Link}
                       to={"/settings/video-cards/"+index}>
                {videoCard.modelName}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
        { videoCards && config &&
        <div className="content with-menu pretty-scroll">
          <Switch>
          {  videoCards.map((videoCard, index) => ( 
             <Route exact path={"/settings/video-cards/"+index} key={index} component={(props) => {
              return <Form config={config[index]}
                           videoFrameRates={videoFrameRates}
                           videoCard={videoCards[index]}
                           smpteIps={smpteIps}
                           onSubmit={callUpdateVideoCardSettings}
                           licenseStatus={licenseStatus}/>
            }}/>
          ))}
          <Redirect from="/settings/video-cards*" to="/settings/video-cards/0"/>
          </Switch>
        </div>
        }
      </div>
    );
  }
}

VideoCardsSettings.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
  const config = state.config ? state.config.videoCards : null;
  const licenseStatus = state.streamhub.smpte2110[1] ? state.streamhub.smpte2110[0].allowed2110 : false;
  return {
    config,
    videoCards: getAvailableVideoCards(state),
    videoFrameRates: getAvailableVideoFrameRates(state),
    smpteIps: state.streamhub.smpteIps,
    licenseStatus
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    callUpdateVideoCardSettings: (id, data) => dispatch(updateVideoCardSettings(id, data))
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withLogger(VideoCardsSettings)));